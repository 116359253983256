export default {
    setConnectedActor({commit}, value) {
         commit('CHANGE_CONNECTED_ACTOR', value);
    },
    setConnectedUser({commit},value){
         commit('CHANGE_CONNECTED_USER',value);
    },
    setIsLoggedIn({commit}, isLoggedIn) {
         commit('CHANGE_IS_LOGGED_IN', isLoggedIn);
    },
    setOauth({commit}, oauth) {
         commit("CHANGE_OAUTH", oauth);
      },
};
