import download from '~/lib/download';
import { getType } from 'mime';
import { database } from '~/src/db';
import store from '~/src/store/';
import _ from 'lodash'

const get = (store, keys) =>
    Promise.all(
        keys.map(
            (key) =>
                new Promise((resolve, reject) => {
                    const request = store.get(key);
                    request.onsuccess = ({ target: { result } }) => resolve(result);
                    request.onerror = ({ target: { error } }) => reject(error);
                })
        )
    ).then((values) =>
        keys.reduce(
            (result, key, index) => ((result[key] = values[index]), result),
            {}
        )
    );

const File_db = {
    async get_file(file, withData=true,downloadFinish=false) {
        let db = await database.db;
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let request = transaction.objectStore('document').get([file.company.id,file.project.id,file.id]);

            transaction.oncomplete = function (event) {
                // console.log("transaction terminated");
            }
            request.onsuccess = async function (event) {
                if (request.result && (!downloadFinish||request.result.offline=='downloadFinish')){
                    request.result['currentUserIsOwner']=request.result.user_ids.includes(user_id);
                    if(withData) {
                        request.result.data = await File_db.get_chunks_data(request.result.chunk_ids);
                    }

                    resolve(request.result);
                }
                resolve();
                // console.log('get_from_db: request successful');

            }
            request.onerror = function (event) {
                resolve(request.result);
                // console.log('get_from_db: request error');
            }
        });
    },

    async get_chunks_data(ids) {
        if (!ids) {
            return false;
        }
        let db = await database.db;
        let data = new Uint8Array();
        let transaction = db.transaction("chunks", "readwrite");
        let store = transaction.objectStore('chunks');
        return new Promise((resolve) => {
            get(store, ids).then((chunks) => {
                for (const chunk in chunks) {
                    let a = new Uint8Array(data.length + chunks[chunk].data.length);
                    a.set(data);
                    a.set(chunks[chunk].data, data.length);
                    data = a;
                    resolve(data);
                }
            });
        })
    },

    // async delete_file(file) {
    //     let db = await database.db;
    //     // delete_chunks(chunk_ids);
    //     return new Promise((resolve) => {
    //         let transaction = db.transaction("document", "readwrite");
    //         transaction.oncomplete = function (event) {
    //             // console.log("transaction terminated");
    //             resolve();
    //         };
    //         transaction.onerror = function (event) {
    //             // console.log('erreur de transaction');
    //         };
    //         let document_store = transaction.objectStore("document");
    //         let request = document_store.delete([file.company.id,file.project.id,file.id]);
    //         request.onsuccess = function (event) {
    //             // console.log('delete_from_db: delete successful');
    //         };
    //         request.onerror = function (event) {
    //             // console.log('delete_from_db: delete error');
    //         };
    //     });
    // },


    async update_user_file(file,st_id=null,project_id=null){
        console.log("File update :", file);
        let db = await database.db;
        st_id = file.st_id ?? store.state.company.company.id;
        project_id = file.project_id ?? store.state.project.project.id
        let user_id = store.state.login.connectedUser.id;
        return new Promise(function (resolve){
            let transaction = db.transaction("document", "readwrite");
            let store = transaction.objectStore('document');
            var request = store.openCursor(IDBKeyRange.only([st_id,project_id,file.id]));
            transaction.oncomplete = function (event) {
                // console.log("transaction terminated");
            }
            let find=false;

            request.onsuccess = async function (event) {
                var cursor = request.result;
                if(cursor){
                    console.log("fichier trouver en base")
                    find=true;
                    if(!cursor.value.user_ids.includes(user_id) || file.version_changed){

                        if(file.version_changed){
                            console.log("Version changed : replace chunk_ids , version and offline")
                            cursor.value.version=file.version;
                            cursor.value.chunk_ids=file.chunk_ids;
                        }
                        else
                        {
                            cursor.value.user_ids.push(user_id)
                            file.offline = "downloadFinish";
                        }

                        var res = cursor.update(cursor.value);
                        res.onsuccess = function(e){
                            console.log("update success!!");
                            return  resolve (true);

                        }
                        res.onerror = function(e){
                            console.log("update failed!!");
                            return resolve (false);

                        }
                    }
                    else
                    {
                        if(cursor.value.user_ids.length<=1){
                            let chunk_ids=cursor.value.chunk_ids
                            let del=cursor.delete()
                            del.onsuccess = function(e){
                                File_db.delete_chunks(chunk_ids);
                                file.offline = "notDownloaded";
                                return resolve (true);
                            }
                            del.onerror = function(e){
                                console.log("delete failed!!");
                                return  resolve (false);

                            }
                        }
                        else{
                            _.pull(cursor.value.user_ids,user_id);
                            file.offline = "notDownloaded";
                            var res2 = cursor.update(cursor.value);
                            res2.onsuccess = function(e){
                                console.log("update success!!");
                                return resolve (true);

                            }
                            res2.onerror = function(e){
                                console.log("update failed!!");
                                return resolve (false);

                            }
                        }
                    }
                    cursor.continue()
                }
                else
                {   if(!find){
                        request = store.put({
                            'user_ids': [user_id],
                            'company': file.company,
                            'project': file.project,
                            'id': file.id,
                            'lib': file.lib,
                            'org_name': file.org_name,
                            'format': file.format,
                            'converted':file.converted,
                            'offline':file.offline,
                            'module_file':file.module_file,
                            'taille': file.taille,
                            'pere': file.pere,
                            'version': file.version,
                            'mime': getType(file.format.toUpperCase()),
                            'chunk_ids': file.chunk_ids,
                        });
                        resolve (false);
                    }
                }
            }
        });
    },

    async put_chunk(file,data) {
        console.log("put_chunk")
        let companyFile = file.company ?? store.state.company.company;
        let projectFile = file.project ?? store.state.project.project;
        let keyFile = [companyFile.id,projectFile.id,file.id];
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("chunks", "readwrite");
            transaction.oncomplete = function (event) {
            };
            transaction.onerror = function (event) {
                console.log(event);
            };
            let chunks_store = transaction.objectStore("chunks");
            let request = chunks_store.add({
                'st_project_obj_id': keyFile,
                'data': data
            });
            request.onsuccess = function (event) {
                console.log("chunk enregistré");
                resolve(event.target.result);
            };
            request.onerror = function (event) {
                console.log(request.error);
            };
        });
    },

    async delete_chunks(keys) {
        let db = await database.db;
        // console.log(keys)
        return new Promise((resolve) => {
            let transaction = db.transaction("chunks", "readwrite");
            transaction.oncomplete = function (event) {
                // console.log("transaction terminated");
                resolve();
            };
            transaction.onerror = function (event) {
                // console.log('erreur de transaction');
            };
            let document_store = transaction.objectStore("chunks");
            _.map(keys,(key)=>{
                console.log("suppression du chunck"+key)
                let request = document_store.delete(key);
                request.onsuccess = function (event) {
                    // console.log('delete_from_db: delete successful');
                };
                request.onerror = function (event) {
                    // console.log('delete_from_db: delete error');
                };
            })

        });
    },

    async get_folder_ids() {
        let db = await database.db;
        let company_id = store.state.company.company.id;
        let project_id = store.state.project.project.id;
        let user_id = store.state.login.connectedUser.id


        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let request = transaction.objectStore('document').index('module_st_project_id').openCursor(IDBKeyRange.only([company_id, project_id,"GED"]));
            let folders_id = [];
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    console.log(cursor)
                    if (cursor.value.user_ids.includes(user_id) && folders_id.indexOf(cursor.value.pere) === -1 &&  cursor.value.offline=="downloadFinish") {
                        folders_id.push(cursor.value.pere);
                    }
                    cursor.continue();
                }
                if(cursor==null){
                    resolve(folders_id);
                }
            };
            request.onerror=function(event){
                resolve(folders_id);
            }
        });

    },

    register_to_virtual_fs(file, data) {
        return new Promise((resolve) => {
            //add file size (remove 1024 * 1024)
            window.webkitRequestFileSystem(window.TEMPORARY, 1024 * 1024, (localstore) => {
                localstore.root.getFile(file.lib, { create: true }, function (fileEntry) {
                    fileEntry.createWriter(function (fileWriter) {
                        fileWriter.onwriteend = function (e) {
                            // console.log('Write completed.');
                            console.log('file registered in file system at: filesystem:' + process.env.VUE_APP_LOCAL_URL + 'temporary/' + file.lib);
                            resolve('filesystem:' + process.env.VUE_APP_LOCAL_URL + 'temporary/' + file.lib);
                        };
                        fileWriter.onerror = function (e) {
                            console.log('Write failed: ' + e);
                        };
                        let file_lib = file.lib;
                        if (file.format_without_html == 'XLSX') {
                            file_lib = file.lib.split('.')[0] + '.ods'; //------------------------------------------------<!>
                        }
                        console.log(data);
                        console.log(getType(file.format.toUpperCase()));

                        fileWriter.write(new Blob([data], { type: getType(file.format.toUpperCase()) }));
                    });
                });
            });
        });
    },

    async register_to_local_fs(file, data) {
         console.log(file.mime,file.lib,file.format,getType(file.format.toUpperCase()))

        return new Promise((resolve) =>  {
             let mime_str = file.mime ?? getType(file.format.toUpperCase());
            download(data, file.lib, mime_str);
            resolve();
        })
    },

    async get_all_files_user_by_module(module_file) {
        if(module_file==null){
            console.log("Aucun module a rechercer");
            return;
        }
        let user_id = store.state.login.connectedUser.id
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let objectStore = transaction.objectStore('document');
            var request = objectStore.openCursor();
            let filesVersion = [];
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    filesVersion.push(cursor.value);
                    if(cursor.value.module_file==module_file && cursor.value.user_ids.includes(user_id))
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(filesVersion);
                }
            };
            request.onerror=function(event){
                resolve(filesVersion);
            }
        });
    },

    async get_files_by_root_id({ folder_id = null, page_id = 1 }) {
        let db = await database.db;
        let company_id = store.state.company.company.id;
        let project_id = store.state.project.project.id;
        let user_id = store.state.login.connectedUser.id
        let countResultPerPage = store.state.files.pageSize;

        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let request = transaction.objectStore('document').index('module_st_project_id').openCursor(IDBKeyRange.only([company_id, project_id,"GED"]));

            let result = { "documents_per_page": countResultPerPage, documents: [] };
            let countFile = 0;
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.user_ids.includes(user_id) &&  cursor.value.offline=="downloadFinish") {

                        if ((folder_id === null || (cursor.value.pere === folder_id))) {
                            countFile++;
                            if (countFile <= page_id * countResultPerPage && countFile > (page_id - 1) * countResultPerPage) {
                                console.log(cursor.value)
                                result.documents.push(cursor.value);
                            }
                        }
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    result.total_documents = countFile;

                    resolve(result);
                }
            };
            request.onerror=function(event){
                resolve(result);
            }
        });
    },
}
export default File_db;