const service = require('~/service/Ged/service.js');

module.exports.get_zoom = get_zoom
module.exports.get_actions = get_actions
module.exports.is_visa_blocked = is_visa_blocked
module.exports.set_visa = set_visa


function get_zoom(id) {
    return new Promise((resolve) => {
        service.get('workflows/' + id + '/get_zoom', null, function (res) {
            resolve(res);
        });
    })
}

function get_actions(obj_id, mission_id) {
    return new Promise((resolve) => {
        service.get('workflows/' + obj_id + '/actions/' + mission_id, null, function (res) {
            resolve(res.actions);
        });
    });
}

function is_visa_blocked(obj_id, mission_id, state_id) {
    return new Promise((resolve) => {
        service.post('workflows/' + obj_id + '/is_visa_blocked', {
            'mission_id': mission_id,
            'state_id': state_id
        },
            function (res) {
                console.log('is visa blocked: ', res);
                resolve(res);
            }
        );
    });
}

function set_visa(obj_id, mission_id, state_id, comment = '') {
    return new Promise((resolve) => {
        service.post('workflows/' + obj_id + '/visa', {
            'mission_id': mission_id,
            'state_id': state_id,
            'comment': comment
        },
            function (res) {
                console.log(res);
                resolve(res);
            });
    });
}
