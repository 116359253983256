<template>
  <div class="module-modal">
    <div class="module-tuile">
      <img class="module-tuile-image" src="../../assets/FileModule.png" @click="activeFileModule" />
      <div>{{$t('applayout.file_management')}} {{selectedKeyDirectory}}</div>
    </div>
    <div class="module-tuile" @click="activeRapportModule">
      <div class="module-next">{{$t('applayout.upcoming')}}</div>
      <img class="module-tuile-image" src="../../assets/RapportModule.png"  />
      <div>{{$t('applayout.raport_management')}}</div>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex';
export default {
  name: "SelectModuleModal",
  methods: {
    ...mapActions(['changeActiveModule']),

    async activeFileModule() {
      await this.changeActiveModule('GED');
      this.$router.push({ name: "File" });
      this.$emit('exit', true)
    },
    async activeRapportModule() {
      await this.changeActiveModule('RAPPORT');
      this.$router.push({ name: "Rapport" });
      this.$emit('exit', true)
    },
  },
  computed:{
   ...mapGetters("directories", {
      selectedKeyDirectory: "getSelectedKeyDirectory",
    }),
  }
};
</script>
