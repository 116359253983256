<template>
  <div class="section-sider" :class="expand?'expand '+$mq:$mq" >
    <div class="section-tiles" :class="$mq">
        <SectionDefinition
          v-for="section in sections"
          :key="section.id"
          :section="section"
        />
      </div>
    </div>
</template>
<script>
import SectionDefinition from "./SectionDefinition";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "RapportEdit",
  components: {
    SectionDefinition,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    expand:{
      type:Boolean,
      requird:true,
      default:false
    }
  },
  data() {
    return {
      signatureSection:{type:"Signature",title:"Signatures"}
    };
  },
  computed: {
    ...mapGetters("rapports", {
      sectionSelected: "get_user_rapport_section_selected",
      is_locked_report:"is_locked_report"
    }),
  },
  methods: {
    ...mapActions("rapports", ["set_user_rapport_section_selected_with_values"]),

  
  },
};
</script>