<template>
    <div class="section-display" :class="$mq">
        <div
            class='scroll-section'
        >
            <a-input v-if="header_rapport.can_change_title" class="title-rapport" placeholder="Titre du rapport" :value="header_rapport.modele.description" @change="change_title_rapport_user($event.target.value)" />
            <div
                v-for="section in sections"
                :id="section.section_id"
                :key="section.section_id"
            >
                <div class="section-detail " :class="$mq" v-if="section.type == undefined">
                    <div class="title-section" :class="$mq"  v-observe-visibility="{
                        callback: (isVisible, entry) =>
                        visibilityChanged(isVisible, entry, section),
                        intersection: {
                            threshold: 0.5,
                        },
                    }">
                        <div class="title">{{ project.isEmptyProject?section.order:section.order-1 }}. {{section.section_name }}</div>
                        <div v-if="!is_blocked_section(section) && section.editable" class="icon-title" @click="launchChangeTitle(section)" ><a-icon  type="edit" style="font-size:35px"/></div>
                        <div v-if="is_blocked_section(section)" class="icon-title" @>
                            <img src="../../../assets/cadenas_white.png" alt="" style="width:35px"/>
                        </div>
                        <div v-if="!is_to_send_or_recap_report && !is_locked_report && has_last_rapport_value(section.section_id) && !is_intervenant_section(section)" class="icon-title" style="width:150px">
                            <FromLastReportButton :active="activeLastReport[section.order-1]" @click="changeFromlastReport(section.order-1)" />
                        </div>
                        <div v-if="!is_to_send_or_recap_report && !is_locked_report && has_last_rapport_value(section.section_id) && is_intervenant_section(section)" class="icon-title" style="width:150px">
                            <div class="FromLastReportButton" style="width:150px" @click="checkActorsFromLast(section)">
                                {{ $t("rapportedit.sections_last.from") }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="section_element in section.section_elements"
                        :key="section_element.element_id"
                    >
                        <ProjectInformation
                            v-if="section_element.element_type === 'REPLACEMENT_INFO' && !activeLastReport[section.order-1] "
                            v-model="section_element.element_values[0]"
                            @save="save_section({section})"
                        />
                        <InfoGeneAeu
                            v-if="section_element.element_type === 'INFO_GENE_AEU' && !activeLastReport[section.order-1] "
                            v-model="section_element.element_values[0]"
                            @save="save_section({section})"
                        />
                        <EntrepriseInformation
                            v-if="section_element.element_type === 'ENTREPRISE_SECTION_IC' && !activeLastReport[section.order-1]"
                            v-model="section_element.element_values[0]"
                            @save="save_section({section})"
                        />
                        <ChoiceActorMail
                            v-if="section_element.element_type ==='CHOICE_ACTORS_MAIL' && !activeLastReport[section.order-1]"
                            v-model="section_element.element_values"
                            @save="save_section({section})"
                        />
                        <ImageSelector
                            v-if="section_element.element_type === 'IMAGE' && !activeLastReport[section.order-1]"
                            :section_id="section.section_id"
                            :element_id="section_element.element_id"
                            :properties="section_element.properties"
                        />
                        <AttachmentSelector
                            v-if="section_element.element_type === 'PJ' && !activeLastReport[section.order-1]"
                            :section_id="section.section_id"
                            :element_id="section_element.element_id"
                            :properties="section_element.properties"
                        />
                        <Paragraphe
                            v-if=" section_element.element_type === 'PARAGRAPH_VC' && $mq!='mobile'"
                            v-model="section_element.element_values"
                            :paragrapheValuesLastReport="last_rapport_section_value(section.section_id,section_element.element_id)"
                            :fromLastReport="activeLastReport[section.order-1]"
                            :properties="section_element.properties"
                            @valide-recup="changeFromlastReport(section.order-1)"
                            @save="save_section({section})"
                        />
                        <ParagrapheMobile
                            v-if=" section_element.element_type === 'PARAGRAPH_VC' && $mq=='mobile'"
                            v-model="section_element.element_values"
                            :paragrapheValuesLastReport="last_rapport_section_value(section.section_id,section_element.element_id)"
                            :fromLastReport="activeLastReport[section.order-1]"
                            :properties="section_element.properties"
                            @valide-recup="changeFromlastReport(section.order-1)"
                            @save="save_section({section})"
                        />
                        <TextAreaMC
                            v-if="section_element.element_type === 'TEXTAREA_MC'
                            ||section_element.element_type === 'PARAGRAPH_IC'
                            || section_element.element_type === 'PARAGRAPH_IC_EXP'
                            || section_element.element_type === 'PARAGRAPH_CRR'"
                            :fromLastReport="activeLastReport[section.order-1]"
                            :properties="section_element.properties"
                            v-model="section_element.element_values"
                            :textValueLastReport="last_rapport_section_value(section.section_id,section_element.element_id)"
                            @valide-recup="changeFromlastReport(section.order-1)"
                            @save="save_section({section})"
                        />
                    </div>
                </div>
            </div>
        </div>
        <a-modal class="pwa-modal"
            :visible="change_title.started"
            :footer="null"
            @cancel="handleCancelPreview"
        >
            <div class="change-title-modal">
                <div>Quel est le nouveau titre de la section ?</div>
                <a-input style="margin-top:10px" v-model='change_title.new_title' />
                <a-button style="width:20%" class="button" @click="change_title.new_title=change_title.section.title">Réinitialiser</a-button>
                <a-button style="width:80%" class="button" @click="save_new_title_section">Valider</a-button>
            </div>
        </a-modal>
    </div>
</template>
<script>
import ChoiceActorMail from "../sections/ChoiceActorMail";
import ImageSelector from "../sections/ImageSelector";
import ProjectInformation from "../sections/ProjectInformation";
import InfoGeneAeu from "../sections/InfoGeneAeu";
import EntrepriseInformation from "../sections/EntrepriseInformation"
import AttachmentSelector from "../sections/AttachmentSelector";
import Paragraphe from "../sections/Paragraphes/Paragraphe";
import ParagrapheMobile from "../sections/Paragraphes/ParagrapheMobile";
import SignatureSider from "./SignatureSider";
import FromLastReportButton from "../sections/FromLastReportButton";
import TextAreaMC from "../sections/Paragraphes/TextAreaMC";
const Antd = require("ant-design-vue");


import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
    name: "SectionDisplay",
    components: {
        ChoiceActorMail,
        ImageSelector,
        AttachmentSelector,
        Paragraphe,
        TextAreaMC,
        SignatureSider,
        ProjectInformation,
        EntrepriseInformation,
        FromLastReportButton,
        ParagrapheMobile,
        InfoGeneAeu
    },
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data(){
        return {
        openSignature:false,
        sectionVisible:[],
        activeLastReport:[],
        askChangeTitle:false,
        change_title:{
            started:false,
            section:"",
            new_title:""
        },
        }
    },
    beforeMount(){
        _.map(this.sections,(section)=>{
            this.activeLastReport.push(false);
        })
    },
    methods: {
        ...mapActions("rapports", [
            "switch_locked_report",
            "set_user_rapport_section_selected_with_values",
            "save_section",
            "change_title_rapport_user",
            "check_mail_from_last"
        ]),
        checkActorsFromLast(section) {
            const element = _.head(section.section_elements);
            const values = this.last_rapport_section_value(section.section_id, element.element_id);
            const filteredValues = _.filter(values, (value) => {
                return _.find(this.allActors, ['id', value.id])
            });
            this.save_section_choice_actor(filteredValues, section, element);
        },
        changeFromlastReport(index){
            this.activeLastReport.splice(index,1,!this.activeLastReport[index])
        },
        launchChangeTitle(section){
            this.change_title.started=true;
            this.change_title.section=section;
            this.change_title.new_title=section.section_name;
        },
        save_new_title_section(){
            this.change_title.section.section_name=this.change_title.new_title;
            this.save_section({section:this.change_title.section});
            this.change_title.started = false;
        },
        handleCancelPreview() {
            this.change_title.started = false;
        },
        save_section_choice_actor(values,section,section_element){
            section_element.element_values=values;
            this.save_section({section});
        },
        selectSection(section){
            this.set_user_rapport_section_selected_with_values(section);
        },
        changeOpenSignature(val){
            this.openSignature=!this.openSignature;
        },
        visibilityChanged(isVisible, entry, section){
                if(isVisible){
                    this.sectionVisible.push(_.findIndex(this.sections,['section_id', section.section_id]))
                }else{
                    _.pull(this.sectionVisible,_.findIndex(this.sections,['section_id', section.section_id]));
                }
                setTimeout(function () {
                    if(this.sectionVisible.length>0)
                        this.selectSection(this.sections[_.min(this.sectionVisible)]);
                    else if( _.findIndex(this.sections,['section_id', this.sectionSelected.section_id])-1!=-1)
                    {
                       this.selectSection(this.sections[_.findIndex(this.sections,['section_id', this.sectionSelected.section_id])-1]);
                    }
                    let target=document.getElementById("sectiondef-"+this.sectionSelected.order)
                    target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop;
                }.bind(this), 300)
        },
        is_blocked_section(section) {
            return (section.blocked_signature && this.is_locked_report) || this.is_to_send_or_recap_report
        }
    },
    computed: {
        ...mapGetters("rapports", {
            is_locked_report: "is_locked_report",
            is_to_send_or_recap_report:"is_to_send_or_recap_report",
            header_rapport: "get_header_rapport",
            sectionSelected: "get_user_rapport_section_selected",
            last_rapport_section_value:"get_last_rapport_section_element_value",
            has_last_rapport_value:"has_last_rapport_value"
        }),
        ...mapGetters("project",{project :'getProject', allActors: "getAllActors"}),
        is_intervenant_section() {
            return (section) => {
                return _.head(section.section_elements).element_type === "CHOICE_ACTORS_MAIL";
            }
        }
    },
};
</script>