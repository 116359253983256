export default {
    displayState: "1",
    online: true,
    loaders:[],
    activeModule:"GED",
    loadingInitPercent:0,
    update: {
        type: "",
        value: ""
    },
    viewableFileId: false,
    lastRouteName:"",
    interruptCurrentLoading: [],
    stockagePercent:0,
    updateOk:false,
    mustReconnect:false,
    forceOffline:false,
    adminModalVisible:false,
};