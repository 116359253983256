import _ from 'lodash';

export default {
  UPDATE_FILES_TO_DISPLAY(state, payload) {
    if (state.currentPage == 1) {
      state.filesStandardToDisplay = payload.filesStandardToDisplay;
      state.filesToVisaToDisplay = payload.filesToVisaToDisplay;
      state.filesLocalToDisplay = payload.filesLocalToDisplay;
    }
    else {
      state.filesStandardToDisplay.documents = _.concat(state.filesStandardToDisplay.documents, payload.filesStandardToDisplay.documents);
      state.filesToVisaToDisplay.documents = _.concat(state.filesToVisaToDisplay.documents, payload.filesToVisaToDisplay.documents);
      state.filesLocalToDisplay.documents = _.concat(state.filesLocalToDisplay.documents, payload.filesLocalToDisplay.documents);
    }
    state.countFilesToVisa = payload.countFilesToVisa;
  },
  ADD_LOCAL_FILE_TO_DISPLAY(state, file) {
    state.filesLocalToDisplay.documents.push(file);
    state.filesLocalToDisplay.total_documents += 1;
  },
  DELETE_LOCAL_FILE_TO_DISPLAY(state, file) {
    state.filesLocalToDisplay.documents = _.reject(state.filesLocalToDisplay.documents, { id: file.id });
    console.log("delete")

    state.filesLocalToDisplay.total_documents -= 1;
  },
  CHANGE_CURRENT_PAGE(state, number) {
      state.currentPage = number;
  },
  CHANGE_FILE_TO_VIEW(state, file) {
    state.fileToView = file;
  }
};