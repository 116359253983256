<template>
    <div class="paragraphe">
        <div v-if="!fromLastReport">
            <div class="child-section detail-paragraphe" v-for="(paragraphe, index) in paragraphes" :key="index">
                <div class="tableau-section">
                    <div>
                        <div
                            class="subtitle-section-detail description"
                            :id="'description_'+index"
                            :class="$mq"
                            style="border-right: 1px solid white"
                        >
                            {{ $t("rapportedit.sections.description") }}
                        </div>
                        <div 
                        class="subtitle-section-detail action"
                        :class="$mq"
                        :id="'action_'+index"
                        >
                        {{ $t("rapportedit.sections.action")}}
                            <a-button
                                v-if="paragraphes.length > 1 && !is_locked_report"
                                style=" float:right; margin: 5px 5px 0px 0px;"
                                type="danger"
                                @click="suppressionParagraphe(index)"
                                ><a-icon type="delete"
                            /></a-button>
                        </div>
                    </div>
                    <div>
                        <div class="simple-editor">
                            <Editor
                                :mustUpdate="mustUpdateEditor"
                                :tools="!is_locked_report"
                                :disabled="is_locked_report"
                                v-model="paragraphe.description"
                                @save="$emit('save')"
                            />
                        </div>
                        <div class="simple-editor" >
                            <Editor
                                :mustUpdate="mustUpdateEditor"
                                :tools="!is_locked_report"
                                :disabled="is_locked_report"
                                v-model="paragraphe.action"
                                @save="$emit('save')"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="ajout-paragraphe"
                v-if="
                    properties.can_add && 
                    paragraphes.length < 6 && !is_locked_report &&
                    !paragrapheVide
                "
                @click="ajoutParagraphe"
            >
                <a-icon class="iconButton" type="plus" />{{
                    $t("rapportedit.sections.add_paragraph")
                }}
            </div>
        </div>
        <div v-else>
            <div class="child-section detail-paragraphe" v-for="(paragraphe, index) in paragrapheValuesLastReport" :key="index">
                <div class="tableau-section">
                    <div>
                        <div style="width:40px; background:#cccccc"></div>
                        <div
                            class="subtitle-section-detail description"
                            :class="$mq"
                            style="border-right: 1px solid white"
                        >
                            {{ $t("rapportedit.sections.description") }}
                        </div>
                        <div class="subtitle-section-detail action" :class="$mq"> 
                            {{ $t("rapportedit.sections.action") }}
                        </div>
                    </div>
                    <div>
                        <div style="width:40px; text-align:center;vertical-align:middle; background:#cccccc">
                            <a-checkbox :disabled="paragraphes.length + lastReportValues.length >= 6" @change="select_for_recup($event,paragraphe,index)"/>
                        </div>
                        <div class="simple-editor" >
                            <Editor
                                :tools="false"
                                :disabled="true"
                                :value="paragraphe.description"
                                
                            />
                        </div>
                        <div class="simple-editor action">
                            <Editor
                                :tools="false"
                                :disabled="true"
                                :value="paragraphe.action"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="lastReportValues.length>0" class="button-recuperation" @click="confirm_recup">Valider la récupération</div>
        </div>
    </div>
</template>
<script>
import Editor from "./Editor";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Paragraphe",
    components: {
        Editor,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        paragrapheValuesLastReport: {
            type: Array,
            required: true,
        },
        properties:{
            type:Object,
            required:false,
            default:()=>{return {}}
        },
        fromLastReport:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    data() {
        return {
            mustUpdateEditor: true,
            lastReportValues:[],
        };
    },
    watch:{
        is_locked_report:function(val){
            this.mustUpdateEditor=!this.mustUpdateEditor
        },
         fromLastReport:function(val){
        this.lastReportValues=[];
      }
    },
    methods: {
        confirm_recup(){
            if(this.paragraphes.length==1 && this.paragraphes[0].description=="" && this.paragraphes[0].action=="")
            {
                 this.paragraphes=_.map(JSON.parse(JSON.stringify(this.lastReportValues)),(lastReportValue)=>{return lastReportValue.value})
            }
            else
            {
                this.paragraphes=_.union(this.paragraphes,_.map(JSON.parse(JSON.stringify(this.lastReportValues)),(lastReportValue)=>{return lastReportValue.value}));
            }
            this.$emit('valide-recup');
        },
        select_for_recup(e,value,index){
            if(e.target.checked){
                this.lastReportValues.push({id:index,value:value});
            }
            else
            {
                this.lastReportValues.splice(_.findIndex(this.lastReportValues,["id",index]),1)
            }
            console.log(this.lastReportValues);
        },
        ajoutParagraphe() {
            this.paragraphes.push({ description: "", action: "" });
        },
        suppressionParagraphe(index) {
             this.$confirm({
                title: 'Etes vous sûr de vouloir supprimer le paragraphe ?',
                content: "La suppression est irréversible",
                okText: 'Oui',
                okType: 'danger',
                cancelText: 'Non',
                onOk:()=> {
                    this.paragraphes = _.filter(this.paragraphes, (value, idx) => idx != index);
                    this.mustUpdateEditor = !this.mustUpdateEditor;
                },
                onCancel() {
                
                },
            });
        },
    },
    computed: {
        ...mapGetters("rapports", { is_locked_report: "is_locked_report" }),
        paragrapheVide() {
            if (
                this.paragraphes[this.paragraphes.length - 1].action == "" &&
                this.paragraphes[this.paragraphes.length - 1].description == ""
            ) {
                return true;
            }
            return false;
        },
        paragraphes:{
            get(){
             return this.value;
            },
            set(value){
                this.$emit('input',value)
                this.$emit('save');
            }
        }
    },
};
</script>
