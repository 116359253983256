<template>
  <div class="flag-doc-renderer">
    <a-tag
      v-if="file['flag']!==undefined && file['flag']!=''"
      style=" border: 1px solid rgb(175, 27, 74); 
          color: rgb(175, 27, 74);"
    >{{ file['flag'].toUpperCase() }}</a-tag>
  </div>
</template>
<script>
export default {
  name: "FlagDocRenderer",
  props: ["file"],
};
</script>



