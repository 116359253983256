var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraphe-mobile"},[(!_vm.fromLastReport)?_c('div',[_vm._l((_vm.paragraphes),function(paragraphe,index){return _c('div',{key:index,staticClass:"child-section detail-paragraphe"},[(_vm.paragraphes.length > 1 && !_vm.is_locked_report)?_c('div',{staticStyle:{"height":"30px"}},[_c('a-button',{staticStyle:{"margin-bottom":"10px","float":"right"},attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.suppressionParagraphe(index)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1):_vm._e(),_c('a-collapse',[_c('a-collapse-panel',{staticClass:"entete",attrs:{"header":_vm.$t('rapportedit.sections.description')}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[(paragraphe.description)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../../../../assets/edit_white.png")}}):_vm._e()]),_c('div',{staticClass:"simple-editor"},[_c('Editor',{attrs:{"mustUpdate":_vm.mustUpdateEditor,"tools":!_vm.is_locked_report,"disabled":_vm.is_locked_report},on:{"save":function($event){return _vm.$emit('save')}},model:{value:(paragraphe.description),callback:function ($$v) {_vm.$set(paragraphe, "description", $$v)},expression:"paragraphe.description"}})],1)]),_c('a-collapse-panel',{staticClass:"entete",attrs:{"header":_vm.$t('rapportedit.sections.action')}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[(paragraphe.action)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../../../../assets/edit_white.png")}}):_vm._e()]),_c('div',{staticClass:"simple-editor"},[_c('Editor',{attrs:{"mustUpdate":_vm.mustUpdateEditor,"tools":!_vm.is_locked_report,"disabled":_vm.is_locked_report},on:{"save":function($event){return _vm.$emit('save')}},model:{value:(paragraphe.action),callback:function ($$v) {_vm.$set(paragraphe, "action", $$v)},expression:"paragraphe.action"}})],1)])],1)],1)}),(
                _vm.properties.can_add &&
                _vm.paragraphes.length < 4 &&
                !_vm.is_locked_report &&
                !_vm.paragrapheVide
            )?_c('div',{staticClass:"ajout-paragraphe",on:{"click":_vm.ajoutParagraphe}},[_c('a-icon',{staticClass:"iconButton",attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("rapportedit.sections.add_paragraph"))+" ")],1):_vm._e()],2):_c('div',[_vm._l((_vm.paragrapheValuesLastReport),function(paragraphe,index){return _c('div',{key:index,staticClass:"child-section detail-paragraphe"},[_c('div',{staticClass:"tableau-section"},[_c('div',[_c('div',{staticStyle:{"width":"40px","text-align":"center","vertical-align":"middle","background":"#cccccc"}},[_c('a-checkbox',{attrs:{"disabled":_vm.paragraphes.length +
                                        _vm.lastReportValues.length >=
                                    4},on:{"change":function($event){return _vm.select_for_recup($event, paragraphe, index)}}})],1),_c('a-collapse',[_c('a-collapse-panel',{staticClass:"entete",attrs:{"header":_vm.$t('rapportedit.sections.description')}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[(paragraphe.description)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../../../../assets/edit_white.png")}}):_vm._e()]),_c('div',{staticClass:"simple-editor"},[_c('Editor',{attrs:{"tools":false,"disabled":true,"value":paragraphe.description}})],1)]),_c('a-collapse-panel',{staticClass:"entete",attrs:{"header":_vm.$t('rapportedit.sections.action')}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[(paragraphe.action)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../../../../assets/edit_white.png")}}):_vm._e()]),_c('div',{staticClass:"simple-editor"},[_c('Editor',{attrs:{"tools":false,"disabled":true,"value":paragraphe.action}})],1)])],1)],1)])])}),(_vm.lastReportValues.length > 0)?_c('div',{staticClass:"button-recuperation",on:{"click":_vm.confirm_recup}},[_vm._v(" Valider la récupération ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }