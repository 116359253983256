<template>
    <div class="switchOffline">
        <a-switch
            id="switchOffline"
            :class="'documentOffline ' + file.offline"
            :checked="file.offline != 'notDownloaded'"
            @change="addOrDeleteOffline(file)"
        />
    </div>
</template>

<script>
import File_db from "~/db_access/File";
import File_API from "~/service/Ged/File";
import { getSizeFile } from "~/src/common";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "SwitchOffline",
    props: {
        file: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("operations", [
            "startFileOperation",
            "finishFileOperation",
            "setSizeDownloaded",
            "deleteDownloadFile",
        ]),
        ...mapActions(["addInterruptCurrentLoading"]),
   addOrDeleteOffline(file) {
      if(!this.online){
        this.$confirm({
                title: "Etes vous sûr de vouloir supprimer l'accès hors-ligne à ce document ?",
                content: "Cette suppression sera irréversible jusqu'à la désactivation du mode hors-ligne",
                okText: 'Oui',
                okType: 'danger',
                cancelText: 'Non',
                onOk:()=> {
                 this.action(file);
                },
                onCancel() {
                  return;
                },
            });
      }else{
          this.action(file);
      }
   },
   async action(file){
      if (file.offline == "downloadInProgress") {
        this.addInterruptCurrentLoading(file.id);
        this.deleteDownloadFile(file.id);
      }
        let startState= file.offline;
        file.offline = "downloadInProgress";
        await this.startFileOperation({
          id: file.id,
          name: file.lib,
          org_name: file.org_name,
          size: getSizeFile(file),
          type: startState == "notDownloaded"?"offline+":"offline-",
          status: "active",
          projectName: this.project.lib,
          dowloadedSize: 0,
          projectId: this.project.id,
          percent: 0,
        });
          File_db.get_file(file,false,true).then(async (value) => {
          if(!value){
            await File_API.dl(file,true,!this.project.isPresents).then((res)=>{
              if(res){
                 this.finishFileOperation({
                  file: file,
                  type: startState == "notDownloaded"?"offline+":"offline-",
                });
              }
            })
          }else{
              await File_db.update_user_file(file);
             this.finishFileOperation({
                  file: file,
                  type: startState == "notDownloaded"?"offline+":"offline-",
                });
          }

          });
        }
    },
  computed:{
    ...mapGetters("project", {
      project: "getProject",
    }),
    ...mapGetters({online:"getOnline"}),
  }

}

</script>

<style>
#switchOffline.documentOffline.downloadInProgress.ant-switch-checked {
    background-color: orange;
}

#switchOffline.documentOffline.downloadFinish.ant-switch-checked {
    background-color: #2994c0;
}

#switchOffline::after {
    background-image: url("../../assets/UnConnected.png");
    background-size: cover;
}
</style>