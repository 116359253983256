import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex);

const store = new Vuex.Store({
    ...require('./modules/common/'),
    modules: {
        login: require('./modules/login'),
        arborescence: require('./modules/arborescence'),
        company: require('./modules/company'),
        filedetail: require('./modules/filedetail'),
        files: require('./modules/files'),
        directories: require('./modules/directories'),
        operations: require('./modules/operations'),
        project: require('./modules/project'),
        viewer: require('./modules/viewer'),
        rapports: require('./modules/rapports'),
    },
    plugins: [createPersistedState()],
});

export default store;
