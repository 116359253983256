import Company_db from "~/db_access/Company";
import User from "~/service/Common/User";
import _ from "lodash";


export default {
      async updateCompanies({commit,rootGetters}){
        let companies_offline=await Company_db.get_company_offline();
        commit("CHANGE_OFFLINE_COMPANIES", companies_offline);
        if(rootGetters.getOnline){
          let companies_online=await User.get_companies();
          _.map(companies_offline,(company_offline)=>{
            let company_online = _.find(companies_online,['id',company_offline.id]);
            if (company_online) {
              company_online.isOffline=true;
            } else {
              Company_db.delete_infos_company();
            }
          });
          commit("CHANGE_ONLINE_COMPANIES", companies_online);
        }
      },
      setCompany({commit},company){
        commit("CHANGE_COMPANY",company)
      }
};
