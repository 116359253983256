import { getSizeFile, defineAction, isToVisa } from '~/src/common'
import _ from 'lodash';
import Project_API from "~/service/Ged/Project.js";
import File_API from "~/service/Ged/File.js";
import File_db from "~/db_access/File.js";
export default {

  changeCurrentPage({ commit }, page) {
    commit("CHANGE_CURRENT_PAGE", page);
  },
  addLocalFileToDisplay({ commit }, file) {
    commit('ADD_LOCAL_FILE_TO_DISPLAY', file);
  },
  deleteLocalFileToDisplay({ commit }, file) {
    commit('DELETE_LOCAL_FILE_TO_DISPLAY', file);
  },

  async updateLocalFile({ dispatch, getters, rootGetters }) {
      let filesVersion = await File_db.get_all_files_user_by_module('GED');
        for (var i = 0; i < filesVersion.length; i++) {
          let fileVersion = filesVersion[i];
          let file = await Project_API.get_obj_detail(fileVersion.id,fileVersion.company.id);
          if (file !== undefined && file.version !== fileVersion.version) {
            console.log(fileVersion)
            file={...file,
              'company': fileVersion.company,
              'project': fileVersion.project,
              'module_file':'GED',
              'version_changed':true}
            await dispatch('operations/startFileOperation',
              {
                "id": file.id,
                "projectId": file.project.id,
                "name": file.lib,
                "size": getSizeFile(file),
                "type": "synchro",
                "status": "active",
                "projetName": file.project.lib,
                "dowloadedSize": 0,
                "percent": 0
              }, { root: true }
            );
            File_API.dl(file,true,true).then( async (file_data) => {
              dispatch('operations/finishFileOperation', { file: file, type: "synchro" }, { root: true });
              await File_db.delete_chunks(fileVersion.chunk_ids);
            });
          }
        }
  },

  async updateFilesToDisplay({ commit, dispatch, getters, rootGetters }, { selectedKeyDirectory, page = 1 }) {
    await dispatch('changeCurrentPage', page?page:getters.getCurrentPage+1);
    let loaderLinked = await dispatch("getNewLoaderAvailable", null, { root: true });
    let filesStandardToDisplay = {}
    let filesToVisaToDisplay = {}
    let filesLocalToDisplay = {};
    let countFilesToVisa = 0;
    let files = [];
    let percentPerFile = 0;
    let countResultPerPage = getters.getPageSize;
    let filesToVisa = [];
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 10 }, { root: true });

    //Mise a jour des fichiers standard
    let folderIdStandard = rootGetters['arborescence/getArboActivationStandard'] ? selectedKeyDirectory : null
    let company=rootGetters['company/getCompany'];
    let project=rootGetters['project/getProject'];
    let filesInDb = await File_db.get_files_by_root_id({ folder_id: folderIdLocal, page_id: getters.getCurrentPage });
    if (rootGetters.getOnline) {
      await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 20 }, { root: true });
      files = await File_API.get_files_by_root_id({ root_id: folderIdStandard, page_id: getters.getCurrentPage, loader: { loaderLinked, startPercent: 20, endPercent: 80 } });
      if (!files) {
        return;
      }
      filesToVisa=await File_API.get_all_files_to_visa();
      await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 80 }, { root: true });
      percentPerFile = (90 - 80) / files['documents_per_page'];
      let cpt = 0;
      for (var z = 0; z < files.documents.length; z++) {
        cpt++;
        if (files.documents[z] !== undefined) {
          files.documents[z] ={
          ...files.documents[z],
          'company': company,
          'project': project,
          'module_file':'GED',
          'flag':_.filter(filesToVisa.documents,(fileToVisa)=>{return fileToVisa.id==files.documents[z].id}).length>0?'A Viser':'',
          }
          files.documents[z]['offline']=await defineAction(files.documents[z]);
        }
        await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: Math.round(80 + cpt * percentPerFile) }, { root: true });
      }
      filesStandardToDisplay = files;
      await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 90 }, { root: true });
      
      //Mise à jour des fichiers à Viser
      let folderIdVisa = rootGetters['arborescence/getArboActivationVisa'] ? selectedKeyDirectory : null

      let tempFilesToVisa = []
      Promise.all(_.map(filesToVisa.documents, async (file, index) => {
        if (folderIdVisa === null || folderIdVisa === file.pere) {
          countFilesToVisa++;
          if (index >= countResultPerPage * (page - 1) && index < countResultPerPage * page) {
            file["flag"] = "A Viser";
            file={
              ...file,
              'company': company,
              'project': project,
              'module_file':'GED',
              'flag':"A Viser"
              }
            file['offline']=await defineAction(file);
            tempFilesToVisa.push(file);
          }
        }
      }));
      filesToVisa.documents = tempFilesToVisa;
      filesToVisa.total_documents = countFilesToVisa;
      filesToVisaToDisplay = filesToVisa;
    }
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 95 }, { root: true });


    //Mise a jour des fichiers Hors lignes
    let folderIdLocal = rootGetters['arborescence/getArboActivationLocal'] ? selectedKeyDirectory : null
    filesLocalToDisplay = filesInDb;
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 98 }, { root: true });

    commit('UPDATE_FILES_TO_DISPLAY',
      {
        filesStandardToDisplay: filesStandardToDisplay,
        filesToVisaToDisplay: filesToVisaToDisplay,
        filesLocalToDisplay: filesLocalToDisplay,
        countFilesToVisa: countFilesToVisa,

      })
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 100 }, { root: true });
    setTimeout(async () => {
      await dispatch("resetLoaders", null, { root: true });
    }, 500);


  },

  setFileToView({ commit }, file) {
    commit('CHANGE_FILE_TO_VIEW', file);
  },
  resetFilesToDisplay({ commit }) {
    commit('UPDATE_FILES_TO_DISPLAY',
      {
        filesStandardToDisplay: { total_documents: 0, documents: [] },
        filesToVisaToDisplay: { total_documents: 0, documents: [] },
        filesLocalToDisplay: { total_documents: 0, documents: [] },
        countFilesToVisa: 0,
      });
  }
};
