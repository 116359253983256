<template>
  <div class="pwa-screen">
    <a-layout >
      <a-layout-header class="layout-header"      >
        <Header
          :displayLogoButton="true"
          :displayBackButton="true"
          :displaySearchBar="true"
          :displayTeamButton="true"
          :displayOfflineButton="true"
          :displayModuleButton="true"
          :title="project.lib"
        />
      </a-layout-header>
      <a-layout-content>
        <AllModeleSelection />
      </a-layout-content>
    </a-layout>
  </div>
</template>
<script>
import Header from "./applayout/Header";
import AllModeleSelection from "./rapportcreation/AllModeleSelection";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ModeleRapportSelectionScreen",
  components: {
    Header,
    AllModeleSelection,
  },
  computed: {
    ...mapGetters("project", { project: "getProject" }),
  },
};
</script>


