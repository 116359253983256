<template>
  <div class="file-selection">
    <TreeSider v-show="arboOpen && arboActivation" @close-sider="openArbo"/>
    <div id="tableauDocuments">
      <div v-if="$mq!=='mobile'" class="entete-file-selection">
        <div>
          <div :class="classButton">
            <div 
              :disabled="!arboActivation"
              class="arbo-button" 
              :class="$mq"
              @click="openArbo()"
            >
              <img
                alt
                v-if="arboOpen"
                src="../../assets/navigationOpen.png"
                style="margin-right:10px; width:25px;"
              />
              <img
                alt
                v-if="!arboOpen"
                src="../../assets/navigationClose.png"
                style="margin-right:10px; width:25px;"
              />
              <span>{{titreArbo}}</span>
              <!-- <span v-if='$mq!=="mobile"'>{{titreArbo}}</span> -->
              <a-divider class="separation-arbo" type="vertical" />
              <a-switch
              size="small"
              class="arbo-switch"
              :checked="arboActivation"
              @change="onChangeArbo"
            />
            </div>
            <!-- <span v-if='$mq!=="mobile"'><a-divider class="separation-arbo" type="vertical" /></span> -->
            
          </div>

          <!-- <div class="button-zone right-buttons">
            <div
              style="display: inline-flex"
              id="progressButton"
              v-if="online"
              @click="showProgressModal"
            >
              <div class="progressButton">
                <img style="width:20px" alt src="../../assets/UnConnected.png" />
                <div>{{countOfflineInProgress}}</div>
              </div>
              <div class="progressButton">
                <img style="width:20px" alt src="../../assets/download.png" />
                <div>{{countDownloadInProgress}}</div>
              </div>
              <div class="progressButton">
                <img style="width:20px" alt src="../../assets/synchronisation.png" />
                <div>{{countSynchroInProgress}}</div>
              </div>
            </div>
          </div>
          <a-modal
            v-model="progressModal"
            :closable="false"
            :footer="null"
            :centered="true"
            class="pwa-modal"
            :title="$t('fileselection.operation_manager')"
          >
            <ProgressModal />
          </a-modal> -->
        </div>
      </div>
      <div>
        <a-tabs class="onglets" :activeKey="displayState" @change="callback">
          <a-tab-pane v-if="online" key="1">
            <span slot="tab">
              <a-icon type="file" />
              {{ $t('fileselection.tab.files')}}
            </span>
          </a-tab-pane>
          <a-tab-pane v-if="online" key="2">
            <span slot="tab">
              <a-icon type="file-done" />
              
              <!-- <a-badge v-if="countFilesToVisa>0" :count="countFilesToVisa">
                <a href="#" class="head-example" /> -->
                <!-- {{ $t('fileselection.tab.waitingforvisa')}} -->
              <!-- </a-badge> -->
              <span >{{ $t('fileselection.tab.waitingforvisa')}}</span>
              <span v-if="countFilesToVisa>0" class="count-file">{{countFilesToVisa}}</span>
            </span>
          </a-tab-pane>
          <a-tab-pane key="3">
            <span slot="tab">
              <a-icon type="file-sync" />
              {{ $t('fileselection.tab.offline')}}
            </span>
          </a-tab-pane>
        </a-tabs>
        <DocumentsList />
      </div>
    </div>
  </div>
</template>

<script>
import TreeSider from "./TreeSider";
import DocumentsList from "./DocumentsList";
import ProgressModal from "./ProgressModal";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AllFilesSelection",
  components: {
    DocumentsList,
    TreeSider,
    ProgressModal,
  },
  data() {
    return {
      filterDisplay: false,
      progressModal: false,
      blockOpen:false,
      
    };
  },
  methods: {
    ...mapActions([
      "changeDisplayState",
      "updateFilesAndDirectoryToDisplay",
      "createLoader",
    ]),

    ...mapActions("arborescence", ["setArboOpen", "setArborescenceActive"]),

    async callback(key) {
      await this.changeDisplayState(key);
      this.correctionAffichage(this.arboOpen);
    },
    displayFilter() {
      this.filterDisplay = !this.filterDisplay;
    },
    showProgressModal() {
      this.progressModal = true;
    },
    async openArbo() {
       setTimeout(async () => {
              if(!this.blockOpen){
                   await this.setArboOpen(!this.arboOpen);
                   this.correctionAffichage(this.arboOpen);
              }
              else
                  this.blockOpen=false;
              }, 50);
     
    },
    async onChangeArbo(checked) {
      console.log(checked)
      this.blockOpen=true;
      await this.setArborescenceActive({
        displayState: this.displayState,
        boolean: checked,
      });
      if (!this.arboActivation) {
        this.correctionAffichage(false);
      } else {
        this.correctionAffichage(this.arboOpen);
      }
      this.createLoader([20, 80]).then(()=>{
          this.updateFilesAndDirectoryToDisplay({
            selectedKeyDirectory: this.selectedKeyDirectory,
          });
        });
    },
    correctionAffichage(open) {
      var tree = document.getElementsByClassName("content")[0];
      var titreTree = document.getElementsByClassName("title")[0];
      var tableau = document.getElementById("tableauDocuments");
      if (open) {
        if(titreTree) titreTree.style.width = tree.offsetWidth;
        
        tableau.style.marginLeft = tree.offsetWidth + "px";
      } else {
           if(titreTree)  titreTree.style.width = tree.offsetWidth;
        tableau.style.marginLeft = 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      displayState: "getDisplayState",
      loading: "getIsLoading",
      online: "getOnline",
      module: "getActiveModule",
    }),

    ...mapGetters("directories", {
      selectedKeyDirectory: "getSelectedKeyDirectory",
      directories: "getChildrensDirectories",
    }),

    ...mapGetters("files", {
      countFilesToVisa: "getCountFilesToVisa",
    }),

    ...mapGetters("arborescence", {
      arboOpen: "getArboOpen",
      treeData: "getTreeData",
      arboActivation: "getArboActivation",
    }),

    ...mapGetters("project", { projectId: "getProjectId" }),

    ...mapGetters("operations", {
      countDownloadInProgress: "getCountDownloadInProgress",
      countOfflineInProgress: "getCountOfflineInProgress",
      countSynchroInProgress: "getCountSynchroInProgress",
    }),
    titreArbo() {
      switch (parseInt(this.displayState)) {
        case 1:
          return this.$t("fileselection.tree.regular");
        case 2:
          return this.$t("fileselection.tree.visa");
        case 3:
          return this.$t("fileselection.tree.offline");
        default:
          return this.$t("fileselection.tree.regular");
      }
    },
    classButton() {
      return this.arboActivation ? "button-zone left-buttons " : "button-zone left-buttons disabled"
    },
  },
};
</script>