export default {
    CHANGE_PROJECT(state, project) {
        state.project = project;
    },
    CHANGE_ACTORS(state, actors) {
        state.actors = actors;
    },
    CHANGE_ONLINE_PROJECTS(state, projects) {
        state.online_projects = projects;
    },
    CHANGE_OFFLINE_PROJECTS(state, projects) {
        state.offline_projects = projects;
    },

};