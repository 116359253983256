<template>
  <div class="tree-sider" :class="$mq">
    <div class="title empty" v-if="$mq==='mobile'"></div>
    <div class="title" v-if="displayState==1 && $mq!=='mobile'">{{ $t('fileselection.tree.regular')}}</div>
      <div class="title" v-if="displayState==2 && $mq!=='mobile'">{{ $t('fileselection.tree.visa')}}</div>
      <div class="title" v-if="displayState==3 && $mq!=='mobile'" >{{ $t('fileselection.tree.offline')}}</div>
    <div class="tree" :class="$mq">
      
      <div class="content">
        <a-tree
          id="content"
          :tree-data="treeData"
          :selected-keys="[selectedKeyDirectory]"
          :expanded-keys="expandedKeys"
          @select="onSelect"
          @expand="onExpand"
        ></a-tree>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex'

export default {
  name: "TreeSider",
  data() {
    return {
      previousSelect: [],
      expandedKeys: ["0"],
    };
  },
  methods: {

    ...mapActions(['updateFilesAndDirectoryToDisplay','createLoader']),

    async onSelect(selectedKeys, expandedKeys) {
      if (selectedKeys.length === 0) {
        this.selectedKey = this.previousSelect;
      } else {
        this.previousSelect = selectedKeys;
         this.createLoader([20,80]).then(async ()=>{
        await this.updateFilesAndDirectoryToDisplay({
          selectedKeyDirectory: selectedKeys[0],
        });
        if(this.$mq==="mobile"){
            this.$emit('close-sider');
        }
      });
      }
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
  },
  mounted: function () {
    let e = document.getElementsByClassName("content")[0];
    e.addEventListener("transitionend", () => {
      var tree = e;
      var titreTree = document.getElementsByClassName("title")[0];
      var tableau = document.getElementById("tableauDocuments");
      titreTree.style.width=tree.offsetWidth;
      tableau.style.marginLeft = tree.offsetWidth+'px';
    });
  },
  computed: {
        ...mapGetters("directories",{ selectedKeyDirectory: "getSelectedKeyDirectory" }),

       ...mapGetters("arborescence",{ treeData: "getTreeData" }),
        ...mapGetters({ displayState: "getDisplayState" }),
  },
};
</script>
