import _ from 'lodash';
export default {
    CHANGE_SIZE_DOWNLOADED(state, value) {
        state.sizeDownloaded = value;
    },
    ADD_SIZE_DOWNLOADED(state, value) {
        state.sizeDownloaded += value;
    },
    ADD_DOWNLOAD_FILE(state, download) {
        state.activeDownloads.push(download);
    },
    ADD_SIZE_DOWNLOADED_FILE(state, { fileId, sizeDownloaded }) {
        state.activeDownloads = _.map(
            state.activeDownloads,
            (progressDownload) => progressDownload.id !== fileId ? progressDownload : {
                ...progressDownload,
                dowloadedSize: progressDownload.dowloadedSize + sizeDownloaded,
                percent: Math.round((progressDownload.dowloadedSize * 100) / progressDownload.size)
            },
        );
    },
    FINISH_DOWNLOAD_FILE(state, fileId) {
        // let d = new Date();
        // if (state.completeDownloads.length === 5){
        //     state.completeDownloads = _.take(state.completeDownloads, 4);

        // }
        // let dl = _.find(state.activeDownloads, { id: fileId });
        // state.completeDownloads.push({
        //     ...dl,
        //     id: dl.id + '-' + d.getTime(),
        //     dowloadedSize: dl.size,
        //     status: "complete",
        //     percent: 100,
        //     date: (d.getDay() < 10 ? '0' + d.getDay() : d.getDay()) + "/" + ((d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1)) + "/" + (d.getFullYear()) + ' - ' + d.getHours() + ':' + d.getMinutes()
        // });
        state.activeDownloads = _.reject(state.activeDownloads, { id: fileId })
    },

    DELETE_DOWNLOAD_FILE(state, fileId) {
        state.activeDownloads = _.reject(state.activeDownloads, { id: fileId });
    },
};