import _ from 'lodash';

export default {
  CHANGE_DISPLAY_STATE(state, number) {
    state.displayState = '' + number;
  },
  CREATE_LOADERS(state, loaders) {
    state.loaders = loaders;
  },
  CHANGE_LOADING_PERCENT(state, { loaderLinked, loadingPercent }) {
    if (loaderLinked != undefined) {
      let loader = _.find(state.loaders, { rang: loaderLinked.rang })
      if (loader) {
        loader.loadingPercent = loadingPercent;
      }
    }
  },
  LINK_LOADER(state, rang) {
    let loader = _.find(state.loaders, { rang: rang })
    if (loader != undefined) {
      loader.linked = true;
    }
  },
  RESET_LOADERS(state){
    state.loaders=[];
  },
  CHANGE_ACTIVE_MODULE(state,module){
    state.activeModule=module;
  },
  CHANGE_ONLINE(state,value){
    state.online=value;
  },
  CHANGE_INIT_LOADING_PERCENT(state,value){
    console.log(value)
    state.loadingInitPercent=value
  },
  CHANGE_UPDATE(state,value){
    state.update=value;
  },
  CHANGE_UPDATE_OK(state,value){
    state.updateOk=value;
  },
  CHANGE_NOT_RELOAD(state, value) {
    state.viewableFileId = value;
  },

  CHANGE_LAST_ROUTE_NAME(state, value) {
    state.lastRouteName = value;
  },
  ADD_INTERRUPT_CURRENT_LOADING(state, value) {
    state.interruptCurrentLoading.push(value);
  },
  RM_INTERRUPT_CURRENT_LOADING(state, value) {
    if (state.interruptCurrentLoading.includes(value)) {
      state.interruptCurrentLoading.splice(state.interruptCurrentLoading.indexOf(value), 1);
    }
  },
  CHANGE_STOCKAGE_PERCENT(state, value){
    state.stockagePercent=value;
  },
  CHANGE_MUST_RECONNECT(state, value){
    state.mustReconnect=value;
  },
  CHANGE_FORCE_OFFLINE(state, value){
    state.forceOffline = value;
  },
  set_admin_modal_visible(state, value){
    state.adminModalVisible = value;
  },
};