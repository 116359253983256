<template>
    <div class="child-section choice-actor-mail">
        <div>
            <div class="entreprise all-entreprise">
                <a-row>
                    <a-col :span="19" class="header-all-actors">{{ $t("rapportedit.sections.all_actors") }}</a-col>
                    <a-col :span="5" class="header-all-actors-choice">
                        <span v-if="$mq=='mobile'" class="value" @click="next_global_value($event)">{{ this.globalValue }}</span>
                        <a-button-group v-else>
                            <a-button @click="checkAll('AT');">TO</a-button>
                            <a-button @click="checkAll('CC');">CC</a-button>
                            <a-button @click="checkAll('NA');">NA</a-button>
                        </a-button-group>
                    </a-col>
                </a-row>
            </div>           
            <a-collapse :activeKey="collapse_open" @change="action">
              <a-collapse-panel :disabled="!can_collapse(entreprise)" :show-arrow="can_collapse(entreprise)" class="entreprise" v-for="(entreprise) in entreprises_actors" :key="entreprise" :header="get_header(entreprise)">
                <a-collapse :bordered="false">
                  <a-collapse-panel  class="destinataire" v-for="(actor,index) in destinataires_by_entreprise(entreprise)" :key="index" :header="actor.name">
                      <div slot="extra" @click='e=>e.stopPropagation()'>
                          <span v-if="$mq=='mobile'" class="value" @click="next_value($event,actor)">{{actor.value=='AT'?'TO':actor.value}}</span>
                          <div v-else class="radio-choice-mail">
                                <a-radio-group
                                    :value="actor.value"
                                    style="display: inline-flex"
                                    @change="set_value($event,actor.id)"
                                >
                                    <div>
                                        <a-radio class="select_column" value="AT">TO</a-radio>
                                    </div>
                                    <div>
                                        <a-radio class="select_column" value="CC">CC</a-radio>
                                    </div>
                                    <div>
                                        <a-radio class="select_column" value="NA">NA</a-radio>
                                    </div>
                                </a-radio-group>
                            </div>
                        </div>
                        <p>{{ $t('applayout.mail') }} : {{ actor.email==''?'Non renseigné':actor.email }}</p>
                        <p>{{ $t('applayout.phone') }} : {{ actor.gsm==''?'Non renseigné':actor.gsm }}</p>
                        <p v-if="!actor.is_intervenant">{{ $t('applayout.activity') }} : {{ actor.role==''?'Non renseigné':actor.role }}</p>
                        <p v-else>{{ $t('applayout.fonction') }} : {{ actor.role==''?'Non renseigné':actor.role }}</p>
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>
              <a-collapse-panel :disabled="true" :show-arrow="false" v-if="destinataires_added.length>0" class="entreprise" key="new" header="Autres contacts">
                <a-collapse :bordered="false">
                  <a-collapse-panel   class="destinataire" v-for="(actor,index) in destinataires_added" :key="index" :header="actor.name +'('+actor.org_lib+')'">
                      <div slot="extra" @click='e=>e.stopPropagation()'>
                          <span v-if="$mq=='mobile'" class="value" @click="next_value($event,actor)">{{actor.value=='AT'?'TO':actor.value}}</span>
                          <span v-else class="radio-choice-mail">
                                <a-radio-group
                                    :value="actor.value"
                                    style="display: inline-flex"
                                    @change="set_value($event,actor.id)"
                                >
                                    <div>
                                        <a-radio class="select_column" value="AT">TO</a-radio>
                                    </div>
                                    <div>
                                        <a-radio class="select_column" value="CC">CC</a-radio>
                                    </div>
                                </a-radio-group>
                            </span>
                            <span class="delete" @click="suppressionDestinataire(actor.id)"><a-icon type="delete"/></span>
                        </div>
                        <p>{{ $t('applayout.entreprise') }} : {{ actor.org_lib}}</p>
                        <p>{{ $t('applayout.mail') }} : {{ actor.email}}</p>
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>
            </a-collapse>
        </div>
        <div class="tableau-section" :style="$mq=='mobile'?'border-spacing: 5px':''">
            <div :class="$mq">
                <div v-if="$mq!='mobile' || ajout_mobile" :class="$mq">
                    <a-input
                        placeholder="Nom et prénom"
                        v-model="name"
                        size="large"
                    />
                </div>
                <div v-if="$mq!='mobile' || ajout_mobile" :style="$mq=='mobile'?'display:block;width:100%':''">
                    <a-input
                        placeholder="Entreprise"
                        v-model="org_lib"
                        size="large"
                    />
                </div>
                <div v-if="$mq!='mobile' || ajout_mobile" :style="$mq=='mobile'?'display:block;width:100%':''">
                    <a-input
                        placeholder="Adresse e-mail"
                        v-model="email"
                        size="large"
                    />
                    
                </div>
                <div :style="$mq=='mobile'?'display:block;width:100%':''"> 
                    <div class="button-ajout-entreprise " @click="ajoutDestinataire">
                    <a-icon class="iconButton" type="plus" />
                    {{$mq=='mobile'?$t("rapportedit.sections.add_staff_mobile"):$t("rapportedit.sections.add_staff")}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "ChoiceActorMail",
    props: {
            value: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            name: "",
            org_lib: "",
            email: "",
            open_tab:['new'],
            ajout_mobile:false,
            destinataires:[],
            valueChange:false,
            globalValue: 'NA'
        };
    },
     watch:{
        destinataires:{
            deep:true,
            handler(){
                if(!this.valueChange)
                {
                    console.log("Sauvegarde actors")
                    this.$emit('input', _.filter(this.destinataires,(destinataire)=>destinataire.value!='NA'));
                    this.$emit('save');
                }
                this.valueChange=false;
            }
        },
        value:{
            deep:true,
            immediate: true,
            handler(){
                this.valueChange=true;
                let actors=_.map(this.actors,(actor)=>{
                    let finalActor=_.find(this.value,['id',actor.id])
                    return finalActor?{...actor,...finalActor}:{...actor,value:"NA"};
                });
                this.destinataires= [...actors,..._.filter(this.value,(destinataire)=>{return destinataire.id.includes('new_')})];
            }
        }
     },
    methods: {
        action(keys){
            this.open_tab=keys;
        },
        next_value(event,actor){
            if (!(actor.id.includes('new_'))) {
                let destinataire=_.find(this.destinataires,['id',actor.id]);
                switch (destinataire.value) {
                    case "AT": destinataire.value="CC";break;
                    case "CC": destinataire.value="NA";break;
                    case "NA": destinataire.value="AT";break;
                    default:break;
                }
            } else {
                let destinataire=_.find(this.destinataires,['name',actor.name]);
                switch (destinataire.value) {
                    case "AT": destinataire.value="CC";break;
                    case "CC": destinataire.value="AT";break;
                    default:break;
                }   
            }
            this.$emit("save", _.filter(this.destinataires,(destinataire)=>{
                return destinataire.value!='NA'
            }));

        },
        set_value(event,id_destinataire){
            _.find(this.destinataires,['id',id_destinataire]).value=event.target.value;
        },
        suppressionDestinataire(id) {
            this.destinataires.splice(this.destinataires.indexOf(_.find(this.destinataires, ['id', id])), 1);
        },
        ajoutDestinataire() {
            if(this.$mq=='mobile' && !this.ajout_mobile){
                this.ajout_mobile=true;
                return;
            }
            if(this.getValideEmail(this.email)){
                let nb_new_dest = _.filter(this.destinataires,(destinataire)=>{return destinataire.id.includes('new_')}).length - 1;
                let suffixe     = nb_new_dest;
                do {
                    suffixe = suffixe + 1;
                } while (_.some(this.destinataires, ['id', `new_${suffixe}`]));
                this.destinataires.push({
                    id: `new_${suffixe}`,
                    name: this.name,
                    email: this.email,
                    org_lib: this.org_lib,
                    value: "AT",
                });
                this.name = "";
                this.org_lib = "";
                this.email = "";
                this.$emit("save", _.filter(this.destinataires,(destinataire)=>{
                    return destinataire.value!='NA'
                }));
                this.ajout_mobile=false;
            } else {
                this.$notification["error"]({message: "L'adresse email renseignée est invalide"});
            }
        },
        get_activities(entreprise){
            return _.join(_.map(_.filter(
                this.actors,
                function(item) {
                    return item.org_lib==entreprise && item.role != "";
                }), function(item) {
                    return item.role;
                }), ", ");
        },
        checkAll(value) {
            _.map(this.destinataires, function (destinataire) {
                destinataire.value = value;
            });
        },
        next_global_value(event) {
            switch (this.globalValue) {
                case "AT": this.globalValue="CC";break;
                case "CC": this.globalValue="NA";break;
                case "NA": this.globalValue="AT";break;
                default:break;
            }
            this.checkAll(this.globalValue);
        }
    },
  
   computed:{
        ...mapGetters('project',{actors:"getAllActors"}),
        ...mapGetters(["getValideEmail"]),
        entreprises_actors(){
            return _.uniq(_.sortBy(_.map(this.actors,'org_lib'), function(actor_lib){
                return _.toLower(actor_lib);
            }));
        },
        destinataires_added(){
            return _.filter(this.destinataires,(destinataire)=>{return destinataire.id.includes('new_')});
        },
        destinataires_by_entreprise(){
            return (entreprise)=>{
                let actors=_.map(_.filter(this.actors,['org_lib',entreprise]),(actor)=>{
                    return _.find(this.destinataires,['id',actor.id])
                });
                return actors;
            }
        },
        can_collapse(){
            return (entreprise)=>{
                return _.filter(this.destinataires, (destinataire)=>{
                    return destinataire.org_lib==entreprise && destinataire.value!='NA' 
                }).length==0;
            }
        },
        collapse_open(){
            let collabsable_entreprise=_.uniq(_.map(_.reject(this.destinataires, ['value','NA']),'org_lib'));

            return  _.union(collabsable_entreprise,this.open_tab);
        },
        get_header() {
            return (entreprise) => {
                const activities = this.get_activities(entreprise);
                if (!activities) {
                    return `${entreprise}`;
                }

                return `${entreprise} (${activities})`;
            }
        }
    }
};
</script>