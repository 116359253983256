import store from '~/src/store/';
import _ from 'lodash'
import { database } from '~/src/db';

const Project_db = {

async put_arbo(data) {
    let db = await database.db;
    let st_id = store.state.company.company.id;
    let project_id = store.state.project.project.id;
    return new Promise((resolve) => {
        let transaction = db.transaction("arbo", "readwrite");
        let arbo_store = transaction.objectStore("arbo");
        transaction.oncomplete = function (event) {
            // console.log("register_arbo_to_db : transaction terminated");
            resolve();
        };
        transaction.onerror = function (event) {
            // console.log('register_arbo_to_db : arbo already downloaded');
        };

        let request = arbo_store.put({
            'st_project_id': st_id + '_' + project_id,
            'st_id': st_id,
            'project_id': project_id,
            'arbo': data,
        });
        request.onsuccess = function (event) {
            // console.log('register_arbo_to_db: request successful');
        };
        request.onerror = function (event) {
            // console.log('register_arbo_to_db: request error');

        };
    });
},

async get_arbo() {
    let db = await database.db;
    let st_id = store.state.company.company.id;
    let project_id = store.state.project.project.id;
    return new Promise((resolve) => {
        let transaction = db.transaction("arbo", "readwrite");
        let request = transaction.objectStore('arbo').get(st_id + '_' + project_id);
        transaction.oncomplete = function (event) {
            // console.log("get_arbo_from_db : transaction terminated");
        }
        request.onsuccess = function (event) {
            // console.log('get_arbo_from_db: request successful');
            resolve(request.result.arbo);
        }
        request.onerror = function (event) {
            resolve(undefined);
            // console.log('get_arbo_from_db: request error');
        }
    });
},


async get_project_offline(project_id) {
    let db = await database.db;
    let projects = []
    let user_id = store.state.login.connectedUser.id;
    return new Promise((resolve) => {
        let transaction = db.transaction("infosProject", "readwrite");
        let request = transaction.objectStore('infosProject').index ('company_id').openCursor (
            IDBKeyRange.only(store.state.company.company.id)
        );
        request.onsuccess = function (event) {
            var cursor = request.result;
            if (cursor) {
                let project=cursor.value.project;
                project.isOffline=true;
                if(project.id==project_id){
                    resolve(project);
                }
                else if(!project_id && cursor.value.user_ids.includes(user_id)){
                    projects.push(project);
                }
                cursor.continue();
            }
            if(cursor==null){
                resolve(_.uniqBy(projects,"id"));
            }
        };
        request.onerror=function(event){
            resolve(projects);
        }
    });
},

async delete_infos_projet(project_id){
    let db = await database.db;
    let user_id=store.state.login.connectedUser.id;
    return new Promise((resolve) => {
        let transaction = db.transaction("infosProject", "readwrite");
        let objectStore=transaction.objectStore('infosProject');
        let request = transaction.objectStore('infosProject').index ('company_id').openCursor (
            IDBKeyRange.only(store.state.company.company.id)
        );
        let find=false;

        request.onsuccess = function (event) {
            var cursor = request.result;
            if (cursor) {
                if(cursor.value.project.id==project_id){
                    find=true;
                    if(cursor.value.user_ids.length>1){
                        cursor.value.user_ids.splice(cursor.value.user_ids.indexOf(user_id),1);
                        var res = cursor.update(cursor.value);
                        res.onsuccess = function(e){
                            console.log("update success!!");
                            resolve (true);
    
                        }
                        res.onerror = function(e){
                            console.log("update failed!!");
                            resolve (false);
    
                        }
                    }
                    else
                    {
                        let del =cursor.delete();
                        del.onsuccess = function() {
                            console.log('Suppression des infos projets réalisées');
                            resolve(true)
                        };
                    }
                }
                cursor.continue();
            }
        }
   
        request.onerror=function(event){
        resolve(false);
        }
    });
},

async register_infos_project({company,project,actors,modeles,last_reports}){
    let db = await database.db;
    let user_id=store.state.login.connectedUser.id;
    return new Promise((resolve) => {
        let transaction = db.transaction("infosProject", "readwrite");
        let objectStore=transaction.objectStore('infosProject');
        let request = transaction.objectStore('infosProject').index ('company_id').openCursor (
            IDBKeyRange.only(company.id)
        );
        request.onsuccess = function (event) {
            var cursor = request.result;
            let find=false;
            if (cursor) {
                if(cursor.value.project.id==project.id){
                    find=true;
                    if(actors)cursor.value.actors=actors;
                    if(modeles)cursor.value.modeles=modeles
                    if(last_reports)cursor.value.last_reports=last_reports;
                    if(!cursor.value.user_ids)
                    cursor.value.user_ids=[];
                    if(!cursor.value.user_ids.includes(user_id))cursor.value.user_ids.push(user_id);
                    var res = cursor.update(cursor.value);
                    res.onsuccess = function(e){
                        console.log("update success!!");
                        resolve (true);

                    }
                    res.onerror = function(e){
                        console.log("update failed!!");
                        resolve (false);

                    }
                }
                cursor.continue();
            }
            if(cursor==null){
                if(!find)
                request = objectStore.put({
                    'project_id': project.id,
                    'company':company,
                    'project':project,
                    'actors':actors,
                    'modeles':modeles,
                    'last_reports':last_reports,
                    'user_ids':[user_id]
                });

                request.onsuccess = function (event) {
                    // console.log('register_arbo_to_db: request successful');
                };
                request.onerror = function (event) {
                    // console.log('register_arbo_to_db: request error');
        
                };
            }
        };
        request.onerror=function(event){
            resolve(false);
        }
    });
    },

    async get_actors() {
        let db = await database.db;
        let project_id = store.state.project.project.id;
        console.log(project_id)
        return new Promise((resolve) => {
            let transaction = db.transaction("infosProject", "readwrite");
            let request = transaction.objectStore('infosProject').get(project_id);
            transaction.oncomplete = function (event) {
                // console.log("transaction terminated");
            }
            request.onsuccess = function (event) {
                resolve(request.result ? request.result.actors : []);
                // console.log('get_from_db: request successful');

            }
            request.onerror = function (event) {
                resolve(request.result);
                // console.log('get_from_db: request error');
            }
        });
    },


}

export default Project_db
