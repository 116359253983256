<template>
    <div class="document-list">
        <div class="tableau-list rapport" :class="$mq">
            <div class="tableau-files">
                <a-config-provider>
                    <template v-slot:renderEmpty>
                        <span>
                            <img
                                class="icon-empty"
                                src="../../assets/noFiles.png"
                            />
                            <span class="textEmpty">{{ $t('rapportselection.list_empty') }}</span>
                        </span>
                    </template>
                    <RapportsListDone v-if="Number(this.displayState) === 1" :rapportsDone="reportsDoneList"/>
                    <RapportsListInProgress v-if="Number(this.displayState) === 2" :rapportsInProgress="reportsInProgressList" />
                </a-config-provider>
            </div>
        </div>
    </div>
</template>
<script>
import RapportsListDone from "./RapportsListDone";
import RapportsListInProgress from "./RapportsListInProgress";
import { mapGetters } from "vuex";
import { filter } from "lodash";

export default {
    name: "RapportsList",
    components: {
        RapportsListDone,
        RapportsListInProgress,
    },
    methods: {
    },
    computed: {
        reportsDoneList() {
            return filter(this.get_rapports_to_display, function(rapport) { return !rapport.editing; });
        },
        reportsInProgressList() {
            return filter(this.get_rapports_to_display, function(rapport) { return rapport.editing; });            
        },
        ...mapGetters({
            displayState: "getDisplayState",
        }),
        ...mapGetters("rapports", ["get_rapports_to_display"]),
    },
};
</script>