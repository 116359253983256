<template>
    <div class="tile rapport">
        <a-row class="title-modele" :style="{ '--background': modele.color }">
            <a-col :span="24" style="margin: auto">
                {{ modele.description }}
            </a-col>
        </a-row>
        <a-row v-if="!rapport_en_cours" class="button-tile-modele full" :style="getStyleRowReport">
            <a-col class="col-tile-modele" v-if="modele.is_model && has_last_report" :span="12" @click="nouveau_rapport(true)">
                {{ $t("rapportcreation.using_last_report") }}
            </a-col>
            <a-col class="col-tile-modele" :span="modele.is_model && has_last_report ? 12 : 24" @click="nouveau_rapport(false)">
                {{ $t("rapportcreation.new_document") }}
            </a-col>
        </a-row>
        <a-row v-else class="button-tile-modele full" :style="getStyleRowReport">
            <a-col class="col-tile-modele" :span="24" @click="acces_rapport()">{{ $t("rapportcreation.edit_document") }}</a-col>
        </a-row>
        <div class="blocked-rapport" v-show="blocked_in_progress.length">
            <img src="../../assets/synchro_blocked.svg" alt="blocked_img">
            <span>{{ $t("rapportcreation.sync_error") }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Rapport_db from "~/db_access/Rapport";
import { filter, map, last } from "lodash";

export default {
    name: "RapportModeleTile",
    props: {
        modele: {
            type: Object,
            required: () => {},
        },
        blocked_in_progress: {
            type: Array,
            required: () => {},
        },
    },
    data() {
        return {
            rapport_en_cours: false,
            blockButton: false,
            has_last_report: false,
        };
    },
    async beforeMount() {
        this.has_last_report = this.has_last_report_values(this.modele);
        if (this.modele.id != 2) {
            let edit_rapports = await Rapport_db.get_edit_rapport(
                this.getProjectId,
                this.modele.detail_modele.id
            );
            let rapports_en_cours = filter(edit_rapports, function (o) {
                return o.header.status_id < 3;
            });
            if (rapports_en_cours.length > 0) {
                this.rapport_en_cours = true;
                if (rapports_en_cours.length === 1) {
                    this.id_rapport_associe = rapports_en_cours[0].header.rapport_id;
                }
            }
        }
    },
    computed: {
        ...mapGetters("project", ["getProjectId"]),
        ...mapGetters("rapports", ["has_last_report_values", "get_rapports_to_display"]),
        getStyleRowReport() {
            return {
                '--background': this.modele.color,
                '--opacity': 1,
                '--color': 'white',
            };
        }
    },
    methods: {
        ...mapMutations("rapports", ["REMOVE_IN_PROGRESS"]),
        ...mapActions("rapports", [
            "create_new_editing_report",
            "load_editing_report",
            "update_editing_reports_from_local_to_server",
        ]),
        async nouveau_rapport(from_last_report) {
            if (!this.blockButton) {
                this.blockButton = true;
                this.create_new_editing_report({
                    modele: this.modele,
                    from_last_report
                }).then(() => {
                    this.update_editing_reports_from_local_to_server();
                    this.$router.push({ name: "EditRapport" });
                    this.blockButton = false;
                });
            }
        },
        acces_rapport(rapport) {
            this.load_editing_report({
                modele_id: this.modele.detail_modele.id,
                rapport_id: this.id_rapport_associe,
            });
            this.$router.push({ name: "EditRapport" });
        },
    },
};
</script>
