<template>
    <a-table
        :columns="columnsRapports"
        :data-source="rapportsDone"
        :pagination="false"
        :showHeader="false"
        tableLayout="fixed"
        style="cursor: pointer"
        :customRow="custom_row_rapport"
        rowKey="id"
    >
        <span
            slot="name"
            slot-scope="rapport"
            style="display: inline-flex"
        >
            <IconeDocRenderer
                :type="rapport.format.toUpperCase()"
                styleImage="width:25px;margin-right:10px"
            />{{ rapport.lib }}</span
        >
        <span
            slot="dateAjout"
            slot-scope="date"
            v-if="date"
            style="
                font-style: italic;
                text-align: right;
                white-space: nowrap;
                font-size: small;
            "
        >
            {{ $t("validated_on") }}
            {{ date }}
        </span>
        <span
            slot="offline"
            slot-scope="rapport"
            @click="blockRow = true"
        >
            <SwitchOffline
                :file="rapport"
                @reload="reload"
            />
        </span>
    </a-table>
</template>
<script>
import IconeDocRenderer from "../renderer/IconeDocRenderer";
import SwitchOffline from "../common/SwitchOfflineFile";
import { mapGetters, mapActions } from "vuex";

import _ from "lodash";


export default {
    i18nOptions: {
        keyPrefix: "rapportselection"
    },
    name: "RapportsListDone",
    components: {
        IconeDocRenderer,
        SwitchOffline,
    },
    props: {
        rapportsDone: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            blockRow: false,
            columnsRapports: [],
        };
    },
    mounted() {
        this.columnsRapports = [{
            title: "Name",
            key: "name",
            scopedSlots: { customRender: "name" },
            width: "150px",
        }, {
            title: "Organisme",
            dataIndex: "org_name",
            key: "org_name",
            scopedSlots: { customRender: "organisme" },
            elipsis: true,
        }];
        if (this.$mq !== "mobile") {
            this.columnsRapports.push({
                title: "validation",
                dataIndex: "date_add",
                scopedSlots: { customRender: "dateAjout" },
                key: "date_add",
                width: "180px",
            });
        }
        this.columnsRapports.push({
            title: "Offline",
            key: "offline",
            scopedSlots: { customRender: "offline" },
            width: "70px",
        });
    },
    
    methods: {
        ...mapActions("files", ["setFileToView"]),
        ...mapActions("rapports", [
            "refresh_display_reports",
        ]),
        custom_row_rapport(record) {
            return {
                on: {
                    click: (event) => {
                        setTimeout(() => {
                            if (!this.blockRow) {
                                this.visualisation(record);
                            } else {
                                this.blockRow = false;
                            }
                        }, 50);
                    },
                },
            };
        },
        reload() {
            this.refresh_display_reports();
        },
        async visualisation(file) {
            if (file.format === "LINK") {
                window.open(file.lib, "_blank");
                return;
            }
            await this.setFileToView(file);
            this.$router.push({ name: "Viewer" });

            return;
        },
    },
}
</script>
