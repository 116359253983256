import Project_db from "~/db_access/Project";
import User from "~/service/Common/User";

import _ from 'lodash'

export default {
      setProject({commit}, project) {
         commit('CHANGE_PROJECT', project);
      },
      async updateProjects({commit,rootGetters}){
         commit('CHANGE_INIT_LOADING_PERCENT',10,{ root: true })
         let projects_offline=await Project_db.get_project_offline();
         commit('CHANGE_INIT_LOADING_PERCENT',20,{ root: true })
         commit('CHANGE_OFFLINE_PROJECTS',projects_offline);
         commit('CHANGE_INIT_LOADING_PERCENT',30,{ root: true })
         if(rootGetters.getOnline){
            let projects=await User.get_projects()
         commit('CHANGE_INIT_LOADING_PERCENT',50,{ root: true })

            _.map(projects_offline,(project_offline)=>{
               let projet_online=_.find(projects,['id',project_offline.id]);
               if(projet_online){
                  projet_online.isOffline=true;
               }
               else
               {
                  Project_db.delete_infos_projet(project_offline.id);
               }
            })
         commit('CHANGE_INIT_LOADING_PERCENT',80,{ root: true })
         commit('CHANGE_ONLINE_PROJECTS',projects);
         }
         commit('CHANGE_INIT_LOADING_PERCENT',100,{ root: true })
      },
};
