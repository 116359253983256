<template>
  <div class="pwa-screen">
     <a-layout>
      <a-layout-header class="layout-header" :class="$mq">
        <Header
          :displayLogoButton="true"
          :displayBackButton="true"
          :displaySearchBar="true"
          :title="company.lib"
        />
      </a-layout-header>
      <a-layout-content>
        <a-modal v-model="progressModal" :closable="false" :footer="null" :centered="true">
          <ProgressTab />
        </a-modal>
        
        <AllEspacesSelection />
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import Header from "./applayout/Header.vue";
import AllEspacesSelection from "./espaceselection/AllEspacesSelection.vue";
import { mapGetters } from "vuex";

export default {
  name: "EspaceSelectionScreen",
  components: {
    Header,
    AllEspacesSelection,
  },
  data() {
    return {
      progressModal: false,
    };
  },
  methods: {
    showProgressModal() {
      this.progressModal = true;
    },
  },
  computed: {
    ...mapGetters({ online: "getOnline" }),
    ...mapGetters("company", { company: "getCompany" }),
  },
};
</script>
