<template>
  <span class="tile">
    <a-card  @click="onSelect" hoverable>
      <div class="tile-img" slot="cover">
        <div class="icon-offline" :style="{'background-color':company.isOffline?'green':'red'}" @click="e=>infoInitialisation(e)" />
        <img v-if="company.icone != undefined" alt :src="company.icone" />
        <img v-else alt src="../../assets/no_image.png" />
      </div>
      <a-card-meta>
        <template slot="title"
          >
          <div :style="{'visibility':loadingCompany?'visible':'hidden'}">
              <a-progress
                style="width: 80%"
                :percent="loadingInitPercent"
                :show-info="false"
              />
          </div>
          <span :style="policeSizeCompany">{{ company.lib }}</span></template
        >
      </a-card-meta>
    </a-card>
  </span>
</template>

<script>
import { getActorConnected } from "~/service/Common/User";
import { mapGetters, mapActions } from "vuex";
import User from "~/service/Common/User";
const Antd = require("ant-design-vue");


export default {
  name: "CompanyTile",
  props: {
    company: {
      required: true,
    },
  },
  data(){
    return {
      loadingCompany: false,
    }
  },
  computed: {
    ...mapGetters({ loadingInitPercent: "getLoadingInitPercent"}),
    policeSizeCompany() {
      let length = this.company.lib.length;

     if (length < 10) return "font-size:16px;";
      else if (length < 20) return "font-size:16px;white-space:normal";
      else if (length < 30) return "font-size:14px; white-space:normal";
      else return "font-size:12px; white-space:normal";
    },
  },

  methods: {
    ...mapActions("company", ["setCompany"]),
    
    ...mapActions("project", ["updateProjects"]),
    ...mapActions("login", ["setConnectedActor", "setConnectedUser"]),
    infoInitialisation(event){
      if(this.company.isOffline)
        this.$notification["success"]({
                message: "Cette société contient des projets disponibles hors-ligne ",
        });
      else
         this.$notification["warning"]({
                message: "Cette société ne contient pas  des projets disponibles hors-ligne ",
        });
        event.stopPropagation()
    },
    
    async onSelect() {
      this.loadingCompany=true;
      console.log("let's go pour voir fois 1")
      await this.setCompany(this.company);
      let curr_actor = await getActorConnected();
      await this.setConnectedActor(curr_actor);
      await this.updateProjects(); 
      setTimeout(() => {
      this.loadingCompany=false;
      this.$router.push({ name: "EspaceSelection" , params:{alreadyLoaded:true}});
      }, 500);
    },
  },
};
</script>