import _ from 'lodash';
export default {
    getProject(state) {
        return state.project;
    },
    getProjectId(state) {
        return state.project.id;
    },
    getOfflineProjects(state) {
       return state.offline_projects;
    },
    getOnlineProjects(state){
        return state.online_projects;
    },
    getAllActors(state) {
        return state.actors;
    },
};
