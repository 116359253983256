<template>
    <div>
        <a-timeline-item color="blue">
            <b>{{ $t('workflowzoom.timeline.visas') }}</b>
            <i>({{ version.workflow_lib }})</i>
            <div v-for="(group, index) in version.visas" :key="index" :style="visaStyle">
                <div
                    v-if="group.a_pas_vise"
                    style="background-color:#D3D3D3;border-radius:3px;padding:5px;padding-left: 5px;"
                >
                    <table style="width:100%;">
                        <div v-for="(value, index) in group.a_pas_vise" :key="index">
                            <tr style="vertical-align:middle;" class="visa-row">
                                <td
                                    v-if="value.icone_url_action"
                                    @click="showModalViser(value)"
                                    style="cursor:pointer;width:200px;border-radius: 3px;padding:5px;text-align:center;background-color:#0E71B4;color:white;"
                                >
                                    <b>{{ $t('workflowzoom.visas.add') }}</b>
                                </td>
                                <td
                                    v-else
                                    class="visa-tile"
                                    :class="$mq"
                                    :style="{'background-color':value.color, 'color': value.textColor}"
                                >{{ value.etat_lib }}</td>
                                <td style="width:200px;text-align:center;">
                                    <b>
                                        {{ value.casquette }}
                                        <span v-if="value.is_optional">
                                            <br />
                                            <i>({{ $t('workflowzoom.visas.optional') }})</i>
                                        </span>
                                    </b>
                                </td>
                                <td style="width:170px;">
                                    <i
                                        v-if="value.comment && !value.extravisa"
                                    >{{ value.comment }} {{ $t('workflowzoom.visas.pending') }}</i>
                                    <i v-if="value.extravisa">{{ value.comment }}</i>
                                </td>
                                <td style="width:150px;text-align:right;">
                                    <p v-if="value.ultimatum" v-html="value.ultimatum"></p>
                                </td>
                                <td style="width:20px;text-align:right;"></td>
                                <td style="width:20px;text-align:right;">
                                    <p v-html="value.icone_url_suppr"></p>
                                </td>
                            </tr>
                        </div>
                    </table>
                </div>
                <div
                    v-if="group.a_vise"
                    style="background-color:#FFFFFF;border-radius:3px;padding:5px;"
                >
                    <table style="width:100%;">
                        <div v-for="(value, index) in group.a_vise" :key="index">
                            <tr class="a-viser-row" style="vertical-align:middle;padding:50px">
                                <td
                                    class="visa-tile"
                                    :class="$mq"
                                    :style="{'background-color':value.color, 'color': value.textColor}"
                                >
                                    {{ value.etat_lib }}
                                    <span
                                        v-if="value.is_late"
                                    >({{ $t('workflowzoom.visas.late') }})</span>
                                    <br />
                                    <i>({{ value.date }})</i>
                                </td>
                                <td style="width:200px;text-align:center;">
                                    {{ value.author_lib }}
                                    <br />
                                    <b v-if="!value.acteur_non_retenu">
                                        {{ value.casquette }}
                                        <span v-if="value.is_optional">
                                            <br />
                                            <i>({{ $t('workflowzoom.visas.optional') }})</i>
                                        </span>
                                    </b>
                                    <b v-else>
                                        {{ $t('workflowzoom.missions.removed', { mission: value.casquette }) }}
                                        <span
                                            v-if="value.is_optional"
                                        >
                                            <br />
                                            <i>({{ $t('workflowzoom.visas.optional') }})</i>
                                        </span>
                                    </b>
                                </td>
                                <td style="width:350px;font-size: 12px !important">
                                    <p v-if="!value.histo_system" v-html="value.comment"></p>
                                    <p v-else>
                                        <i>({{ $t('workflowzoom.visas.system') }})</i>
                                    </p>
                                </td>
                                <!-- <td style="width:20px;text-align:right;">
                                    <p v-if="value.is_repeatable" v-html="value.icone_url_action"></p>
                                </td>
                                <td style="width:20px;text-align:right;">
                                    <p v-if="value.is_repeatable" v-html="value.icone_url_suppr"></p>
                                </td> -->
                            </tr>
                        </div>
                    </table>
                </div>
            </div>
        </a-timeline-item>
        <workflow-zoom-file :version="version" />
        <a-modal class="pwa-modal" v-model="modalVisa" :closable="false" :footer="null" :centered="true" :title="mission.casquette +' - '+actor.name">
            <Visa :mission_id="mission.mission_id" :file_id="file.id" :object_lib="file.lib" @exit="hideModalVisa()"/>
        </a-modal>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapMutations,
} from 'vuex';

export default {
    props: {
        version: {
            type: Object,
            required: true,
        },
        file: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            mission:'',
            modalVisa:false
        }
    },
    components: {
        WorkflowZoomFile: () => import('./WorkflowZoomFile.vue'),
        Visa: () => import('../visa/Visa')
    },
    methods: {
        async updateMission(node_id) {
            this.toggleOverlay(true);
            await this.getZoomData({
                nodeId: node_id,
                update: true,
            });
            this.toggleOverlay(false);
        },
        selectExtra() {
            this.ExtraVisaWin({
                node_id: this.version.id,
                extraviseurs_json: this.version.extraviseurs_json,
            });
        },
        hideModalVisa(){
            this.modalVisa=false;
            this.$emit('reload', true)

        },
        showModalViser(mission) {
            this.mission = mission;
            this.modalVisa=true;
        },
        ...mapMutations([
            'toggleOverlay',
        ]),
        ...mapActions('workflowzoom', [
            'getZoomData',
            'ExtraVisaWin',
        ]),
    },
    computed: {
        ...mapGetters('login',{ actor: "getConnectedActor" }),

        visaStyle() {
            return {
                'border-color': '#E5E5E5',
                'border-radius': '2px',
                'border-style': 'solid',
                'border-width': this.version.border_style,
            };
        },
    }
};
</script>
