import { keys, first, has, assign } from 'lodash';
import moment from 'moment';
import i18next from 'i18next';
import ICU from 'i18next-icu';
import XHR from 'i18next-xhr-backend';

const localeDatas = {
    fr: require('i18next-icu/locale-data/fr'),
    en: require('i18next-icu/locale-data/en'),
    de: require('i18next-icu/locale-data/de'),
};
const languages = keys(localeDatas);
const defaultLanguage = first(languages);

const icu = new ICU();

const importLocaleData = (lng) => {
    if (has(localeDatas, lng)) {
        icu.addLocaleData(localeDatas[lng]);
        moment.locale(lng);
    }
};
importLocaleData(defaultLanguage);

const loadLocales = async (url, options, callback, data) => {
    const modules = [
        'applayout', 
        'companyselection', 
        'connexion',
        'common',
        'espaceselection', 
        'fileselection',
        'filevisualisation',
        'rapportcreation',
        'rapportedit',
        'rapportselection',
        'workflowzoom',
        'viewer',
        'visa'
    ];
    try {
        const values = await Promise.all(
            modules.map(async (module) => {
                const locale = await import(`./${module}/${url}.json`);
                return {
                    [module]: locale,
                };
            }),
        );
        const locale = assign({}, ...values);
        callback(locale, {
            status: '200',
        });
    } catch (error) {
        callback(null, {
            status: '404',
        });
    }
};

i18next.use(icu).use(XHR).init({
    debug: false,
    languages,
    fallbackLng: defaultLanguage,
    backend: {
        loadPath: '{{lng}}',
        parse: (data) => data,
        ajax: loadLocales,
    },
});
i18next.on('languageChanged', importLocaleData);

export default i18next;
