<template>
    <div class="child-section">
        <div class="tableau-section" style="border-bottom: 1px solid #4cb3c1">
            <div :class="$mq">
                <div class="subtitle-section-detail project-information"  :class="$mq">
                {{ $t("rapportedit.sections.purchaser") }}
                    <div class="cell-detail" >
                        <a-input style="width:100%" :disabled="is_locked_report" v-model="informations.purchaser" placeholder="Champ Libre"/>
                    </div>
                </div>
                <div class="subtitle-section-detail project-information"  :class="$mq">
                     {{ $t("rapportedit.sections.mission_type") }}
                     <div class="cell-detail" >
                        <a-input style="width:100%" :disabled="is_locked_report" v-model="informations.mission_type" placeholder="Champ Libre"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import {mapActions,mapGetters} from 'vuex';


export default {
    name: "InfoGeneAeu",
    props: {
        value:{
            type:Object,
            required:true
        }
	},
    data() {
        return {
            valueChange:false,
            informations:{},
        };
    },
    watch:{
        informations:{
            deep:true,
            handler(){
                if(!this.valueChange){
                    this.$emit('input',this.informations);
                    this.$emit('save');
                }
                this.valueChange=false;

            }
        },
        value:{
            immediate:true,
            deep:true,
            handler(){
                this.valueChange=true;
                this.informations={...this.value};
            }
        }
    },
    beforeMount(){
         this.remove_blocked_sign_error(0)
    },
    methods: {
        ...mapActions('rapports',["add_blocked_sign_error","remove_blocked_sign_error"]),
        reset_error(){
            this.remove_blocked_sign_error(0)
        }
    },
    computed: {
        ...mapGetters("rapports", { is_locked_report: "is_locked_report" }),
  }
};
</script>