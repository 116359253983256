<template>
  <div class="pwa-screen">
    <a-layout>
      <a-layout-header class="layout-header" :class="$mq">
        <Header
          :displayLogoButton="true"
          :displayBackButton="true"
          :displaySearchBar="true"
          :displayTeamButton="true"
          :displayOfflineButton="true"
          :displayModuleButton="true"
          :displayStockagePercent="false"
          :title="project.lib"
        />
      </a-layout-header>
      <a-layout-content>
        <RapportEdit :rapport="rapport" />
      </a-layout-content>
    </a-layout>
  </div>
</template>
<script>
import Header from "./applayout/Header";
import RapportEdit from "./rapportedit/RapportEdit";
import Rapport_API from "~/service/Rapport/Rapport";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RapportEditScreen",
  components: {
    Header,
    RapportEdit,
  },
  computed: {
    ...mapGetters("project", { project: "getProject" }),
    ...mapGetters("rapports", { rapport: "get_user_rapport" }),
  },
};
</script>



