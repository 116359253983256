<template>
    <div class="file-selection">
        <div>
            <div class="entete-file-selection">
				<div class="button-zone left-buttons">
					<a-button
						class="new-rapport"
						size="large"
						@click="newRapport()"
					>
						<a-icon type="plus" />{{
							$t("rapportselection.new_report")
						}}
					</a-button>
				</div>
			</div>
        </div>
        <div>
            <a-tabs class="onglets" :activeKey="displayState" @change="change_tab">
				<a-tab-pane key="1">
					<span slot="tab">
						<a-icon type="file" />
						{{ $t("rapportselection.validated_reports") }}
					</span>
				</a-tab-pane>
				<a-tab-pane key="2">
					<span slot="tab">
						<a-icon type="file-done" />
						<span>{{ $t("rapportselection.edit_reports") }}</span>
					</span> 
				</a-tab-pane>
			</a-tabs>
			<RapportsList />
        </div>
    </div>
</template>

<script>
import RapportsList from "./RapportsList";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "AllFilesSelection",
    components: {
        RapportsList,
    },
	methods: {
        ...mapActions("rapports", ["display_reports_by_state"]),
        change_tab(key) {
            this.display_reports_by_state(key);
        },
		newRapport() {
            this.$router.push({ name: "NewRapport" });
        },
    },
    computed: {
        ...mapGetters({
            displayState: "getDisplayState",
        }),
    },
};
</script>

