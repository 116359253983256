export default {
  getTreeData(state, getters, rootState, rootGetters) {
    switch (parseInt(rootState.displayState)) {
      case 1:
        return state.arboStandardToDisplay;
      case 2:
        return state.arboToVisaToDisplay;
      case 3:
        return state.arboLocalToDisplay;
      default:
        return state.arboStandardToDisplay;
    }
  },
  getArboLocalToDisplay(state) {
    return state.arboLocalToDisplay;
  },
  getArboStandardToDisplay(state) {
    return state.arboStandardToDisplay;
  },
  getArboToVisaToDisplay(state) {
    return state.arboToVisaToDisplay;
  },
  getArboActivation(state, getters, rootState, rootGetters) {
    switch (parseInt(rootState.displayState)) {
      case 1:
        return state.arboActivationStandard;
      case 2:
        return state.arboActivationVisa;
      case 3:
        return state.arboActivationLocal;
      default:
        return state.arboActivationStandard;
    }
  },
  getArboActivationStandard(state) {
    return state.arboActivationStandard;
  },
  getArboActivationVisa(state) {
    return state.arboActivationVisa;
  },
  getArboActivationLocal(state) {
    return state.arboActivationVisa;
  },
  getArboOpen(state) {
    return state.arboOpen;
  },
};
