
export default {
  getChildrensDirectories(state, getters, rootState, rootGetters) {
    switch (parseInt(rootState.displayState)) {
      case 1:
        return state.directoriesStandardToDisplay;
      case 2:
        return state.directoriesToVisaToDisplay;
      case 3:
        return state.directoriesLocalToDisplay;
      default:
        return state.directoriesStandardToDisplay;
    }
  },
  getSelectedKeyDirectory(state) {
    return state.selectedKeyDirectory;
  },
  getCurrentPath(state) {
    return state.currentPath;
  },
  getSelectedDirectory(state) {
    return state.selectedDirectory;
  }
};
