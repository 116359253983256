<template>
    <div class="child-section attachment-selector">
        <div class="clearfix">
            <input type="file"  :id="'file-input_'+section_id" :ref="'fileInput_'+section_id" @change="handleFileUpload"/>
            <label v-if="total_file_size < properties.limit_filesize && !is_locked_report" :for="'file-input_'+section_id" class="add-file">
                  <a-icon type="inbox" />
                  Cliquez pour ajouter des fichiers.
            </label>
            <div class="file-container">
              <div v-for="(file,index) in files" :key="index" class="file-description"> 
                <div class="icone-file">
                  <IconeDocRenderer class="detail-file icon"
                    :type="file.format"
                    style="width:80px;display:table-cell"
                    styleImage="width:60px; margin-right:20px;"
                  />
                </div>
                  
                  <div class="legend">
                      ( {{Math.round(file.size/1000000*10)/10}} Mo) {{file.name}} 
                  </div>
                  <div class="delete-button"> 
                      <a-button type="danger" @click="remove(file)"><a-icon type="delete"/></a-button>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import {mapActions,mapGetters} from 'vuex';
import Rapport_db from "~/db_access/Rapport";
import Rapport_API from "~/service/Rapport/Rapport";
import IconeDocRenderer from "../../renderer/IconeDocRenderer";
import { getExtension } from 'mime';




function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}


export default {
    name: "ImageSelector",
    props: {
        properties:{
            type:Object,
            required:false,
            default:()=>{return {}}
        },
        section_id:{
            type:Number,
            required:true,
        },
        element_id:{
            type:Number,
            required:true,
        }
  },
    components:{
      IconeDocRenderer
    },
    data() {
        return {
            files:[],
            total_file_size:0
        };
    },
    beforeMount(){
        Rapport_db.get_section_by_key(this.header_rapport.project_id, this.header_rapport.modele.id,this.header_rapport.rapport_id,this.section_id).then((section)=>{
            let section_element=_.find(section.section_elements,['element_type',"PJ"]);
            if(section_element){
                this.files=section_element.element_values;
            }
        })
    },
    methods: {
        ...mapActions('rapports',["save_element_section"]),
        async handleFileUpload(){
            let input = this.$refs['fileInput_'+this.section_id];
            let file = input.files;
            if (file && file[0]) {
                console.log(getExtension(file[0].type))
                if (this.total_file_size+file[0].size>this.properties.limit_filesize) {
                    this.$notification["error"]({
                            message: "La taile de l'ensemble des fichier dépasse 8 Mo",
                        });
                    return;
                }
                this.files.push({name:file[0].name,format:getExtension(file[0].type).toUpperCase(),size:file[0].size,thumbUrl:await getBase64(file[0])})
                this.total_file_size+=file[0].size
                console.log("upload terminé")
                this.save_element_section({section_id:this.section_id,element_id:this.element_id,element_values:this.files});
            }
        },   
        remove(file) {
            const index = _.findIndex(this.files, ['name', file.name]);
            this.files.splice(index, 1);
            this.save_element_section({section_id:this.section_id,element_id:this.element_id,element_values:this.files});
        },
    },
    computed: {
        ...mapGetters("rapports", { is_locked_report: "is_locked_report",header_rapport: "get_header_rapport" }),
  }
};
</script>