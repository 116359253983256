import { stringify } from 'querystring';
import store from '~/src/store/';
import Offline from '~/src/offline.js';

const service = {
    async post(route, body, callback, company = true) {
        if(!await Offline.test_online()){
            callback(false);
        }
        let oauth = store.state.login.oauth;
        if (!await service.refresh(oauth)) {
            callback(false);
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': oauth.token_type + ' ' + oauth.access_token,
                'Content-Type': 'application/json',
            }
        }

        if (company) options.headers['X-Axx-StId'] = store.state.company.company.id;

        fetch(process.env.VUE_APP_COMMON_SRVURL + route, options)
            .then(res => res.json())
            .then(res => callback(res))
            .catch((err) => {
                console.error(err);
                callback(false); 
            });
    },

    async get(route, query_param, callback, company = true, autoparse = true) {
        if(!await Offline.test_online()){
            callback(false);
        }
        let oauth = store.state.login.oauth;
        if (!await service.refresh(oauth)) {
            callback(false);
        }

        let options = {
            method: 'GET',
            headers: {
                'Authorization': oauth.token_type + ' ' + oauth.access_token,
                'Content-Type': 'application/json',
            }
        }

        if (company) options.headers['X-Axx-StId'] = store.state.company.company.id;

        let final_route = process.env.VUE_APP_COMMON_SRVURL + route

        //if data needs to be passed as query string
        if (query_param) final_route += '?' + stringify(query_param);

        fetch(final_route, options)
            .then((res) => {
                return autoparse ? res.json() : res;
            })
            .then(res => callback(res))
            .catch((err) => {
                console.error(err);
                callback(false);
            });
    },

    async auth(route, body, callback, company = true) {
        if(!await Offline.test_online()){
            callback(false);
        }
        
        let __auth={
            client_id:process.env.VUE_APP_AUTH_CODE_ID,
            client_secret: process.env.VUE_APP_AUTH_CLIENT_ID,
            scope: process.env.VUE_APP_AUTH_SCOPE,
        }
        body = Object.assign({}, __auth, body);

        let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        }

        if (company) options.headers['X-Axx-StId'] = store.state.company.company.id;

        fetch(process.env.VUE_APP_COMMON_SRVURL + route, options)
            .then(res => res.json())
            .then(res => callback(res))
            .catch((err) => {
                console.error(err);
                callback(false);
            });
    },

    async refresh(oauth) {
        return new Promise((resolve) => {
            if (oauth.expires_at > Date.now()) {
                if(oauth.expires_at-Date.now()<30*60*1000){
                    service.auth('oauth/refreshtoken', {
                        'grant_type': 'refresh_token',
                        'refresh_token': oauth.refresh_token
                    }, async (oauth) => {
                        if (oauth) {
                            oauth.expires_at = Date.now() + (oauth.expires_in * 1000);
                            await store.dispatch('login/setOauth', oauth);
                            await store.dispatch('login/setIsLoggedIn', true);
                            resolve(true);
                        } else {
                            window.location.href = process.env.VUE_APP_LOCAL_URL + '#/login';
                            resolve(false);
                        }
                    });
                }
                else{
                    resolve(true)
                }
            } else {
                resolve(false);
            }
        });
    }
}

export default service;

