<template>
<div>
    <div><a-input v-model="filter" placeholder="Recherche de projet" style="width: 100%" :allowClear="true" /></div>
    <div class="container-tiles espace">
        <EspaceTile
            v-for="espace in projects"
            :key="espace.id"
            :espace="espace"
            v-model="espace.starred"
            :selectEspaceAvailable="selectEspaceAvailable"
            :rapportModuleActif="espace.rapportModuleActif"
            :gedModuleActif="espace.gedModuleActif"
            @espace-select-event="blockEspaceSelection"
        />
        <div v-if=!projects.length >
            <h1>{{$t('espaceselection.no_project')}}</h1>
        </div>
    </div>
</div>
</template>
<script>

import EspaceTile from './EspaceTile.vue'
import User from '~/service/Common/User';
import { mapActions, mapGetters } from 'vuex';

export default {
    name:'AllEspacesSelection',
    components: {
        EspaceTile
    },
    data() {
        return {
            selectEspaceAvailable:true,
            filter:''
        }
    },
    async beforeMount() {
        if(!this.$route.params.alreadyLoaded){
            console.log("on recharge les projets")
            await this.updateProjects();
            
        }
        this.changeInitLoadingPercent(0); 
    },
    methods:{
        ...mapActions('project',['updateProjects']),
        ...mapActions(['changeInitLoadingPercent']),
        onSearch(value){
            this.filter=value
        },
        blockEspaceSelection(){
               this.selectEspaceAvailable=!this.selectEspaceAvailable;
        },
    },
    computed:{
        ...mapGetters({online:'getOnline'}),
        ...mapGetters('project',{offlineProjects:'getOfflineProjects',onlineProjects:'getOnlineProjects'}),

        projects(){
            let projects=[];
            if(this.online){
                projects= this.onlineProjects;
            }
            else
            {
                projects= this.offlineProjects;
            }
             if(this.filter!=''){
                    projects=projects.filter(project=> (project.lib && project.lib.toUpperCase().includes(this.filter.toUpperCase())) || (project.isPresents && project.naff && project.naff.toUpperCase().includes(this.filter.toUpperCase())))
                }
            return [...projects.filter(r=> r.starred == true),...projects.filter(r=> !r.starred)];

        }
    }
};
</script>