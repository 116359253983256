export default {
  getFileStandardToDisplay(state) {
    return state.filesStandardToDisplay;
  },
  getFilesToVisa(state) {
    return state.filesToVisaToDisplay;
  },
  getFilesLocal(state) {
    return state.filesLocalToDisplay;
  },
  getDisplayedFiles(state, getters, rootState, rootGetters) {
    switch (parseInt(rootState.displayState)) {
      case 1:
        return state.filesStandardToDisplay;
      case 2:
        return state.filesToVisaToDisplay;
      case 3:
        return state.filesLocalToDisplay;
      default:
        return state.filesStandardToDisplay;
    }
  },
  getPageSize(state) {
    return state.pageSize;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
  getAllFilesToVisa(state) {
    return state.allfilesToVisa;
  },
  getCountFilesToVisa(state) {
    return state.countFilesToVisa;
  },
  getFileToView(state) {
    return state.fileToView;
  },
};
