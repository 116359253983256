<template>
    <div class="container-tiles">
        <CompanyTile
            v-for="company in companies"
            :key="company.id"
            :company="company"
        />
        <div v-if="companies.length === 0">
            <h1>{{$t('companyselection.no_company')}}</h1>
        </div>
    </div>
</template>
<script>
import CompanyTile from "./CompanyTile.vue";
import { getActorConnected } from "~/service/Common/User";
import {mapGetters,mapActions} from 'vuex'

export default {
    name: "AllCompaniesSelection",
    components: {
        CompanyTile
    },
    async beforeMount() {
        if(!this.$route.params.alreadyLoaded){
            await this.updateCompanies(); 
             this.changeInitLoadingPercent(0); 
        }
        if(this.companies.length===1 && this.lastRoute!='EspaceSelection'){
            await this.setCompany(this.companies[0]);
            let curr_actor = await getActorConnected();
            await this.setConnectedActor(curr_actor);
            await this.updateProjects();
            console.log("push next")
            this.$router.push({ name: "EspaceSelection" , params:{alreadyLoaded:true}});
        }
        this.changeInitLoadingPercent(0); 
        
    },
    methods:{
    ...mapActions(["changeInitLoadingPercent"]),           
    ...mapActions("company", ["setCompany","updateCompanies"]),
    ...mapActions("project", ["updateProjects"]),
    ...mapActions("login", ["setConnectedActor", "setConnectedUser"]),
    },
    computed:{
        ...mapGetters({online:'getOnline',lastRoute:'getLastRouteName'}),
        ...mapGetters('company',{offlineCompanies:'getOfflineCompanies',onlineCompanies:'getOnlineCompanies'}),

        companies(){
            if(this.online){
                return this.onlineCompanies;
            }else{
                console.log("offline companies",this.offlineCompanies)
                 return this.offlineCompanies;
            }
        }
    }
};
</script>