export default {
  getIsLoggedIn(state) {
    return state.isLoggedIn
  },
  getOauth(state) {
    return state.oauth;
  },
  getConnectedActor(state) {
    return state.connectedActor;
  },
  getConnectedUser(state) {
    return state.connectedUser;
  },
};
