<template>
<a-timeline-item color="green">
    <b v-if="version.is_first_version">{{ $t('workflowzoom.timeline.upload') }}</b>
    <b v-else>{{ $t('workflowzoom.timeline.update') }}</b>
    <table style="width:100%;padding-top:15px;">
        <tr>
            <td style="width:20px;text-align:right;">
                <i v-if="version.is_first_version">{{ $t('workflowzoom.file.uploaded') }}</i>
                <i v-else>{{ $t('workflowzoom.file.updated') }}</i>
                {{ version.add_by }}
                <span
                    v-if="version.add_by_lib"
                >({{ version.add_by_lib }})</span>
                , {{ version.dt_add }}
            </td>
        </tr>
    </table>
</a-timeline-item>
</template>

<script>
export default {
    props: {
        version: {
            type: Object,
            required: true,
        },
    },
};
</script>
