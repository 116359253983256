<template>
  <div class="mobaxeo-app" :class="!online && $router.currentRoute.name!='Viewer'?'offline':''">
      <router-view />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'App',
  components: {
  },
  watch:{
    online:function(value){
        if(value){
          this.$notification['info']({
            message: 'Mode Hors-ligne désactivé',
            description:
                "La connexion a été retrouvée, vous êtes maintenant en ligne.",
          });
          if(this.$route.meta.online_trigger){
            console.log(this.$route.meta.online_trigger);
              this.$route.meta.online_trigger.forEach(element => {
                if(element.value){
                  console.log(element.action,element.value)
                  this.$store.dispatch(element.action,element.value)
                }
                else{
                  this.$store.dispatch(element.action);
                }
              });
          }
        }
        else{
           this.$notification['warning']({
              message: 'Mode Hors-ligne activé',
              description:
                  'La connexion a été perdue, vous êtes maintenant hors-ligne',
            });
          if(this.$route.meta.offline_trigger){
            console.log(this.$route.meta.offline_trigger);
             this.$route.meta.offline_trigger.forEach(element => {
                if(element.value){
                  console.log(element.action,element.value)
                  this.$store.dispatch(element.action,element.value)
                }
                else
                {
                  this.$store.dispatch(element.action);
                }
              });
          }
        }
      }
  },
  created(){
    if(this.updated){
        this.changeUpdateOk(false)
    }
  },
  methods:{
     ...mapActions(['changeUpdateOk','changeOnline']),
  },
  computed:{
    ...mapGetters({ online: "getOnline" ,updated:"getIsUpdate"}),
  }
}
</script>

