<template>
  <div class="pwa-screen">
     <a-layout>
      <a-layout-header class="layout-header" :class="$mq">
        <Header
          :displayLogoButton="true"
          :displayBackButton="true"
          :displaySearchBar="true"
          :title="title"
        />
      </a-layout-header>
      <a-layout-content>
        <a-modal v-model="progressModal" :closable="false" :footer="null" :centered="true">
          <ProgressTab />
        </a-modal>
        <AllCompaniesSelection />
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import Header from "./applayout/Header";
import AllCompaniesSelection from "./companyselection/AllCompaniesSelection.vue";
import { mapGetters,mapActions } from 'vuex'

export default {
  name: "CompanySelectionScreen",
  components: {
    AllCompaniesSelection,
    Header,
  },
  data() {
    return {
      progressModal: false,
      title: process.env.VUE_APP_CLIENT_TITLE,
    };
  },
  methods: {
    showProgressModal() {
      this.progressModal = true;
    },
  },
  computed: {
    ...mapGetters({ online: "getOnline" }),
  },
};
</script>