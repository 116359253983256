<template>
	<div class="rapport-edit">
		<RapportHeader
			v-if="!sign"
			:header="rapport.header"
			:expand="expand"
			:canExpand="!sign && !send && !preview"
			:canPreview="!sign && !send"
			@expand-or-collapse="expandOrCollapse()"
		/>
		<div style="display:table; width:100vw; table-layout:fixed">
			<SectionSider
				:sections="rapport.sections"
				:expand="expand"
			/>
			<SectionsDisplay
				v-if="!preview && !sign && !send"
				:sections="rapport.sections"
			/>
			<SignatureSider v-else-if="sign"/>
			<VisualisationModal
				v-else-if=" preview || (send && defaultMailContent)"
				:with_send_mail="send"
				:reload="preview || send"
				:rapport="rapport"
				:userMailContent="defaultMailContent"
			/>
			<div 
				v-if="rapport.header.status_id < 3"
				class="action-button first"
				style="z-index:1000"
				@click="expandButton=!expandButton"
			>
				<a-icon style="fontSize: 22px" :type='expandButton?"arrow-down":"arrow-up"'/>
			</div>
			<div v-if="!sign" :class="'action-button slice '+(!signed?'inactive ':'')+(expandButton?'second':'')" @click="openSend">
				<img v-if="!send" src="../../assets/send.png" alt="image_send" />
				<div v-if="!send" class="text">
					{{ $t('rapportedit.buttons.send') }}
				</div>
				<img v-if="send" src="../../assets/edit_white.png" alt="image_edit" />
				<div v-if="send" class="text">
					{{ $t('rapportedit.buttons.back_to_edit') }}					
				</div>
			</div>
			<div
				v-if="!sign && !send"
				:class="'action-button slice  '+(!is_locked_report?'inactive ':'')+(expandButton?'tierce':'')"
				@click="openSignature"
			>
				<img  src="../../assets/sign.png" alt="image_sign" />
				<div class="text">
					{{ $t('rapportedit.buttons.sign_report') }}
				</div>
			</div>
			<div
				v-else-if="sign"
				class="action-button slice second"
				@click="openSignature"
			>
				<img src="../../assets/sign.png" alt="image_sign" />
				<div v-if="!send" class="text">
					{{ $t('rapportedit.buttons.valid_signs') }}
				</div>
			</div>
			<div
				v-if="!sign && !send"
				:class="blocked_sign_error.length > 0 ? 'action-button slice error '+ (expandButton?'fourth':'') : 'action-button slice '+ (expandButton?'fourth' : '')"
				@click="lock_report"
			>
				<img v-if="is_locked_report" src="../../assets/cadenas_white.png" alt="image_cadenas" />
				<img v-else src="../../assets/cadenas_white_unlock.png" alt="image_cadenas_unlock" />
				<div v-if="is_locked_report" class="text">
					{{ $t('rapportedit.buttons.to_edit') }}
				</div>
				<div v-else class="text">
					{{ $t('rapportedit.buttons.stop_edit') }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import RapportHeader from "./rapportlayout/RapportHeader";
import SectionSider from "./rapportlayout/SectionSider";
import SectionsDisplay from "./rapportlayout/SectionsDisplay";
import VisualisationModal from './rapportlayout/VisualisationModal'
import SignatureSider from './rapportlayout/SignatureSider'


import { map } from 'lodash'
import { mapGetters, mapActions } from "vuex";
import User_db from "~/db_access/User";

export default {
	name: "RapportEdit",
	components: {
		RapportHeader,
		SectionSider,
		SectionsDisplay,
		VisualisationModal,
		SignatureSider
	},
	props: {
		rapport: {
			type: Object,
			default: () => {},
		},
	},
	data(){
		return {
			expand:false,
			expandButton:false,
			defaultMailContent: "",
		}
	},
	watch:{
		rapport: function(value) {
			this.initializeContentMail(value);
		}
	},
	created() {
		this.initializeContentMail(this.rapport);
	},
	methods:{
		...mapActions('rapports', ['change_sign_interface', 'change_send_interface', 'switch_locked_report']),
		expandOrCollapse () {
			this.expand = !this.expand;
		},
		async initializeContentMail(rapport) {
			let text = await User_db.get_user_mail_modele_content(rapport.header.modele.id);
			if (!text) {
				text = this.$t(`rapportedit.mailtext.${rapport.header.modele.title}`)
			}

			this.defaultMailContent = text;
		},
		async lock_report(){
			if (this.is_locked_report && this.signed) {
				this.$confirm({
					title: this.$t('rapportedit.check_open_section'),
					content: this.$t('rapportedit.warning_open_section'),
					okText: this.$t('common.confirm.yes'),
					okType: 'danger',
					cancelText: this.$t('common.confirm.no'),
					onOk:()=> {
						this.switch_locked_report()
					},
				});
			} else {
				if (!this.is_locked_report && this.blocked_sign_error.length > 0) {
					let string ="<div> Les erreurs sont les suivantes :<ul>";
					await Promise.all(map(this.blocked_sign_error, (value) => { string += '<li>'+value.text+'</li> '}));
					string+='</ul></div>';					
					this.$notification["error"]({
						message: this.$t('rapportedit.title_lock_sign'),
						description : h => {
						return h(
							'div',{domProps:{innerHTML:string}});
						},
						duration: 0,
					});
				} else {
					this.switch_locked_report();
				}
			}
		},
		async openSignature(){
			if (this.is_locked_report) {
				this.change_sign_interface(!this.sign);
			}
		},
		async openSend(){
			if (this.signed) {
				this.change_send_interface(!this.send);
			}
		}
	},
	computed:{
		...mapGetters('rapports', {
			preview: 'get_open_preview_interface',
			sign: 'get_open_sign_interface',
			send: 'get_open_send_interface',
			is_locked_report: "is_locked_report",
			signed: "is_signed_report",
			blocked_sign_error: "get_blocked_sign_error"
		}),
	}
};
</script>
<style>
	.pre-formatted {
		white-space: normal;
	}
</style>