<template>
    <div style="text-align:center">
        <div v-if="type=='download'">
            <img class="IconTypeRenderer" src="../../assets/download.png" />
        </div>
        <div v-if="type=='offline+'">
            <img class="IconTypeRenderer" src="../../assets/ajoutOffline.png" />
        </div>
        <div v-if="type=='offline-'">
            <img class="IconTypeRenderer" src="../../assets/suppressionOffline.png" />
        </div>
        <div v-if="type=='synchro'">
            <img class="IconTypeRenderer" src="../../assets/synchronisation.png" />
        </div>
    </div>
</template>
<script>
export default {
    name: "TypeIconRenderer",
    props: ["type"],
    methods: {}
};
</script>
<style scoped>
.IconTypeRenderer {
  width: 25px;
}
</style>


