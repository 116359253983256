<template>
    <div class="visualisation" :class="$mq">
        <iframe
            id="iframe"
            title="iframe_report"
            v-if="online || loaded"
            :class="with_send_mail ? 'send' : ''"
            :src="basicFileLink"
        ></iframe>
        <div v-else class="send-offline">
            {{ $t("viewer.error_preview_offline") }}
        </div>
        <div v-if="with_send_mail" class="send-footer">
            <div style="display:table;width:100%;table-layout:fixed">
                <a-input v-model="object_mail" class="object-mail"/>
                <div :class="is_text_empty ? 'send-button' : 'send-button-disabled'" @click="content_is_updated ? activeModalOptionsSending(true) : send_report()">
                    <SendOptionsModal
                        :active="showModalOptionsSending"
                        :modele_id="this.header.modele.id"
                        :content="text_mail"
                        @cancelModal="activeModalOptionsSending(false)"
                        @send="send_report"
                    />
                    {{ $t("common.button.send") }}
                </div>
            </div>
            <Editor :tools="false" v-model="text_mail" class="text-mail"/>
        </div>
        <loading-overlay v-if="overlay" />
    </div>

</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import _ from 'lodash'
import { getType } from 'mime';
import Editor from "../sections/Paragraphes/Editor";
import Rapport_API from "~/service/Rapport/Rapport";
import User_API from "~/service/Common/User";
import Rapport_db from "~/db_access/Rapport.js";

export default {
    name: "VisualisationModal",
    components:{
        Editor,
        LoadingOverlay: () => import(/* webpackChunkName: "loading-overlay" */ '../../utils/LoadingOverlay.vue'),
        SendOptionsModal: () => import(/* webpackChunkName: "loading-overlay" */ './SendOptionsModal.vue'),
    },
    props:{
        rapport:{
            type:Object,
            required:true,
            default:()=>{return {header:{},sections:[]}},
        },
        reload:{
            type:Boolean,
            required:true,
        },
        with_send_mail:{
            type:Boolean,
            required:true,
        },
        userMailContent:{
            type:String,
            required:true,
        }
    },
    data() {
        return {
            basicFileLink:"",
            color:process.env.VUE_APP_COMMON_COLOR,
            success_synchro_rapport:false,
            rapport_id:"",
            loaded:true,
            localTextMail: this.userMailContent,
            localObjectMail: "",
            overlay: false,
            showModalOptionsSending: false
        };
    },
    computed:{
        is_text_empty() {
            var tag = document.createElement('div');
            tag.innerHTML = this.text_mail;
            return _.trim(tag.innerText);
        },
        content_is_updated() {
            return this.text_mail !== this.userMailContent;
        },
        text_mail: {
            get() {
                return this.localTextMail;
            },
            set(value) {
                this.localTextMail = value;
            }
        },
        object_mail: {
            get() {
                return _.trim(this.localObjectMail) != "" ? this.localObjectMail : this.send_infos.project+" - CR "+this.header.modele.description;
            },
            set(value) {
                this.localObjectMail = value;
            }
        },
        ...mapGetters("project",{project_id:"getProjectId"}),
        ...mapGetters("rapports",{send_infos:"get_send_infos",header:"get_header_rapport", is_syncing:"report_is_syncing"}),
        ...mapGetters({ online: "getOnline"}),
    },
    watch:{
        reload:{
            immediate: true,
            handler(){
                if (this.reload && this.online) {
                    this.success_synchro_rapport = false;
                    if (this.rapport.header.rapport_id) {
                        this.update_editing_report_from_local_to_server({with_notification: false}).then((success_synchro_rapport)=>{
                            this.success_synchro_rapport = success_synchro_rapport;
                            setTimeout(() => {
                                Rapport_API.get_previsualisation(this.project_id,this.rapport.header.rapport_id).then((data)=>{
                                    if(data) {
                                        this.loaded=true;
                                        let url=URL.createObjectURL(new Blob([data], { type: getType("PDF") }));
                                        this.basicFileLink = url;
                                        this.basicFileLink =process.env.VUE_APP_LOCAL_URL +"pdf.js/web/viewer.html?file=" +url;
                                    } else {
                                        this.loaded=false;
                                    }
                                });
                            }, 150);
                        });
                    }
                } else {
                    this.loaded=false;
                }
            }
        }
    },
    methods:{
        ...mapActions("rapports", ["update_editing_report_from_local_to_server","delete_editing_report","change_user_rapport_statut"]),
        activeModalOptionsSending(value) {
            this.showModalOptionsSending = value;
        },
        send_delayed(msg) {
            this.$notification['error']({
                message: 'Envoi du raport en différé',
                description: msg,
                duration: 0
            });
            this.change_user_rapport_statut({status_id:3,text_mail:this.text_mail,object_mail:this.object_mail,send_infos:this.send_infos});
            this.$router.push({ name: "Rapport" ,params:{alreadyLoaded:false}});
        },
        send() {
            Rapport_API.send_report(this.project_id,this.rapport.header.rapport_id,this.text_mail,this.object_mail).then(async (result)=>{
                if(result.success){
                    await this.delete_editing_report({project_id:this.rapport.header.project_id, modele_id:this.rapport.header.modele.id,rapport_id:this.rapport.header.rapport_id});
                    await Rapport_db.set_last_report_modeles(this.rapport.header.project_id, result.last_report);
                    this.$notification['success']({
                        message: 'Rapport envoyé',
                        description: "Le rapport a été validé et envoyé avec succès",
                    });
                    this.$router.push({ name: "Rapport" ,params:{alreadyLoaded:false}});
                } else {
                    this.change_user_rapport_statut({status_id:4,text_mail:this.text_mail,object_mail:this.object_mail,send_infos:this.send_infos});
                    this.$notification['error']({
                        message: 'Envoi du raport echoué',
                        description: "Une erreur est survenue pendant la validation et l'envoi du rapport",
                    });
                    this.$router.push({ name: "Rapport" , params:{alreadyLoaded:true}});
                }
            }).finally(() => {
                this.overlay = false;
            });
        },
        async send_report() {
            this.showModalOptionsSending = false;
            const report_sync_state = this.is_syncing(this.project_id,this.rapport.header.rapport_id);
            const result = await User_API.can_mailing();
            if (this.online && !report_sync_state) {
                this.overlay = true;
                if (result.can_mailing) {
                    this.send();
                } else {
                    this.send_delayed("Votre compte email est mal paramétré et ne permet pas la validation de ce CR. Celui-ci sera envoyé une fois le problème corrigé. Merci de vous rapprocher du service informatique.");
                }
            } else if (!this.online || report_sync_state) {
                this.send_delayed("Les conditions ne permettent pas un envoi en direct, celui ci sera envoyé en différé.");
            }
        }
    }
}
</script>


<style>
#TeamModal {
    text-align: center;
    width: content;
}
#TeamTable .ant-pagination-item-link {
    display: flex;
}
#TeamTable .anticon {
    display: flex;
    margin: auto;
}
</style>
