import store from '~/src/store/';
import service from '~/service/Common/service.js';

export default {
    async login(username, password) {
        return new Promise((resolve) => {
            service.auth('oauth/authenticate', {
                'grant_type': 'password',
                username,
                password,
            }, async (oauth) => {
                if (oauth) {
                    oauth.expires_at = Date.now() + (oauth.expires_in * 1000);
                    await store.dispatch('login/setOauth', oauth);
                    await store.dispatch('login/setIsLoggedIn', true);
                }
                resolve(oauth)
            });
        });
    },

    async logout() {
        store.dispatch('login/setOauth', {});
        store.dispatch('login/setIsLoggedIn', false);
    },
}
