<template>
    <div
        id="paragraphEditor"
        :class="
            tools && $mq != 'mobile'
                ? 'paragrapheEditorTools'
                : 'paragrapheEditor'
        "
    >
        <div ref="editor" id="quill_editor"></div>
        <div v-if="this.limit" id="counter">
            {{ $t("rapportedit.sections.size_limit_indicator", {nb_chars: this.nbCharacters, limit: this.limit}) }}
        </div>
    </div>
</template>
<script>
import Quill from "quill";

var toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }],
    ["clean"],
];
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        tools: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mustUpdate: {
            type: Boolean,
            default: false,
        },
		limit: {
            type: [Number, Boolean],
            default: false,
		}
    },
    data() {
        return {
            editor: null,
            edited: false,
            nbCharacters: 0,
            config: {
                modules: {
                    toolbar: toolbarOptions,
                    clipboard: {
                        matchers: [
                            [
                                Node.ELEMENT_NODE,
                                function (node, delta) {
                                    var Delta = Quill.import("delta");
                                    return delta.compose(
                                        new Delta().retain(delta.length(), {
                                            color: false,
                                            background: false,
                                            bold: false,
                                            strike: false,
                                            underline: false,
                                        })
                                    );
                                },
                            ],
                        ],
                    },
                },
                theme: "snow",
                formats: [
                    "bold",
                    "underline",
                    "strike",
                    "header",
                    "italic",
                    "size",
                    "color",
                    "background",
                    "align",
                    "clean",
                ],
                strict: true,
            },
        };
    },
    watch: {
        mustUpdate: function (val) {
            this.editor.enable(!this.disabled);
            this.editor.root.innerHTML = this.value;
        },
    },
    mounted() {
        this.editor = new Quill(this.$refs.editor, this.config);
        this.nbCharacters = this.editor.getLength() - 1;
        this.editor.enable(!this.disabled);
        this.editor.root.innerHTML = this.value;
        this.editor.on("text-change", (delta, oldContents) => this.update(delta, oldContents));
        this.editor.editor.scroll.domNode.addEventListener("blur", () => {
            this.save();
        });
    },
    methods: {
        update(delta, oldContents) {
            if (this.limit && this.editor.getLength() - 1 > this.limit) {
                this.$message.error({
                    key: "alert_size_reached",
                    content: this.$t("rapportedit.errors.size_limit_reached_content", {limit: this.limit}),
                    duration: 5,
                });
                this.editor.setContents(oldContents);
            } else {
                this.nbCharacters = this.editor.getLength() - 1;
                this.$emit(
                    "input",
                    this.editor.getLength() > 1 ? this.editor.root.innerHTML : ""
                );
                this.edited = true;
            }
        },
        save() {
            if (this.edited) {
				this.$emit("save");
			}
            this.edited = false;
        },
    },
};
</script>
<style scss-scoped>
    .paragrapheEditor {
        /* min-height:150px; */
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .paragrapheEditorTools {
        /* min-height: 10vh; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        align-items: stretch;
    }

    .paragrapheEditor .ql-toolbar {
        display: none;
    }

    .paragrapheEditor .ql-container.ql-snow {
        min-height: 15vh;
        max-height: 55vh;
    }
    #quill_editor .ql-editor {
        min-height: 15vh;
        max-height: 55vh;
    }

    .paragrapheEditorTools .ql-container.ql-snow {
        min-height: 15vh;
        max-height: 55vh;
    }

    #counter {
        border: 1px solid #ccc;
        border-width: 0px 1px 1px 1px;
        color: #aaa;
        padding: 5px 15px;
        text-align: right;
    }
</style>