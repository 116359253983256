<template>
    <div class="pwa-viewer" :class="$mq">
        <div class="basic-viewer" :class="$mq">
            <div class="loader" v-show="loadingVisualisation">
                <a-progress
                    type="circle"
                    :percent="loadingVisualisationPercent"
                />
                <div>{{ $t("viewer.loading") }}</div>
            </div>
            <iframe
				title="iframeCR"
                class="iframe"
                id="iframe"
                v-show="!loadingVisualisation"
                :src="basicFileLink"
                :class="$mq"
            ></iframe>
        </div>
    </div>
</template>

<script>
import {
	mapGetters,
	mapActions
} from "vuex";
import File_API from "~/service/Ged/File";
import File_db from "~/db_access/File";
import { getSizeFile } from "~/src/common";
import Offline from "~/src/offline.js";

export default {
    name: "app",
    components: {},
    props: ["file"],
    data: () => {
        return {
            basicViewer: false,
            basicFileLink: "",
            fileSizeToVisu: 0,
            loadingVisualisation: false,
            fileDb: {},
            fileData: "",
        };
    },
    async mounted() {
        await this.setSizeDownloaded(0);
        this.basicViewer = false;
        this.loadingVisualisation = true;
		this.basicViewer = true;
		this.fileDb = await File_db.get_file(this.file, true, true);
		this.fileData = (this.fileDb !== undefined) ? this.fileDb.data : undefined;
		this.fileSizeToVisu = getSizeFile(this.file);
		await this.setSizeDownloaded(this.fileSizeToVisu / 4);
		if (await Offline.test_online()) {
			if (this.fileData === undefined) {
				this.changeViewableFileId(this.file.id);
				this.fileData = await File_API.dl(
					this.file,
					false,
					this.activeModule == "GED"
				);
			}
		}
		this.setSizeDownloaded(this.fileSizeToVisu);
		setTimeout(() => {
			this.initializeViewer(this.fileData);
		}, 500);
    },
    computed: {
        ...mapGetters("operations", ["getSizeDownloaded"]),
        ...mapGetters({
            online: "getOnline",
            activeModule: "getActiveModule",
        }),

        loadingVisualisationPercent() {
            return Math.round(
                (this.getSizeDownloaded * 100) / this.fileSizeToVisu
            );
        },
    },
    methods: {
        ...mapActions("operations", ["setSizeDownloaded"]),
        ...mapActions(["changeViewableFileId"]),
        async initializeViewer(url) {
            if (
                this.file.format.toUpperCase() == "PDF" ||
                this.file.converted
            ) {
                var pdfjsframe = document.getElementById("iframe");
                pdfjsframe.onload = () => {
                    pdfjsframe.contentWindow.PDFViewerApplication.open(url);
                    this.loadingVisualisation = false;
                    this.changeViewableFileId(false);
                };
                pdfjsframe.src = "pdf.js/web/viewer.html";
            }
        },
    },
};
</script>