<template>
    <a-modal
        v-model="display_force_modal"
        class="force-sync-modal"
        :closable="false"
        :footer="null"
        :centered="true"
        width="90%"
        @cancel="reset_modal()"
        >
        <div class="visualisation" :class="$mq">
            <a-result status="warning"
                v-if="!show_next"
                :title="$t('rapportcreation.sync_modal.title')"
                :sub-title="$t('rapportcreation.sync_modal.desc')">
                <template #extra>
                    <a-button key="console" size="large" type="primary" @click="preview_server_report()">{{ $t('rapportcreation.sync_modal.btn') }}</a-button>
                </template>
            </a-result>
            <div v-if="show_next">
                <iframe
                    id="iframe"
                    title="iframe_report"
                    style="width:100%; height: 80%;"
                    v-if="online || loaded"
                    :src="basicFileLink"
                ></iframe>
                <div class="pdf-preview-footer">
                    <a-button size="large" @click="minimize_modal" style="margin-right: auto"><a-icon style="fontSize: 22px" type="arrow-left"/></a-button>
                    <a-button size="large" @click="force_server_sync" class='btn-warning'>{{ $t('rapportcreation.sync_modal.btn_keep_server') }}</a-button>
                    <a-button size="large" @click="force_local_sync" type="danger">{{ $t('rapportcreation.sync_modal.btn_keep_local') }}</a-button>
                </div>
            </div>
        </div>
    </a-modal>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getType } from 'mime';
import Rapport_API from "~/service/Rapport/Rapport";

export default {
    name: "PdfPreviewModal",
    data() {
        return {
            basicFileLink:"",
            loaded:true,
            show_next: false,
        };
    },
    props:{
        rapport_id:{
            required:true,
        },
    },
    computed:{
        ...mapGetters("project",{project_id:"getProjectId"}),
        ...mapGetters({ online: "getOnline"}),
        ...mapGetters("rapports", {force_sync_modal: "get_force_sync_modal"}),
        display_force_modal:{
            get(){
                return this.force_sync_modal;
            },
            set(value){
                this.CHANGE_FORCE_SYNC_MODAL({new_val: value});
            },
        },
    },
    methods: {
        ...mapActions("rapports", ["update_editing_report_from_local_to_server"]),
        ...mapMutations("rapports", ['CHANGE_FORCE_SYNC_MODAL']),
        preview_server_report() {
            Rapport_API.get_previsualisation(this.project_id,this.rapport_id).then((data)=>{
                if(data) {
                    this.loaded=true;
                    let url=URL.createObjectURL(new Blob([data], { type: getType("PDF") }));
                    this.basicFileLink = url;
                    this.basicFileLink =process.env.VUE_APP_LOCAL_URL +"pdf.js/web/viewer.html?file=" +url;
                } else {
                    this.loaded=false;
                }
                this.show_next = true;
            });
        },
        reset_modal() {
            this.show_next = false;
            this.display_force_modal = false;
        },
        force_local_sync() {
            this.update_editing_report_from_local_to_server({with_notification: true, force: true})
            this.reset_modal();
        },
        force_server_sync() {
            //TODO
            this.reset_modal();
        },
        minimize_modal() {
            this.$emit('display-maximize-modal')
            this.display_force_modal = false;
        },
    },
}
</script>


<style>

.pdf-preview-footer {
    margin-top: 24px;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
}

.btn-warning {
    color: white;
    background-color: #FFA600;
}
</style>
