
import store from '~/src/store/';
// import router from '~/src/router';
import {notification} from 'ant-design-vue';
import Ping from 'ping.js';


const offline = {

    test_online(on_login = false){
        if (store.getters.getForceOffline === true) {
            store.dispatch('changeOnline', false);
            return false;
        }
        return new Promise((resolve) => {
        let p = new Ping({favicon :'images/icons/ping.png'});
        p.ping(process.env.VUE_APP_LOCAL_URL, (err, data) =>{
            if (err) {
                if(store.getters.getOnline){
                    store.dispatch('changeOnline',false);
                }
                // console.log("test_online",data,err,false)
                resolve(false);
            }
            else{
                if(!store.getters.getOnline){
                    if ((store.getters["login/getOauth"] && store.getters["login/getOauth"].expires_at > Date.now()) || on_login) {
                        store.dispatch('changeOnline',true);
                        store.dispatch('changeMustReconnect', false);
                    } else if (!store.getters.getMustReconnect) {
                        store.dispatch('changeMustReconnect',true);
                    }
                } else {
                    if (store.getters["login/getOauth"] && store.getters["login/getOauth"].expires_at < Date.now() && store.getters["login/getIsLoggedIn"]) {
                        window.location.href = process.env.VUE_APP_LOCAL_URL + '#/login';
                    }
                }
                resolve(true);
            }
         
        });
        });
    },
}

export default offline;

