<template>
<div class="text-area">
    <div v-if="!fromLastReport">
        <div class="child-section" v-for="(value,index) in text" :key="index">
            <div class="tableau-section">
                <div> 
                    <div class="subtitle-section-detail description">{{ $t("rapportedit.sections.description") }}</div>
                </div>
                <div>
                    <div class="simple-editor">
						<Editor
							:tools="!is_locked_report"
							:disabled="is_locked_report"
							:mustUpdate="is_locked_report"
							:limit="limit"
							v-model="value.description"
							@save="save_section"
						/>
					</div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="child-section" v-for="(value,index) in textValueLastReport" :key="index">
            <div class="tableau-section">
                <div> 
                    <div  style="width:40px; background:#cccccc"></div>
                    <div class="subtitle-section-detail description">{{ $t("rapportedit.sections.description") }}</div>
                </div>
                <div> 
                    <div style="width:40px; text-align:center;vertical-align:middle; background:#cccccc">
						<a-checkbox @change="select_for_recup($event,value,index)"/>
					</div>
                    <div class="simple-editor">
                        <Editor
							:tools="false"
							v-model="value.description"
							:limit="limit"
						/>
                    </div>
                </div>
            </div>
        </div>
        <a-popconfirm placement="left" ok-text="Oui" cancel-text="Non" @confirm="confirm_recup()">
            <template slot="title">
                <div>{{ $t("rapportedit.sections_last.warning") }}</div>
                <div>{{ $t("rapportedit.sections_last.confirm") }}</div>
            </template>
            <div v-if="lastReportValues.length>0" class="button-recuperation">{{ $t("rapportedit.sections_last.btn_confirm") }}</div>
        </a-popconfirm>
    </div>
</div>
</template>
<script>
import Editor from './Editor';
import { mapGetters } from 'vuex';

import _ from 'lodash';


export default {
	name: "TextAreaMC",
	components:{
		Editor
	},
	props:{
		value:{
			type : Array,
			required:true
		},
		textValueLastReport:{
			type : Array,
			required:true
		},
		fromLastReport:{
            type:Boolean,
            required:false,
            default:false
        },
        properties:{
            type:Object,
            required:false,
            default:()=>{return {}}
        },
	},
	data(){
		return{
			lastReportValues:[],
		}
	},
	watch:{
		fromLastReport:function(val){
			this.lastReportValues=[];
		}
	},
	methods:{
		save_section() {
			this.$emit("save", true);
		},
		confirm_recup(){
			this.text=_.map(JSON.parse(JSON.stringify(this.lastReportValues)),(lastReportValue)=>{return lastReportValue.value});
			this.$emit('valide-recup');
		},
		select_for_recup(e,value,index){
			if (e.target.checked) {
				this.lastReportValues.push({id:index,value:value});
			} else {
				this.lastReportValues.splice(_.findIndex(this.lastReportValues,["id",index]),1)
			}
		}
	},
	computed: {
		...mapGetters("rapports", { is_locked_report: "is_locked_report" }),
		text:{
			get(){
				return this.value;
			},
			set(value){
				this.$emit('input',value)
				this.$emit("save", true);
			}
		},
		limit() {
			const textsizeLimit = this.properties?.limit ?? false;
			if (textsizeLimit) {
				const size = Number(textsizeLimit);

				return size && size > 0 ? Number(textsizeLimit) : false;
			}

			return false;
		}
	}
}
</script>