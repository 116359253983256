import _ from 'lodash';

export default {
    getDisplayState(state) {
        return state.displayState;
    },
    getOnline(state) {
        return state.online;
    },
    getLoaders(state){
        return state.loaders;
    },
    getGlobalLoadingPercent(state){
        let percentGlobal=0;
        _.map(state.loaders,(loader)=>{percentGlobal+=Math.round(loader.loadingPercent/100*loader.value)})
        return percentGlobal;
    },
    getActiveModule(state){
        return state.activeModule
    },
    getLoadingInitPercent(state){
        return state.loadingInitPercent;
    },
    getUpdateAvailable(state){
        return state.update.type!="";
    },
    getUpdate(state){
        return state.update;
    },
    getViewableFileId(state){
        return state.viewableFileId;
    },
    getInterruptCurrentLoading(state) {
        return state.interruptCurrentLoading;
    },
    getStockagePercent(state){
        return state.stockagePercent;
    },

    getIsUpdate(state){
        return state.updateOk;
    },
    
    getLastRouteName(state){
        return state.lastRouteName
    },

    getMustReconnect(state){
        return state.mustReconnect
    },
    getForceOffline(state){
        return state.forceOffline;
    },

    getValideCode: (state) => (code) => {
        let suite = false;
        let same = true;
        let object={codeNotAvailable:false,errorTextCodeInvalide:""};
        if (code.includes("")) {
            return object
        }

        for (i = 1; i < 4; i++) {
            if (parseInt(code[i]) != parseInt(code[0])) {
                same = false;
                break;
            }
        }
        if (same) {
            object.errorTextCodeInvalide ="Le code doit avoir des chiffre différents.";
            object.codeNotAvailable = true;
            return object;
        }

        if (parseInt(code[0]) == parseInt(code[1]) - 1) {
            suite = true;
            for (var i = 1; i < 3; i++) {
                if (
                    parseInt(code[i]) !=
                    parseInt(code[i + 1]) - 1
                ) {
                    suite = false;
                    break;
                }
            }
        }

        if (parseInt(code[0]) == parseInt(code[1]) + 1) {
            suite = true;
            for (i = 1; i < 3; i++) {
                if (
                    parseInt(code[i]) !=
                    parseInt(code[i + 1]) + 1
                ) {
                    suite = false;
                    break;
                }
            }
        }

        if (suite) {
            object.errorTextCodeInvalide ="Le code ne doit pas être une suite de chiffre";
            object.codeNotAvailable = true;
        }
        return object;
    },
    getValideEmail:(state)=>(email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
};
