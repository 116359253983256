import store from "~/src/store/";
const Offline = require("~src/offline.js");

setupInterval(
  () => {
    Offline.test_online();
  },
  5000,
  "ping"
);

setupInterval(
  async () => {
    console.log("Lancement de la synchronisation");
    if (await Offline.test_online()) {
      store.dispatch("updateStockagePercent");
      store.dispatch("files/updateLocalFile");
      store.dispatch("rapports/update_offline_reports");
      if (store.getters["login/getIsLoggedIn"]) {
        store.dispatch("rapports/update_editing_reports_from_local_to_server");
        store.dispatch("rapports/send_reports");
      }
      await store.dispatch("rapports/set_frozen_reports");
      store.dispatch("rapports/set_projects_in_progress");
      store.dispatch("rapports/display_frozen_rapport_notif");
    }
    console.log("Fin de la synchronisation");
  },
  30000,
  "synchro"
);

function setupInterval(callback, interval, name) {
  var key = "_timeInMs_" + (name || "");
  var now = Date.now();
  var timeInMs = localStorage.getItem(key);
  var executeCallback = function() {
    localStorage.setItem(key, Date.now());
    callback();
  };
  if (timeInMs) {
    // User has visited
    var time = parseInt(timeInMs);
    var delta = now - time;
    if (delta > interval) {
      // User has been away longer than interval
      setInterval(executeCallback, interval);
    } else {
      // Execute callback when we reach the next interval
      setTimeout(function() {
        setInterval(executeCallback, interval);
        executeCallback();
      }, interval - delta);
    }
  } else {
    setInterval(executeCallback, interval);
  }
  localStorage.setItem(key, now);
  localStorage.setItem("sync_interval", true);
  return true;
}
