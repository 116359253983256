<template>
    <div class="number-pad">
      <div style="width:250px; margin: auto; color:white;">{{entete}}</div>  
      <div style="display: inline-flex; text-align: center">
        <div
          v-for="n in 4"
          :id="'input_' + n"
          class="input-selector"
          :key="n+10"
          @click="deleteNumber(n)"
        >{{code[n-1]}}</div>
      </div>
      <div v-if="isError" style="width:250px; margin: auto; color:white; color:red;">{{errorText}}</div>  
      <div class="number-selectors">
        <div
          v-for="n in 10"
          :key="n+100"
          :id="'select_' + n-1"
          class="number-selector"
          @click="chooseNumber(n-1)"
        >
          {{ n-1 }}
        </div>
      </div>
      <a-button v-if="textButton"
        class="button-validation"
        :disabled="validable || isError"
        @click="exit"
        >{{textButton}}</a-button
      >
      <div class="bottom-text">{{textBottom}}</div>
    </div>
</template>
<script>
export default {
    name: "NumberPad",
    props: {
        entete: {
            type: String,
            required: false,
        },
        value:{
            type:Array,
            required:true,
        },
        textButton: {
            type: String,
            required: false,
        },
        textBottom:{
            type: String,
            required: false,
        },
        errorText: {
            type: String,
            required: false,
        },
        isError: {
            required: false,
            default:false
        },
    },
  methods:{
    deleteNumber(n) {
      this.code.splice(n-1,1,"");
      this.$emit('input',this.code);
    },
    chooseNumber(n) {
      for (var i = 0; i < this.code.length; i++) {
        if (this.code[i] === "") {
          this.code.splice(i,1,n);
          this.$emit('input',this.code);
          return;
        }
      }
    },
    exit(){
        this.$emit('get-code', this.code);
    }
  },
  computed:{
      code(){
          return this.value;
      },

      validable(){
        console.log(this.code.includes(""))
          return this.code.includes('');
      }
  }
    
}
</script>
