import store from '~/src/store/';
import _ from 'lodash'
import { get,post } from '~/service/Ged/service.js';

const Project_API = {
    async get_arbo() {
        let project_id = store.state.project.project.id;
        return new Promise(function (resolve) {
            get('projects/' + project_id + '/get_arbo', null, function (arbo) {
                resolve([JSON.parse(JSON.stringify(arbo))]);
            });
        });
    },

async  get_actors() {
    let project_id = store.state.project.project.id;
       return new Promise(function (resolve) {
        get('projects/' + project_id + '/all_actors', null, function (actors) {
            resolve(actors);
        });
    });
},

async  get_obj_detail(obj_id,company_id) {
    return new Promise(function (resolve) {
        get('projects/obj/' + obj_id, null, function (detail) {
            resolve(detail);
        },true,company_id);
    });
},

async  get_objs_details(obj_ids) {
    return new Promise(function (resolve) {
        console.log({"obj_ids":obj_ids})
        post('projects/objs', {'obj_ids':obj_ids}, function (details) {
            resolve(details);
        });
    });
},

}

export default Project_API;