<template>
    <div v-if="!active" class="FromLastReportButton" style="width:150px" @click="$emit('click')">
        {{ $t("rapportedit.sections_last.from") }}
    </div>
    <div v-else class="FromLastReportButton" style="width:100px" @click="$emit('click')">
        {{ $t("rapportedit.sections_last.back") }}
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "FromLastReportButton",
    props:{
        active:{
            type:Boolean,
            required:false,
            default:false,
        }
    },

};
</script>

<style>
.FromLastReportButton {
    background:#0093a7;
    color:white;
    border-radius:5px;
    font-size:15px;
    text-align:center;
    line-height:20px;
    padding:3px;
    cursor:pointer;
    margin:2px;
}

</style>