<template>
  <div class="path-renderer" v-if="arbo">
    <span v-if="path.length>0 && isNotEspace(path[0].id)">.../</span>
    <span v-for="n in path.length" :key="n">
      <a-button
        class="path-access-button"
        type="link"
        @click="selectDirectory(path[n-1].id)"
      >{{path[n-1].name}}</a-button>/
    </span>
    <span>{{displayDirectoryName}}</span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PathRenderer",
  props: ["arbo"],
  data() {
    return {
      limitPath: 1,
    };
  },
  methods: {
    ...mapActions(["changeDisplayState", "updateFilesAndDirectoryToDisplay","createLoader"]),

    async selectDirectory(key) {
     this.createLoader([20,80]).then(()=>{ this.updateFilesAndDirectoryToDisplay({
        selectedKeyDirectory: key,
      });
      });
    },
    isNotEspace(id) {
      if (id.toString().charAt(0) !== "8") return true;
      else return false;
    },
  },
  computed: {
    ...mapGetters("directories", {
      path: "getCurrentPath",
      directory: "getSelectedDirectory",
    }),
    displayDirectoryName() {
      if (this.directory != null) {
        return this.directory.title;
      } else return null;
    },
  },
};
</script>



