<template>
  <div style="width: 100px">
    <a-progress :percent="percent.percent" />
  </div>
</template>
<script>
export default {
    name: "CompletionRenderer",
    props: {
        percent:{},
    },
};
</script>