export default {
    filesStandardToDisplay:{total_documents:0,documents:[]},
    filesToVisaToDisplay: {total_documents:0,documents:[]},
    filesLocalToDisplay: {total_documents:0,documents:[]},
    allfilesToVisa: '',
    pageSize: 15,
    currentPage: 1,
    countFilesToVisa: 0,
    fileToView:"",
    isLoading: false,
    loadingPercent:""
};