<template>
    <div>
        <!-- <div v-if="version.action || version.is_pending" style="padding:10px;text-align:center;">
            <span v-if="version.is_pending" v-html="version.pending_input"></span>
            <a-button
                v-if="version.is_pending"
                type="primary"
                :onclick="version.pending_action"
            >{{ $t('workflowzoom.sequential.upload') }}</a-button>
            <a-button
                v-if="version.action"
                type="primary"
                :onclick="version.action"
            >{{ $t('workflowzoom.sequential.act') }}</a-button>
        </div> -->
        <a-timeline-item color="blue">
            <b>{{ $t('workflowzoom.timeline.actions') }}</b>
            <i>({{ version.workflow_lib }})</i>
            <div style="background-color:#FFFFFF;border-radius:3px;padding:10px;">
                <table style="width:100%;">
                    <div v-for="(histo, name, index) in version.histos" :key="index">
                        <tr style="vertical-align:middle;">
                            <td
                                style="width:200px;border-radius: 3px;padding:5px;text-align:center;"
                                :style="{'background-color': histo.color, 'color': histo.textColor}"
                            >
                                <b>{{ histo.status_name }}</b>
                                <span v-if="histo.assigned_actors">
                                    <br />
                                    {{ $t('workflowzoom.sequential.assigned') }} {{ histo.assigned_actors }}
                                </span>
                                <br />
                                <i>({{ histo.date }})</i>
                            </td>
                            <td style="width:150px;text-align:center;">{{ histo.actor_name }}</td>
                            <td>
                                <p v-html="histo.comment"></p>
                            </td>
                            <!-- <td>
                                <p v-html="histo.attachments"></p>
                            </td> -->
                            <td style="width:150px;text-align:right;">
                                <p v-if="histo.ultimatum" v-html="histo.ultimatum"></p>
                            </td>
                            <td style="width:20px;text-align:right;"></td>
                            <td style="width:20px;text-align:right;"></td>
                        </tr>
                    </div>
                </table>
            </div>
        </a-timeline-item>
        <workflow-zoom-file :version="version" />
    </div>
</template>

<script>
import WorkflowZoomFile from "./WorkflowZoomFile";

export default {
    props: {
        version: {
            type: Object,
            required: true
        }
    },
    components: {
        WorkflowZoomFile
    }
};
</script>
