<template><div>
    <div class="progress-modal" v-if="progressActive.length>0 || progressComplete.length>0">
        <!-- {{$t('fileselection.operation.ongoing')}} -->
        <div v-if="progressActive.length>0">    
            <a-table :columns="columnProgressActive" 
                :data-source="progressActive" 
                :showHeader="false" 
                :pagination="false"
                rowKey="id">
                <span slot="type" slot-scope="type">
                    <TypeRenderer :type="type"/>
                </span>
                <span slot="percent" slot-scope="percent">
                    <a-progress :percent="percent" />
                </span>
            </a-table>
        </div>
        <div v-else style="text-align:center; height:70px; line-height:70px">{{$t('fileselection.operation.none_ongoing')}}</div>
        <!-- <span v-if="progressComplete.length=5">{{$t('fileselection.operation.last_completed')}}</span>
        <span v-else>{{$t('fileselection.operation.completed')}}</span>
        <div v-if="progressComplete.length>0">    
            <a-table :columns="columnProgressComplete" 
                :data-source="progressComplete" 
                :pagination="false"
                rowKey="id">
                <span slot="type" slot-scope="type">
                    <TypeRenderer :type="type"/>
                </span>
                <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
                    {{$t('fileselection.operation.ended_on')}} {{ record.date }}
                </p>
            </a-table>
        </div>
        <div v-else style="text-align:center; height:70px; line-height:70px">Aucune opération terminées</div> -->
    </div>
    <!-- <div v-else style="text-align:center;">{{$t('fileselection.operation.none')}}r</div> -->
    </div>
</template>
<script>
import TypeRenderer from "../renderer/TypeRenderer";
import CompletionRenderer from "../renderer/CompletionRenderer";
import { mapGetters,mapActions } from 'vuex'

const columnProgressActive = [
     {
        title: "projet",
        dataIndex: "projectName",
        key: "projectName",
    },
    {
        title: " ",
        dataIndex: "type",
        key: "type",
        scopedSlots: { customRender: "type" },
        width: 1
    },
    {
        title: "Libellé",
        dataIndex: "name",
        key: "name",
        width: 10000
    },
    {
        title: "percent",
        key: "percent",
        dataIndex: "percent",
        scopedSlots: { customRender: "percent" }
    }
];

const columnProgressComplete =     [
     {
        title: "Projet",
        dataIndex: "projectName",
        key: "projectName",
        width:1000
    },
     {
        title: "Fichier",
        dataIndex: "name",
        key: "name",

    },
    {
        title: "Opération",
        dataIndex: "type",
        key: "type",
        scopedSlots: { customRender: "type" },
    },
   
];

export default {
    name: "ProgressModal",
    components:{
        TypeRenderer,
        CompletionRenderer,
    },
    data() {
        return {
            columnProgressActive,
            columnProgressComplete,
            limitPath: 3
        };
    },
    computed:{
          ...mapGetters("operations", {
       progressActive: "getActiveDownloads" ,
       progressComplete: "getCompleteDownloads" 
    }),
    }
};
</script>
