import store from '~/src/store/';
import { database } from '~/src/db';
import _ from 'lodash'


export default {
    async get_user_mail_modele_content(modele_id) {
        let db = await database.db;
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("userMailsContent", "readwrite");
            let request = transaction.objectStore('userMailsContent').get([user_id, modele_id] );
            transaction.oncomplete = function (event) {
                //console.log("get_user_mail_modele_content : transaction terminated");
            }
            request.onsuccess = function (event) {
                const result = request.result;
                if (result) {
                    resolve(result.content);
                }

                resolve(false);
            }
            request.onerror = function (event) {
                resolve(undefined);
            }
        });
    },
    async set_user_mail_content(modele_id, content) {
        let db = await database.db;
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("userMailsContent", "readwrite");
            let user_mail_content_store = transaction.objectStore("userMailsContent");
            transaction.oncomplete = function (event) {
                resolve();
            };
            transaction.onerror = function (event) {
                // console.log('set_user_mail_content : project already favorites');
            };
    
            let request = user_mail_content_store.put({
                'user_id': user_id,
                'modele_id': modele_id,
                'content': content,
            });
            request.onsuccess = function (event) {
                // console.log('set_user_mail_content: request successful');
            };
            request.onerror = function (event) {
                // console.log('set_user_mail_content: request error');
    
            };
        });
    },
    async is_favorite_project(project_id) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("favoriteProject", "readwrite");
            let request = transaction.objectStore('favoriteProject').get([store.state.company.company.id, project_id, store.state.login.connectedUser.id] );
            transaction.oncomplete = function (event) {
                //console.log("is_favorite_project : transaction terminated");
            }
            request.onsuccess = function (event) {
              resolve(request.result===undefined?false:true);
            }
            request.onerror = function (event) {
                resolve(undefined);
            }
        });
    },
    async add_favorite_project(project_id=null) {
        let db = await database.db;
        let st_id = store.state.company.company.id;
        let user_id=store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("favoriteProject", "readwrite");
            let favorite_store = transaction.objectStore("favoriteProject");
            transaction.oncomplete = function (event) {
                resolve();
            };
            transaction.onerror = function (event) {
                // console.log('add_favorite_project : project already favorites');
            };
    
            let request = favorite_store.put({
                'user_id': user_id,
                'company_id': st_id,
                'project_id': project_id
            });
            request.onsuccess = function (event) {
                // console.log('add_favorite_project: request successful');
            };
            request.onerror = function (event) {
                // console.log('add_favorite_project: request error');
    
            };
        });
    },
    async  delete_favorite_project(project_id=null) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("favoriteProject", "readwrite");
            transaction.oncomplete = function (event) {
                // console.log("transaction terminated");
                resolve();
            };
            transaction.onerror = function (event) {
                // console.log('erreur de transaction');
            };
            let document_store = transaction.objectStore("favoriteProject");
            let request = document_store.delete([store.state.company.company.id, project_id, store.state.login.connectedUser.id]);
            request.onsuccess = function (event) {
                // console.log('delete_from_db: delete successful');
            };
            request.onerror = function (event) {
                // console.log('delete_from_db: delete error');
            };
        });
        
    },

    async defineCode(secret_key=null){
        let db = await database.db;
        let user=store.state.login.connectedUser;
        return new Promise((resolve) => {
            let transaction = db.transaction("userCodes", "readwrite");
            let userCode_store = transaction.objectStore("userCodes");
            transaction.oncomplete = function (event) {
                // console.log("defineCode : transaction terminated");
                resolve();
            };
            transaction.onerror = function (event) {
                // console.log('defineCode : project already favorites');
            };
    
            let request = userCode_store.put({
                'user_id': user.id,
                'user':user,
                'secret_key': secret_key
            });

            request.onsuccess = function (event) {
                // console.log('defineCode: request successful');
            };
            request.onerror = function (event) {
                // console.log('defineCode: request error');
    
            };
        });
    },

    async user_has_code_defined(email){
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("userCodes", "readwrite");
            let objectStore = transaction.objectStore('userCodes');
            var request = objectStore.openCursor();
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if(cursor.value.user.email===email){
                       resolve(cursor.value.user);
                    }
                    cursor.continue();
                }
                if(cursor==null){
                    resolve(false);
                }
            };
            request.onerror=function(event){
                resolve(null);
            }  
        });
    },

    async auth(mail,key) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("userCodes", "readwrite");
            let objectStore = transaction.objectStore('userCodes');
            var request = objectStore.openCursor();
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if(_.isEqual(key,cursor.value.secret_key) && mail==cursor.value.user.email){
                        resolve(cursor.value.user);
                    }
                    cursor.continue();
                }
                if(cursor==null){
                    resolve(false);
                }
            };
            request.onerror=function(event){
                resolve(false);
            }  
        });
    },



}