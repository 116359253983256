<template>
    <div class="team-modal" v-show="actors.length>0">
        <a-table :columns="columnTeam"
            :data-source="actors"
            :showHeader="true"
            :pagination="false"
            tableLayout="fixed"
            rowKey="id">
            
            <span slot="phone" slot-scope="phone" style="white-space:nowrap"> {{phone}}</span>
             <span v-if="project.isPresents" slot="ppsps" slot-scope="ppsps"> 
                <a-icon v-if="ppsps" type="check" />
             </span>
             
             <span v-if="project.isPresents" slot="icp" slot-scope="icp"> 
                 <a-icon v-if="icp" type="check" />
             </span>
        </a-table>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex'
import _ from 'lodash'

export default {
    name: "TeamModal",
    data() {
        return {
            columnTeam: [],
            limitPath: 3
        };
    },
    computed:{
    getTranslation() {
      return (key) => this.$t(`applayout.${key}`);
    },
        ...mapGetters('project',{ allActors: "getAllActors",project:'getProject' }),
        tableWidth(){
            
            return document.getElementById('TeamTable')?document.getElementById('TeamTable').clientWidth:100
        },
        actors(){
            if(this.project.isPresents){
                 return _.sortBy(this.allActors,['org_lib','name']);
            }
            return _.sortBy(this.allActors,['name']);
        }
    },
    beforeMount() {
        this.columnTeam = [
            {
                title: this.getTranslation('name'),
                dataIndex: "name",
                key: "name",
            },
            {
                title: this.getTranslation('mail'),
                dataIndex: this.project.isPresents?"email":"mail",
                key: "mail",
                ellipsis: true,
            },
            {
                title: this.getTranslation('phone'),
                dataIndex: this.project.isPresents?"gsm":"info.tel",
                scopedSlots: { customRender: "phone" },
                key: "phone",
            }
        ];

        if(this.project.isPresents){
          this.columnTeam.splice(0,0, {
                title: this.getTranslation('entreprise'),
                dataIndex: "org_lib",
                key: "entreprise",
            })  

             this.columnTeam.push({
                title: this.getTranslation('ICP'),
                dataIndex: "icp",
                scopedSlots: { customRender: "icp" },
                key: "icp",
                align:"center",

            })  

             this.columnTeam.push({
                title: this.getTranslation('PPSPS'),
                dataIndex: "ppsps",
                scopedSlots: { customRender: "ppsps" },
                key: "ppsps",
                align:"center",
                width:'10%'
            })  
        }
    }
};
</script>
