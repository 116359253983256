<template>
  <div style="margin-top:10px; ">
    <div class="loader-zoom" v-if="!show_zoom">
      <a-progress type="circle" :percent="percentLoadZoom" />
      <div>{{$t('workflowzoom.loading')}}</div>
    </div>
    <div v-if="show_zoom" id="testZoom" >
        <a-timeline v-for="(version, name, index) in histo" :key="index">
          <div v-if="!version.is_visa">
            <WorkflowZoomSeqBlock :version="version" :file="file" />
          </div>
          <div v-else>
            <WorkflowZoomVisaBlock :version="version" :file="file" @reload="reload()"/>
          </div>
        </a-timeline>
    </div>
  </div>
</template>

<script>
import WorkflowZoomSeqBlock from "./WorkflowZoomSeqBlock";
import WorkflowZoomVisaBlock from "./WorkflowZoomVisaBlock";
import Workflow from "~/service/Ged/Workflow";
import { mapGetters,mapActions } from 'vuex'

export default {
  props: ["file"],
  components: {
    WorkflowZoomSeqBlock,
    WorkflowZoomVisaBlock,
  },
  data() {
    return {
      show_zoom: false,
      percentLoadZoom:0,
      histo:""
    };
  },
  mounted:function(){
     this.reload();
  },
  watch: {
        file: function(file){
            this.reload();  
        }
    },
  methods:{
  async reload() {
        this.percentLoadZoom=0;
        this.show_zoom = false;
        this.histo=await Workflow.get_zoom(this.file.id);
        this.percentLoadZoom=80;
        this.percentLoadZoom=100;
        setTimeout(() => { this.show_zoom = true;}, 250)
  },
  }
};
</script>

