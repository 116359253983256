<template>
   <div :style='{"width": width}'>
    <span v-if="title!=''">{{ title }} : </span>
    <a-select
        show-search
        :style='title==""?"width:100%":""'
        v-model="element"
        :filter-option="filterOption"
        :dropdownMatchSelectWidth="false"
        :disabled="disabled"
    >
        <a-select-option
            v-for="value in choiceValues"
            :key="value.id"
            :value="value.id"
        >
            {{value.text}}
        </a-select-option>
    </a-select>
    </div>
</template>
<script>
import { head, toLower } from "lodash";

export default {
    name: "DropDown",
    props: {
        value: {
            required: true,
        },
        choiceValues:{
            type:Array,
            required:true,
        },
        title:{
            required:false,
            default:''
        },
        width:{
            required:false,
            default:"130px"
        },
        disabled:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    data() {
        return {
            values_default: [],
        };
    },
    methods: {
        filterOption(input, option) {
            const child = head(option.componentOptions.children);
            if (!child) {
                return true;
            }
            const optionValue = toLower(child.text.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
            const searchValue = toLower(input.normalize("NFD").replace(/\p{Diacritic}/gu, ""));

            return optionValue.indexOf(searchValue) >= 0;
        }
    },
    computed:{
        element:{
            get(){
                return this.value
            },
            set(value){
                this.$emit('input',value)
                this.$emit('save')
            }
        }
    }
};
</script>

<style>
</style>