export default {
    CHANGE_IS_LOGGED_IN(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn;
      },
      CHANGE_OAUTH(state, oauth) {
        state.oauth = oauth;
      },
      CHANGE_CONNECTED_ACTOR(state, value) {
        state.connectedActor = value;
      },
      CHANGE_CONNECTED_USER(state,value){
        state.connectedUser=value;
      }
};