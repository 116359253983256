import store from '~/src/store/';
import { database } from '~/src/db';
import {
    find,
    max,
    split,
    map,
    filter,
    isEmpty,
    sortBy,
    pick,
    omit,
} from 'lodash';
import moment from 'moment';

const Rapport_db = {
    async get_rapport_validate(rapport) {
        let db = await database.db
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let request = transaction.objectStore('document').index('module_st_project_id').openCursor(IDBKeyRange.only([rapport.company.id, rapport.project.id, "RAPPORT"]));
            let rapport_db = {};

            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.user_ids.includes(user_id) && ((rapport.lib != 'IC' && cursor.value.lib == rapport.lib) || (rapport.lib == 'IC' && cursor.value.id == rapport.id))) {
                        rapport_db = { ...cursor.value, 'currentUserIsOwner': true };
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(rapport_db);
                }
            };
            request.onerror = function (event) {
                resolve(null);
            }
        });
    },
    async get_edit_rapport(project_id, modele_id, rapport_id = false, exclude_heavy_data = true) {
        let rapports = [];
        let rapport = { header: [], sections: [] };
        let db = await database.db;
        return new Promise(function (resolve) {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').index('project_modele_id').openCursor([project_id, modele_id])

            transaction.oncomplete = function (event) { }
            request.onsuccess = async function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (!rapport_id) {
                        let rapport = find(rapports, function (rapport) {
                            return rapport.header.rapport_id == cursor.value.rapport_id || find(rapport.sections, ["rapport_id", cursor.value.rapport_id]);
                        });
                        if (!rapport) {
                            if (cursor.value.section_id == 0) {
                                rapports.push({ header: cursor.value, sections: [] });
                            } else {
                                rapports.push({ header: [], sections: [cursor.value] });
                            }
                        }
                        else {
                            if (cursor.value.section_id == 0) {
                                rapport.header = cursor.value;
                            } else {
                                rapport.sections.push(cursor.value)
                            }
                        }
                    }
                    else if (cursor.value.rapport_id == rapport_id) {
                        if (cursor.value.section_id == 0) {
                            rapport.header = cursor.value;
                        } else {
                            let section = cursor.value;
                            let excluded_data_section = filter(section.section_elements, (section_element) => {
                                return section_element.element_type == 'IMAGE' || section_element.element_type == 'PJ';
                            })
                            if (exclude_heavy_data) {
                                map(excluded_data_section, (element_section) => {
                                    element_section.element_values = [];
                                })
                            }
                            rapport.sections.push(section)
                        }
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    if (!rapport_id) {
                        resolve(rapports);
                    }
                    else {
                        if (isEmpty(rapport.header)) {
                            resolve(undefined)
                        } else {
                            rapport.sections = sortBy(rapport.sections, 'order');
                            resolve(rapport);
                        }
                    }

                }

            }
            request.onerror = function (event) {
                resolve(null);
            }
        });
    },
    async get_unsynchronised_sections() {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let objectStore = transaction.objectStore('modificationRapports');
            var request = objectStore.openCursor();
            let unsynchronised_sections = [];
            request.onsuccess = async function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (!cursor.value.synchronised) {
                        let section = omit(cursor.value, ['title', 'synchronised', 'blocked_signature']);
                        let section_elements = map(cursor.value.section_elements, (section_element) => {
                            let element_values = section_element.element_values;
                            if (section_element.element_type == 'CHOICE_ACTORS_MAIL') {
                                element_values = map(section_element.element_values, (element_value) => {
                                    if (element_value.id.includes('new_')) {
                                        return element_value;
                                    }

                                    return pick(element_value, ["id", "value"]);
                                });
                            }
                            section_element.element_values = element_values;
                            return omit(section_element, ["element_type", "properties"])
                        });
                        section.section_elements = section_elements;
                        unsynchronised_sections.push(section);
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(unsynchronised_sections);
                }
            };
            request.onerror = function (event) {
                resolve(unsynchronised_sections);
            }
        });
    },
    async get_entreprise_associated_with_modele(modele_id) {
        let db = await database.db;
        let project_id = store.state.project.project.id;
        let entreprises = [];
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').index("project_modele_id").openCursor(IDBKeyRange.only([project_id, modele_id]));
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.section_id == 60) {
                        entreprises.push({ ...cursor.value.section_elements[0].element_values[0], rapport_id: cursor.value.rapport_id });
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(entreprises)
                }
            };
            request.onerror = function (event) {
                resolve(undefined);
            }
        });
    },

    async get_section_by_key(project_id, modele_id, rapport_id, section_id) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').get([project_id, modele_id, rapport_id, section_id]);
            request.onsuccess = function (event) {
                resolve(request.result);
            }
            request.onerror = function (event) {
                resolve(request.result);
            }
        });
    },
    async save_element_values(modele_id, rapport_id, section_id, element_id, element_values, section_name = null, project_id = store.state.project.project.id) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let store = transaction.objectStore('modificationRapports');
            let request = store.get([project_id, modele_id, rapport_id, section_id]);
            request.onsuccess = (event) => {
                let section = request.result;
                if (section) {
                    let element = find(section.section_elements, ['element_id', element_id]);
                    if (element) {
                        element.element_values = JSON.parse(JSON.stringify(element_values));
                    }
                    if (section_name) {
                        section.section_name = section_name;
                    }
                    this.set_value_by_section(section);
                }
                resolve(section);
            }
            request.onerror = function (event) {
                resolve(request.result);
            }
        });
    },
    async get_rapports_en_cours(project_id = null) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').index('project_id').openCursor(IDBKeyRange.only(project_id ?? store.state.project.project.id));

            let rapports = [];
            request.onsuccess = async function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.section_id == 0 && cursor.value.status_id != 999) {
                        if (cursor.value.rapport_id == "") {
                            Rapport_db.delete_section(cursor.value);
                        }
                        else {
                            let rapport = { "id": cursor.value.rapport_id, "header": cursor.value, "modele_id": cursor.value.modele.id, "lib": cursor.value.modele.title }
                            rapport['editing'] = true;
                            rapport['format'] = find(cursor.value.status, ['id', cursor.value.status_id]).text;

                            rapports.push(rapport);
                        }
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(rapports);
                }
            };
            request.onerror = function (event) {
                resolve(rapports);
            }

        });
    },
    async get_rapports_offline() {
        let db = await database.db;
        let user_id = store.state.login.connectedUser.id
        return new Promise((resolve) => {
            let transaction = db.transaction("document", "readwrite");
            let request = transaction.objectStore('document').index('module_st_project_id')
                .openCursor(IDBKeyRange.only([store.state.company.company.id, store.state.project.project.id, "RAPPORT"]));
            let rapports = []
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.user_ids.includes(user_id) && cursor.value.offline == "downloadFinish") {
                        rapports.push(cursor.value);
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(rapports);
                }
            };
            request.onerror = function (event) {
                resolve(rapports);
            }
        });
    },


    async get_all_modeles() {
        let db = await database.db;
        let project_id = store.state.project.project.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("infosProject", "readwrite");
            let request = transaction.objectStore('infosProject').get(project_id);
            request.onsuccess = function (event) {
                resolve(request.result ? request.result.modeles : []);
            }
            request.onerror = function (event) {
                resolve(request.result);
            }
        });
    },
    async set_last_report_modeles(project_id, last_report_information) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("infosProject", "readwrite");
            let request = transaction.objectStore('infosProject').openCursor(
                IDBKeyRange.only(project_id)
            );
            request.onsuccess = function (event) {
                const cursor = request.result;
                if (!cursor) {
                    resolve(null);
                }
                const values = {
                    ...cursor.value,
                    modeles: map(cursor.value.modeles, (modele) => {
                        if (modele.id !== last_report_information.id_model) {
                            return modele;
                        }

                        return {
                            ...modele,
                            last_report_sections: last_report_information.data
                        }
                    })
                }
                const result = cursor.update(values);
                result.onsuccess = function() {
                    resolve(cursor);
                };

                resolve(null);
            }
            request.onerror = function (event) {
                resolve(request.result);
            }
        });
    },
    async get_id_new_rapport(modele) {
        let db = await database.db;
        let idMax = 0;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').index('project_modele_id').openCursor([store.state.project.project.id, modele.id]);
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (typeof cursor.value.rapport_id == "string") {
                        idMax = max([idMax, Number(split(cursor.value.rapport_id, '_', 3)[2])]);
                    }

                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(idMax + 1);
                }
            };
            request.onerror = function (event) {
                resolve(null);
            }

        });
    },


    async create_new_rapport(modele, is_clone = false) {
        moment.locale('fr');
        let project = store.state.project.project;
        let project_id = project.id;
        let rapportSections = [];
        let id_new_rapport = await Rapport_db.get_id_new_rapport(modele);
        let header = {
            "project_id": project_id,
            "modele": { "id": modele.id, "instance_id": modele.current_instance.instance_id, "title": modele.title, "description": modele.description },
            "title": "Header",
            "section_id": 0,
            "can_change_title": modele.id === 4,
            "clonable": modele.clonable,
            "creator_id": store.state.login.connectedUser.id,
            "rapport_id": modele.id + "_new_" + id_new_rapport,
            "is_cloned": is_clone,
            "signatures": modele.signatures,
            "status": modele.status,
            "status_id": modele.status[0].id,
            "section_elements": []
        }
        map(header.signatures, (signature) => {
            signature.image = { isEmpty: true, data: '' };
        })
        map(modele.current_instance.header_elements, (children) => {
            let initValues = ""
            switch (children.element_detail.element_type) {
                case "DROPDOWN":
                    initValues = { default: children.default_value, value: children.default_value[0].id }
                    break;

                case "DATE":
                    initValues = moment().format('L');
                    break;
            }
            header.section_elements.push({ "element_id": children.element_id, "element_title": children.titre, "element_type": children.element_detail.element_type, "element_values": initValues });
        });
        this.set_value_by_section(header);

        map(modele.current_instance.content_elements, (element_group) => {
            let section = {
                "project_id": project_id,
                "modele": { "id": modele.id, "instance_id": modele.current_instance.instance_id },
                "section_id": element_group.element_group_id,
                "rapport_id": modele.id + "_new_" + id_new_rapport,
                "title": element_group.titre,
                "editable": element_group.editable,
                "clonable": element_group.clonable,
                "section_name": element_group.titre,
                "order": element_group.order,
                "section_elements": [],
                "blocked_signature": true
            }
            let is_empty_project_section = false
            map(element_group.children, (children) => {
                let initValues = ""
                switch (children.element_detail.element_type) {
                    case "PARAGRAPH_VC":
                        initValues = [{ description: "", action: "" }]
                        break;

                    case "PARAGRAPH_IC_EXP":
                        initValues = [{ description: children.default_value }]
                        break;

                    case "PARAGRAPH_IC":
                        initValues = [{ description: children.default_value }]
                        break;

                    case "PARAGRAPH_CRR":
                        initValues = [{ description: children.default_value }]
                        break;

                    case "CHOICE_ACTORS_MAIL":
                        //TRAITER DIRECTEMENT DANS LE RAPPORT
                        initValues = []
                        section.blocked_signature = false;
                        break;

                    case "TEXTAREA_MC":
                        initValues = [{ description: "" }]
                        break;

                    case "IMAGE":
                        initValues = []
                        break;

                    case "PJ":
                        initValues = []
                        break;
                    case "REPLACEMENT_INFO":
                        initValues = [{ naff: "", operation_name: "", email: "" }]
                        is_empty_project_section = true;
                        break;
                    case "ENTREPRISE_SECTION_IC":
                        initValues = [{
                            id: "",
                            effectif: "",
                            comment: "",
                            dates_travaux: [moment().format('L'), moment().format('L')],
                            nouvelle_entreprise: {
                                raison: "",
                                service: "",
                                adresse_1: "",
                                adresse_2: "",
                                code_postal: "",
                                ville: "",
                                telephone: "",
                                telecopie: "",
                                site_internet: ""
                            }
                        }]
                        break;

                    case "INFO_GENE_AEU":
                        initValues = [{ purchaser: "", mission_type: "" }]
                        break;

                    default:
                        break;
                }

                section.section_elements.push({ "element_id": children.element_id, "element_type": children.element_detail.element_type, "properties": children.element_detail.properties, "element_values": initValues });
            })
            if ((is_empty_project_section && project.isEmptyProject) || !is_empty_project_section)
                rapportSections.push(section);
        })
        map(rapportSections, (section) => {
            this.set_value_by_section(section);
        });

        return {
            header: header,
            sections: rapportSections
        };
    },
    async set_section_synchronised(modele_id, rapport_id, section_id, project_id = store.state.project.project.id) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let store = transaction.objectStore('modificationRapports');
            let request = store.get([project_id, modele_id, rapport_id, section_id]);
            request.onsuccess = (event) => {
                let section = request.result;
                if (section) {
                    this.set_value_by_section(section, true);
                }
                resolve(section);
            }
            request.onerror = function (event) {
                resolve(null);
            }
        });
    },
    async set_section_pending(modele_id, rapport_id, section_id, project_id = store.state.project.project.id) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let store = transaction.objectStore('modificationRapports');
            let request = store.get([project_id, modele_id, rapport_id, section_id]);
            request.onsuccess = (event) => {
                let section = request.result;
                if (section) {
                    this.set_value_by_section(section, false, true, true);
                }
                resolve(section);
            }
            request.onerror = function (event) {
                resolve(null);
            }
        });
    },

    async set_value_by_section(section, synchronised = false, new_date_update = true, pending = false) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let modificationRapports_store = transaction.objectStore("modificationRapports");
            transaction.oncomplete = function (event) {
                resolve();
            };
            modificationRapports_store.put({
                ...section,
                pending: pending,
                synchronised: synchronised,
                date_update: new_date_update ? moment().format() : section.date_update
            });
        });
    },

    async delete_section(section) {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let document_store = transaction.objectStore("modificationRapports");
            let request = document_store.delete([section.project_id, section.modele.id, section.rapport_id, section.section_id]);
            request.onsuccess = function (event) {
                resolve(true);
            };
            request.onerror = function (event) {
                resolve(false);
            };
        });

    },

    async get_rapport_to_send() {
        let db = await database.db;
        return new Promise((resolve) => {
            let transaction = db.transaction("modificationRapports", "readwrite");
            let request = transaction.objectStore('modificationRapports').index('project_id').openCursor();

            let rapports = [];
            request.onsuccess = async function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.section_id == 0 && cursor.value.send_infos && cursor.value.send_infos.sender_id == store.state.login.connectedUser.id && cursor.value.status_id == 4) {
                        if (cursor.value.rapport_id == "") {
                            Rapport_db.delete_section(cursor.value);
                        }
                        else {
                            let rapport = {
                                "id": cursor.value.rapport_id,
                                "project_id": cursor.value.project_id,
                                "modele_id": cursor.value.modele.id,
                                "text_mail": cursor.value.text_mail,
                                "object_mail": cursor.value.object_mail,
                                "send_infos": cursor.value.send_infos
                            }
                            rapports.push(rapport);
                        }
                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(rapports);
                }
            };
            request.onerror = function (event) {
                resolve(rapports);
            }
        });
    },
}
export default Rapport_db;