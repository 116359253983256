import _ from 'lodash';
export default {
    getActiveDownloads(state) {
        return state.activeDownloads;
      },
      getCompleteDownloads(state) {
        return _.reverse(state.completeDownloads);
      },
      getCountDownloadInProgress(state, getters, rootState, rootGetters) {
        return _.filter(state.activeDownloads, { type: "download", projectId: rootState.project.project.id }).length;
      },
      getCountSynchroInProgress(state, getters, rootState, rootGetters) {
        return _.filter(state.activeDownloads, { type: "synchro", projectId: rootState.project.project.id }).length;
      },
      getCountOfflineInProgress(state, getters, rootState, rootGetters) {
        return _.filter(state.activeDownloads, function (dl) { return (dl.type === "offline+" || dl.type === "offline-") && dl.projectId === rootState.project.project.id; }).length;
      },
      getSizeDownloaded(state) {
        return state.sizeDownloaded;
      },
      getLimitParentPath(state) {
        return state.limitParentPath;
      }
};
