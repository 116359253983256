<template>
  <div :id="'sectiondef-'+section.order"
    :class="isSelected ? 'section-tile selected' : 'section-tile'"
    @click="selectSection()"
  >
    <a-row style="margin-left: 10px">
      <a-col v-if="is_blocked_section" :span="4" class="title" ><div :style="isSelected?'background-color:white':'background-color:#f9b320;'" class="cadenasIconTitle"></div></a-col>
      <a-col :span="is_blocked_section?'16':'20'" class="title">{{ section.order-1}}. {{ section.title }}</a-col>
      <a-col :span="4"><div<a-icon type="right" class="icon-access" /></a-col>
    </a-row>
    <a-divider class="separator-definition" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import _ from "lodash";

export default {
  name: "SectionDefinition",
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("rapports", {
      sectionSelected: "get_user_rapport_section_selected",
      is_locked_report:"is_locked_report",
      is_to_send_or_recap_report:"is_to_send_or_recap_report",
    }),
    is_blocked_section() {
        return (this.section.blocked_signature && this.is_locked_report) || this.is_to_send_or_recap_report
    },
    isSelected() {
      // console.log(this.sectionSelected.section_id)
      if (_.isEqual(this.sectionSelected.section_id, this.section.section_id)) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("rapports", ["set_user_rapport_section_selected_with_values"]),
    selectSection() {
      this.set_user_rapport_section_selected_with_values(this.section);
      let target=document.getElementById(this.section.section_id)
      target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop;
    
    },
  },
};
</script>
