import Quill from "quill";

const Inline = Quill.import('blots/inline');

export default class CustomQuillColor extends Inline {
  constructor(domNode, value) {
    super(domNode, value);
    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
    });
    
    // here we apply every attribute from <font> tag to span as a style
    Object.keys(domNode.attributes).forEach(function (key) {
      if (domNode.attributes[key].name != "style") {
        const domValue = domNode.attributes[key].value;
        let name = domNode.attributes[key].name;
        if (name == "face") {
          name = "font-family";
        }
        span.format(name, domValue);
      }
    });

    this.remove();

    return span;
  }
}

CustomQuillColor.blotName = "customQuillColor";
CustomQuillColor.tagName = "FONT";

Quill.register(CustomQuillColor, true);