
import File_db from "~/db_access/File";
import { getSizeFile } from "~/src/common";
 
export default {
  startFileOperation({ commit }, download) {
    commit('ADD_DOWNLOAD_FILE', download);
  },
  deleteDownloadFile({ commit }, fileId) {
    commit('DELETE_DOWNLOAD_FILE', fileId);
  },
  finishFileOperation({ commit, dispatch,getters,rootGetters }, { file, type = null }) {
    commit('FINISH_DOWNLOAD_FILE', file.id);
    if(rootGetters.getActiveModule=="GED"){
        if (type === "offline+") {
          dispatch('files/addLocalFileToDisplay', file, { root: true });
          dispatch('arborescence/updateArbosTodisplay', {}, { root: true });
        }
        else if (type === "offline-") {
          dispatch('files/deleteLocalFileToDisplay', file, { root: true });
          dispatch('arborescence/updateArbosTodisplay', {}, { root: true });
        }
    }
    if(rootGetters.getActiveModule=="RAPPORT"){
      console.log(rootGetters["getOnline"])
        if (type === "offline-" && !rootGetters["getOnline"]) {
          dispatch('rapports/delete_offline_validate_rapport_to_display', file, { root: true });
      }
  }
  },


  setSizeDownloaded({ commit }, value) {
    commit('CHANGE_SIZE_DOWNLOADED', value);
  },
  addSizeDownloaded({ commit }, payload) {
    if (payload.fileId != undefined) {
      commit('ADD_SIZE_DOWNLOADED_FILE', payload);
    } else {
      commit('ADD_SIZE_DOWNLOADED', payload.sizeDownloaded);
    }

  },

  async downloadFile({ dispatch, rootGetters}, {file,fileData}) {
    console.log(file,fileData);
    dispatch("startFileOperation",{
      id: file.id,
      name: file.lib,
      size: getSizeFile(file),
      type: "download",
      status: "active",
      projectName: rootGetters["project/getProject"].lib,
      dowloadedSize: 0,
      projectId: rootGetters["project/getProjectId"],
      percent: 0,
    });
    File_db.register_to_local_fs(file, fileData).then(() => {
      console.log("finish")
      dispatch("finishFileOperation",{ file: file });
    });
  },
};
