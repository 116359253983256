export default {
    arboActivationStandard: true,
    arboActivationVisa: true,
    arboActivationLocal: true,
    arboOpen: false,
    arboStandardToDisplay: [],
    arboToVisaToDisplay: [],
    arboLocalToDisplay: [],
    isLoading: false,
    loadingPercent:""
};