<template>
    <div class="signatures" :class="$mq">
        <div style="height: 50px; line-height: 50px; display: table; width: 100%">
      
        <div style="display:table-row;width:100%">
        <div
            v-for="signature in this.header_rapport.signatures"
            :key="signature.type"
            :class="signatureSelected.type==signature.type?'onglet selected':'onglet'"
            @click="selectSignature(signature.type)"
        >      
                {{ signature.text }} <span v-if="signature.mandatory"> (Obligatoire)</span>
                <div v-if="!is_recap && signatureSelected.type==signature.type"
                    class="button-reset"
                    @click.stop="resetSignature(signature)"
                >
                    Reset
                </div>
        </div> 
        </div>  
        </div> 
            <VueSignaturePad
                id="signature"
                class="pad"
                width="100%"
                ref="signature"
                :options="{ onEnd }"
            />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import VisualisationModal from './VisualisationModal'
const Antd = require("ant-design-vue");

export default {
    name: "SignatureSider",
    components:{
        VisualisationModal,
    },
    data(){
      return {
        openSider:false,
        blockSider:false,
        sendModal:false,
        signatureSelected:"",
      }
    },
    computed: {
        ...mapGetters("rapports", {
            is_locked_report: "is_locked_report",
            is_to_send_or_recap_report:"is_to_send_or_recap_report",
            is_to_send:"is_to_send",
            is_recap:"is_recap",
            sections_rapport:"get_sections_rapport",
            header_rapport: "get_header_rapport",
        }),
        ...mapGetters(["getOnline"]),
    },
    mounted() {
        if(this.is_to_send_or_recap_report){
            this.$refs.signature.lockSignaturePad();
        }
        this.selectSignature(this.header_rapport.signatures[0].type)
    },
    methods: {
        ...mapActions("rapports", ["save_header","update_signature","change_user_rapport_statut"]),
        selectSignature(type){
            this.signatureSelected=_.find(this.header_rapport.signatures,['type',type])
            this.$refs.signature.fromData(this.signatureSelected.value);
        },
        resetSignature(e,signature) {
            this.$refs.signature.clearSignature();
            this.onEnd();
        },
        async onEnd(event) {
            this.update_signature({type:this.signatureSelected.type,value:this.$refs.signature.toData(),img:this.$refs.signature.saveSignature()})
        },
    },
};
</script>