<template>
    <div class="child-section image-selector">
        <a-upload-dragger
            v-if="checkDisplayed"
            :id="getInputName('file-input')"
            :multiple="true"
            :showUploadList="false"
            :before-upload="beforeUpload"
            @change="handleChange"
            :disabled="images.length >= properties.max_image_number"
        >
            <p class="ant-upload-hint">
                <img class="icon-drag" :src='photoIcon' alt='' width="25px" />
                {{ $t('import_drag', { 'nb': properties.max_image_number}) }}
            </p>
        </a-upload-dragger>
        <input
            v-else
            :id="getInputName('file-input')"
            :ref="getInputName('fileInput')"
            class="input-hide"
            type="file"
            @change="handleFileUpload"
        />
        <div class="file-container">            
             <label
                v-if="this.isModeMobile && images.length < properties.max_image_number && !is_locked_report"
                :for="'file-input_'+section_id" class="file-description add-image"
            >
                <img src="../../../assets/photo.png" alt='photo'/>
            </label>
            <div v-for="(image,index) in images" :key="index" class="file-description">
                <div class="icone-file">
                        <div class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${image.thumbUrl})` }"
                    @click="handlePreview(image)"
                    />
                </div>
                <div class="legend">
                    <a-input :disabled="is_locked_report" v-model="image.name" @blur="save_section"/>
                </div>
                <div class="delete-button" v-if="!is_locked_report">
                    <a-button type="danger" @click="remove(image)"><a-icon type="delete"/></a-button>
                </div>
            </div>
        </div>
        <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancelPreview"
            >
                <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script>
import { filter, find, head } from 'lodash';
import {mapActions,mapGetters} from 'vuex';
import Rapport_db from "~/db_access/Rapport";
import { v4 as uuidv4 } from 'uuid';
import photoIcon from '../../../assets/photo.png';


function getBase64(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const res = canvas.toDataURL('image/webp', 0.15);
                
                resolve(res);
            };
        };
    });
}

export default {
    i18nOptions: {
        keyPrefix: "rapportedit.sections"
    },
    name: "ImageSelector",
    props: {
        properties:{
            type:Object,
            required:false,
            default:()=>{return {}}
        },
        section_id:{
            type:Number,
            required:true,
        },
        element_id:{
            type:Number,
            required:true,
        }
	},
    data() {
        return {
            previewVisible: false,
            previewImage: "",
            is_image: false,
            upload_errors: false,
            in_number_limit: false,
            nb_file_to_add: 0,
            images:[],
        };
    },
    beforeMount() {
        Rapport_db.get_section_by_key(this.header_rapport.project_id, this.header_rapport.modele.id,this.header_rapport.rapport_id,this.section_id).then((section)=>{
            let section_element = find(section.section_elements,['element_type',"IMAGE"]);
            if (section_element) {
                this.images=section_element.element_values;
            }
        })
    },
    methods: {
        ...mapActions('rapports',["save_element_section"]),
        async handleChange(info) {
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'done') {                
                this.images.push({
                    uuid: uuidv4(),
                    name: "",
                    thumbUrl: await getBase64(info.file.originFileObj)
                });
                this.save_element_section({section_id:this.section_id,element_id:this.element_id,element_values:this.images});
            }
        },
        beforeUpload(file, fileList) {
            this.in_number_limit = (this.images.length + fileList.length) <= this.properties.max_image_number;
            if (!this.in_number_limit) {
                this.$notification["error"]({
                    key: `errorLimit${this.section_id}`,
                    message: this.$i18n.i18next.t('rapportedit.errors.upload_error'),
                    description: this.$i18n.i18next.t('rapportedit.errors.file_limit', { 'nb': this.properties.max_image_number}),
                });
            }
            this.is_image = head(file.type.split('/')) === 'image';
            if (!this.is_image) {
                this.$notification["error"]({
                    key: `errorFile${this.section_id}`,
                    message: this.$i18n.i18next.t('rapportedit.errors.upload_error'),
                    description: this.$i18n.i18next.t('rapportedit.errors.image_ext_error'),
                });
            }

            return this.is_image && this.in_number_limit;
        },
        async handleFileUpload(){
            let input = this.$refs['fileInput_'+this.section_id];
            let file = input.files;
            if (file && file[0]) {
                this.is_image = (file[0] && file[0].type.split('/')[0] === 'image');
                if (!this.is_image) {
                    this.$notification["error"]({
                        message: this.$i18n.i18next.t('rapportedit.errors.image_ext_error'),
                    });
                    return;
                }
                this.images.push({
                    uuid: uuidv4(),
                    name: "",
                    thumbUrl: await getBase64(file[0])
                });
                this.save_element_section({section_id:this.section_id,element_id:this.element_id,element_values:this.images});
            }
        },
        handleCancelPreview() {
            this.previewVisible = false;
        },
        async handlePreview(image) {
            this.previewImage = image.thumbUrl
            this.previewVisible = true;
		},
        save_section() {
            this.save_element_section({section_id:this.section_id, element_id:this.element_id, element_values:this.images});
        },
        remove(image) {
            this.images = filter(this.images,(_image)=>_image.uuid!==image.uuid);
            this.save_element_section({section_id:this.section_id,element_id:this.element_id,element_values:this.images});
        },
    },
    computed: {
        ...mapGetters("rapports", { is_locked_report: "is_locked_report",header_rapport: "get_header_rapport" }),
        iconStyle() {
            return {
                color: '#828282',
                height: '50px',
                'font-size': '30px',
                'margin-bottom': '10px',
            };
        },
        photoIcon() {
            return photoIcon;
        },
        getInputName() {
            return (suffix) => `${suffix}_${this.section_id}`;
        },
        isModeMobile() {
            return this.$mq === 'mobile' || this.$mq === 'tablet';
        },
        checkDisplayed() {
            return !this.isModeMobile && !this.is_locked_report;
        }
    }
};
</script>