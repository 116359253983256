<template>
    <div class="rapport-header">
        <div class="switch-header" v-if="canExpand">
            <img v-if="expand" src="../../../assets/navigationOpen.png" @click="$emit('expand-or-collapse')"/>
            <img v-else src="../../../assets/navigationClose.png" @click="$emit('expand-or-collapse')"/>
        </div>

            <div class="details">
            <div v-if="$mq!='mobile'" style="display:inline-block; margin-right:10px;">
                Modele : <a-input :disabled="true" style="width:70px" :value="header.modele.title"/>
            </div>
            <div v-for="element in header.section_elements" :key="element.element_id" style="display:inline-block">
                 <DatePicker
                    v-if="element.element_type == 'DATE'"
                    class="type-header" :disabled="is_locked_report || is_to_send_or_recap_report" :title="element.element_title" v-model="element.element_values" @save="save_header"
                />
                <DropDown
                    v-if="element.element_type == 'DROPDOWN' && element.element_values.default.length>1"
                    class="type-header" :disabled="is_locked_report || is_to_send_or_recap_report" :title="element.element_title" v-model="element.element_values.value" :choiceValues="element.element_values.default" @save="save_header"
                />
            </div>
        </div>
        <div v-if="online && !send_interface && !preview_interface" class="indic-synchro" @click="update_editing_report_from_local_to_server({with_notification: true})">
            <img v-if="last_synchro.success" src='../../../assets/synchro_ok.png' :class="getClassImageSynchro" />
            <img v-else src='../../../assets/synchro_ko.png' :class="getClassImageSynchro" />
        </div>
        <div class="switch-previsualisation" v-if="canPreview && online">
            <img src="../../../assets/edit_white.png" />
            <a-switch size="large" :checked="preview" @change='changePreview'/>
            <img style="margin-left:5px" src="../../../assets/visualisation_white.png" />
        </div>
        <!-- <PdfPreviewModal
            :rapport_id=header.rapport_id
            @display-maximize-modal="displayMaximizeModal"
        />
        <a-button size="large" v-if="maximizeModalButton" @click="maximizeModal" class="maximize-modal-button"><a-icon style="fontSize: 22px" type="arrow-right"/></a-button> -->
    </div>
</template>

<script>
import { find, findIndex } from "lodash";
import DropDown from "../sections/HeaderSections/DropDown"
import DatePicker from "../sections/HeaderSections/DatePicker"
import PdfPreviewModal from "./PdfPreviewModal.vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "RapportHeader",
    components: {
        DropDown,
        DatePicker,
        PdfPreviewModal
    },
    data() {
        return {
            dateFormat: 'DD/MM/YYYY',
            color: process.env.VUE_APP_COLOR_COMMON,
            maximizeModalButton: false,
        };
    },
    created() {
        this.maximizeModalButton = false;
    },
    computed: {
        ...mapGetters("rapports", {
            is_locked_report: "is_locked_report",
            is_to_send_or_recap_report:"is_to_send_or_recap_report",
            preview:"get_open_preview_interface",
            last_synchro:"get_last_synchro",
            report_is_syncing: "report_is_syncing",
            send_interface:"get_open_send_interface",
            preview_interface:"get_open_preview_interface",
        }),
        ...mapGetters({
            online: "getOnline"
        }),
        statutEnCours() {
            let idSignature = find(this.header.status, function (statut) {
                return statut.text == "Signature";
            }).id;
            if (idSignature) {
                if (
                    this.header.status_id < idSignature &&
                    this.is_locked_report
                ) {
                    return findIndex(this.header.status, function (statut) {
                        return statut.id == idSignature;
                    });
                }
            }
            return findIndex(this.header.status, (statut)=> {
                return statut.id == this.header.status_id;
            });
        },
        getClassImageSynchro() {
            return this.report_is_syncing(this.header.project_id, this.header.rapport_id) ? 'rotate-img' : '';
        }
    },
    methods: {
        ...mapActions("rapports", [
            "save_header","change_preview_interface","update_editing_report_from_local_to_server"
        ]),
        ...mapMutations("rapports", ['CHANGE_FORCE_SYNC_MODAL']),
        onChange(date, element) {
             element.element_values=date.format("L");
             this.save_header();
        },
        changePreview(value){
            this.change_preview_interface(value);
        },
        displayMaximizeModal() {
            this.maximizeModalButton = true;
        },
        maximizeModal() {
            this.maximizeModalButton = false;
            this.CHANGE_FORCE_SYNC_MODAL({new_val: true});
        }
    },
    props: {
        expand:{
            type:Boolean,
            required:true,
        },
        header: {
            type: Object,
            default: () => {},
        },
        canExpand:{
             type:Boolean,
            required:true,
        },
        canPreview:{
             type:Boolean,
            required:true,
        }
    },
};
</script>