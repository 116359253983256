export default {
    CHANGE_SELECTED_DIRECTORY(state, selectedDirectory) {
        state.selectedKeyDirectory = selectedDirectory.key;
        state.selectedDirectory = selectedDirectory;
    },
    CHANGE_CURRENT_PATH(state, currentPath) {
        state.currentPath = currentPath;
    },
    UPDATE_DIRECTORIES_TO_DISPLAY(state, payload) {
        state.directoriesStandardToDisplay = payload.directoriesStandardToDisplay;
        state.directoriesToVisaToDisplay = payload.directoriesToVisaToDisplay;
        state.directoriesLocalToDisplay = payload.directoriesLocalToDisplay;
    },
    CHANGE_DISPLAY_CHILDREN(state, directories) {
        state.displayedDirectories = directories;
      },
};