<template>
    <div class="pwa-screen">
    <a-layout>
      <a-layout-header class="layout-header" :class="$mq">
        <Header
            :displayLogoButton="true"
            :displayBackButton="true"
            :displayStockagePercent="false"
            :title="fileToView.lib"
        />
        </a-layout-header>
      <a-layout-content>
         <Viewer :file="fileToView"/>
      </a-layout-content>
    </a-layout>
    </div>
</template>

<script>
import Header from "./applayout/Header.vue";
import Viewer from "./viewer/Viewer.vue";
import { mapGetters } from 'vuex'

export default {
    name: "EspaceSelectionScreen",
    components: {
        Viewer,
        Header
    },
    computed: {
        ...mapGetters({ online: "getOnline" }),
        ...mapGetters("files", {fileToView: "getFileToView"}),
    }
};
</script>