export default {
    getCompany(state){
      return state.company;  
    },
    getOnlineCompanies(state){
        return state.onlineCompanies;
    },
    getOfflineCompanies(state){
        return state.offlineCompanies;
    }
};
