export default {
    CHANGE_COMPANY_ID(state, company_id) {
        state.company_id = company_id;
    },

    CHANGE_COMPANY_NAME(state, companyName) {
        state.companyName = companyName;
    },
    CHANGE_ONLINE_COMPANIES(state,companies){
        state.onlineCompanies=companies;
    },
    CHANGE_OFFLINE_COMPANIES(state,companies){
        state.offlineCompanies=companies
    },
    CHANGE_COMPANY(state,company){
        state.company=company;
    }
};