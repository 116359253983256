<template>
    <ConnexionForm />
</template>

<script>
import ConnexionForm from './connexion/ConnexionForm.vue'

export default {
    name: 'ConnexionScreen',
    components: {
        ConnexionForm,
    },
}
</script>
