import _ from 'lodash';
import Project_API from "~/service/Ged/Project";
import Project_db from "~/db_access/Project";
import File_db from "~/db_access/File";
import Rapport_db from "~/db_access/Rapport";
import File_API from "~/service/Ged/File";
import moment from 'moment';


async function getNodeAndPathOfDirectory(node, idToFind, directory = { node: '', path: [] }) {

	if (node.key === idToFind) {
		directory.node = node;
		return directory;
	}

	if (node.children.length > 0) {
		let i = 0;
		directory.path.push({
			id: node.key,
			name: node.title
		});
		while (i < node.children.length && directory.node === '') {
			directory = await getNodeAndPathOfDirectory(node.children[i], idToFind, directory);
			i++;
		}
		if (directory.node === '') {
			directory.path = _.reject(directory.path, { 'id': node.key });
		}
	}
	return directory;
}

async function getArboVisa(arboNormal) {
	let dataTree = JSON.parse(JSON.stringify(arboNormal));
	let filesToVisa = await File_API.get_all_files_to_visa();
	let parents = [];
	_.map(filesToVisa.documents, (document) => {
		if (parents.indexOf(document.pere) === -1) {
			parents.push(document.pere);
		}
	})
	await cleanTree(dataTree[0], parents);
	return dataTree;
}

async function getArboLocal(arboNormal) {
	return new Promise((resolve) => {
		File_db.get_folder_ids().then((parents) => {
			let dataTree = JSON.parse(JSON.stringify(arboNormal));
			cleanTree(dataTree[0], parents).then(() => {
				Project_db.put_arbo(dataTree);
				resolve(dataTree);
			});
		});
	});
}

async function getArboNormal() {
	let arbo = await Project_API.get_arbo();
	return arbo;
}

async function cleanTree(node, idsToKeep) {
	var keepParent = false;
	if (node.children.length > 0) {
		var keepChildren;
		for (var i = node.children.length - 1; i >= 0; i--) {
			keepChildren = false;
			keepChildren = await cleanTree(node.children[i], idsToKeep);
			if (!keepChildren) {
				_.pullAt(node.children, i);
			} else {
				keepParent = true;
			}
		}
	}
	if (idsToKeep.indexOf(node.key) !== -1) {
		keepParent = true;
	}
	return keepParent;
}

async function defineAction(file) {
	let fileDB = await File_db.get_file(file, false);
	if (fileDB == undefined || !fileDB.currentUserIsOwner) {
		return "notDownloaded";
	} else {
		return fileDB.offline;
	}
}

async function defineActionRapport(rapport) {
	let rapport_db = await Rapport_db.get_rapport_validate(rapport);
	if (rapport_db == undefined || !rapport_db.currentUserIsOwner) {
		rapport["offline"] = "notDownloaded";
	} else {
		rapport["offline"] = rapport_db.offline;
	}
}

function isToVisa(file, filesToVisa) {
	if (filesToVisa.documents !== undefined) {
		for (var i = 0; i < filesToVisa.documents.length; i++) {
			if (filesToVisa.documents[i].id == file.id) {
				file["flag"] = "A Viser";
				return true;
			}
		}
	}
	file["flag"] = "";
	return false;
}
function getSizeFile(file) {
	let propertySize = 'size';
	if (!file[propertySize]) {
		propertySize = 'taille';
	}
	let multiplicateur = 1;
	if (file[propertySize].includes("MB")) {
		multiplicateur = 1000 * 1000;
	} else if (file[propertySize].includes("kB")) {
		multiplicateur = 1000;
	} else if (file[propertySize].includes("GB")) {
		multiplicateur = 1000 * 1000 * 1000;
	}
	let fileSizeToVisu = file[propertySize].replace(/[^\d-]/g, "") * multiplicateur;

	return fileSizeToVisu;
}
function convertDateValidateCr(strDate) {
	return moment(strDate).format('DD/MM/YYYY [à] HH[h]mm');
}

function isViewableFile(file) {
	if (process.env.VUE_APP_VISUALISABLE_FILE.indexOf(file.format.toUpperCase()) !== -1)
		return false;
	return true;
}

export {
	getNodeAndPathOfDirectory,
	getArboLocal,
	getArboVisa,
	getSizeFile,
	isToVisa,
	defineAction,
	getArboNormal,
	convertDateValidateCr,
	isViewableFile,
	defineActionRapport
}