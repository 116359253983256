<template>
  <div class="document-list">
    <div>
      <div class="header-list">
        <PathRenderer :arbo="arboActivation" />
        <span @click="clearCache()" class="clear-cache" :class="$mq"><img class="icon-refresh" src="../../assets/refresh.png" /> R</span>
        <span v-if="$mq!=='mobile'" class="count-files-and-folders">
          <span v-if="arboActivation"
            >{{ directories.length }}
            <img class="icon-count" src="../../assets/directoriesCount.png"
          /></span>
          {{ files.documents.length }}/{{ files.total_documents }}
          <img class="icon-count" src="../../assets/filesCount.png" />
        </span>
      </div>
      <div class="tableau-list document" :class="$mq">
          <a-progress
            style="width: 100%"
            v-show="loaders.length !== 0"
            :percent="globalLoadingPercent"
            :show-info="false"
          />
        <div>
          <div
            v-show="arboActivation"
            @onSelect="selectDirectory"
            class="tableau-directory"
          >
            <a-config-provider>
              <template #renderEmpty >
                <span v-if="loaders.length > 0 && directories.length == 0"
                  >Chargement des dossiers en cours</span
                >
                <span v-else>
                  <img
                    class="icon-empty "
                    src="../../assets/noDirectories.png"
                  />
                  <span slot="description" class="text-empty">{{
                    $t("fileselection.nofile.nofolder")
                  }}</span>
                </span>
              </template>
              <a-table
                :columns="columnsDirectories"
                :data-source="directories"
                :customRow="customRowSelectDirectory"
                :showHeader="false"
                :pagination="false"
                
              >
                <div slot="IconDocumentList" slot-scope="directory">
                  <img
                    class="icon-list"
                    @click="selectDirectory(directory.key)"
                    src="../../assets/folder.png"
                  />
                </div>
                <div slot="directory" slot-scope="directory">
                  <div @click="selectDirectory(directory.key)">
                    {{ directory.title }}
                  </div>
                </div>
              </a-table>
            </a-config-provider>
          </div>
          <div class="tableau-files">

            <a-config-provider>
              <template #renderEmpty>
                <span
                  v-if="loaders.length > 0 && files.documents.length == 0"
                  >{{ $t("fileselection.loading_files") }}</span
                >
                <span v-else>
                  <img class="icon-empty" src="../../assets/noFiles.png" />
                  <span class="text-empty" v-show="displayState == 1">{{
                    $t("fileselection.nofile.noregular")
                  }}</span>
                  <span class="text-empty" v-show="displayState == 2">{{
                    $t("fileselection.nofile.novisa")
                  }}</span>
                  <span class="text-empty" v-show="displayState == 3">{{
                    $t("fileselection.nofile.nolocal")
                  }}</span>
                </span>
              </template>
              <a-table
                :columns="online ? columnsFiles : columnsFilesOffline"
                :data-source="files.documents"
                :pagination="false"
                :showHeader="false"
                :customRow="customRowSelectFile"
                tableLayout="fixed"
                rowKey="id"
              >
                <span slot="format" slot-scope="format"><IconeDocRenderer v-if="format!=''" :type="format" styleImage="width:25px;" /></span>
                <span slot="name" slot-scope="file">{{ file.lib }}</span>
                <span  slot="flag" slot-scope="file" v-if="file.flag!=''"><FlagDocRenderer :file="file" /></span>
                <span slot="zoom" slot-scope="file">
                  <img
                    v-if="!file.wf_type || !online"
                    class="icon-list"
                    src="../../assets/infoSimple.png"
                    @click="showModalZoom(file)"
                  />
                  <img
                    v-else
                    class="icon-list"
                    src="../../assets/infoWorkflow.png"
                    @click="showModalZoom(file)"
                  />
                </span>
                <span slot="offline" slot-scope="file" @click="blockRow=true"> <SwitchOffline v-if="formatViewable.includes(file.format)" :file="file"/></span>
              </a-table>
            </a-config-provider>
          </div>
        </div>
      </div>
      <a-modal
        v-model="modalZoom"
        :closable="false"
        :footer="null"
        :centered="true"
        class="pwa-modal zoom"
        width="80vw"
      >
        <div class="entete">
          <IconeDocRenderer class="detail-file icon"
              :type="modalFile.format"
              :styleImage=' $mq!=="mobile"?"width:80px; margin-right:20px;":"width:25px; margin-right:20px;"'
          />
          <DetailsFileRenderer :file="modalFile" class="detail-file"/>
          <div v-if="formatViewable.includes(modalFile.format)" class="visualisation-button" @click="visualisation(modalFile)">Visualiser le document</div>

        </div>
        <div class="detail-zoom" v-if="modalFile.wf_type && online">
          <WorkflowZoom style="margin-top: 5px" :file="modalFile" />
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import IconeDocRenderer from "../renderer/IconeDocRenderer";
import FlagDocRenderer from "../renderer/FlagDocRenderer";
import PathRenderer from "../renderer/PathRenderer";
import DetailsFileRenderer from "../filevisualisation/DetailsFileRenderer";
import WorkflowZoom from "../workflowzoom/WorkflowZoom";
import SwitchOffline from "../common/SwitchOfflineFile";
import { mapGetters, mapActions } from "vuex";
import { isViewableFile } from "~/src/common";


const columnsDirectories = [
  {
    title: "",
    dataIndex: "directory",
    key: "IconDocumentList",
    scopedSlots: { customRender: "IconDocumentList" },
    width: "2%",
  },
  {
    title: "Directory",
    dataIndex: "directory",
    key: "directory",
    scopedSlots: { customRender: "directory" },
  },
];
const columnsFiles = [
  {
    title: "Format",
    dataIndex: "format",
    key: "format",
    scopedSlots: { customRender: "format" },
    width: "50px",
  },
  {
    title: "Name",
    key: "name",
    scopedSlots: { customRender: "name" },
    ellipsis:true,
  },
  {
    title: "Flag",
    key: "flag",
    scopedSlots: { customRender: "flag" },
    width: "70px",
  },
  {
    title: "Zoom",
    key: "zoom",
    scopedSlots: { customRender: "zoom" },
    width: "50px",
  },
  {
    title: "Offline",
    key: "offline",
    scopedSlots: { customRender: "offline" },
    width: "70px",
  },
];
const columnsFilesOffline = [
  {
    title: "Format",
    dataIndex: "format",
    key: "format",
    scopedSlots: { customRender: "format" },
    width: "50px",
  },
  {
    title: "Name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Zoom",
    key: "zoom",
    scopedSlots: { customRender: "zoom" },
    width: "50px",
  },
];

export default {
  name: "DocumentsList",
  components: {
    IconeDocRenderer,
    FlagDocRenderer,
    PathRenderer,
    DetailsFileRenderer,
    WorkflowZoom,
    SwitchOffline
  },
  data() {
    return {
      columnsFiles,
      columnsFilesOffline,
      columnsDirectories,
      multiSelect: false,
      blockRow:false,
      limitPath: 3,
      color:process.env.VUE_APP_COLOR_COMMON,
      modalZoom: false,
      modalFile: "",
      modalFileLink: "",
      fileSizeToVisu: 0,
      styleIframe: "width:0px; height:0px;",
      loadFiles: false,
      formatViewable:process.env.VUE_APP_VIEWABLE_FILE,
    };
  },
  methods: {

    ...mapActions(["updateFilesAndDirectoryToDisplay", "createLoader"]),

    ...mapActions("files", ["updateFilesToDisplay", "setFileToView"]),

    ...mapActions("directories", ["resetDirectoriesToDisplay"]),
    checkIsViewableFile(file) {
      return isViewableFile(file);
    },
    async clearCache() {
      caches.open("runtime").then(async function (cache) {
        cache.keys().then(function (keys) {
          keys.forEach(function (request, index, array) {
            cache.delete(request);
          });
        });
      });
      this.selectDirectory(this.selectedKeyDirectory);
    },
     customRowSelectDirectory(record) {
      return {
        on: {
          click: event => {
            this.selectDirectory(record.key)
          }
        }
      };
    },
    customRowSelectFile(record) {
      return {
        on: {
          click: event => {
             setTimeout(() => {
              if(!this.blockRow)
              if(this.formatViewable.includes(record.format)){
                  this.visualisation(record)
              }else{
                  this.$notification["error"]({message: "Ce format de fichier n'est pas visualisable"});
              }
              else
                  this.blockRow=false;
              }, 50);
          }
        }
      };
    },
    showModalZoom(file) {
      this.blockRow=true;
      this.modalFile = file;
      this.modalZoom = true;
    },
    async visualisation(file) {
      if (file.format === "LINK") {
        window.open(file.lib, "_blank");
        return;
      }

      await this.setFileToView(file);
      this.$router.push({ name: "Viewer" });
      return;
    },
    async selectDirectory(key) {
      this.createLoader([20, 80]).then(()=>{
          this.updateFilesAndDirectoryToDisplay({
            selectedKeyDirectory: key,
          });
        });
    },
    async loadNextPage() {
      if (
        this.files.documents.length < this.files.total_documents &&
        !this.loadFiles
      ) {
        this.loadFiles = true;
        this.updateFilesToDisplay(
          {
            selectedKeyDirectory: this.selectedKeyDirectory,
            page:false,
          }).then(()=>{
              this.loadFiles = false
        });
      }
    },
  },
  mounted() {
    let scrollTab=document.getElementsByClassName("tableau-list")[0]
    scrollTab.addEventListener("scroll",()=> {
        var scrollTop = scrollTab.scrollTop;
        var scrollHeight = scrollTab.scrollHeight;
        var offsetHeight = scrollTab.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop * 2) {
          this.loadNextPage();
        }
      },
      false
    );
  },
  computed: {
    ...mapGetters({
      displayState: "getDisplayState",
      loaders: "getLoaders",
      online: "getOnline",
      globalLoadingPercent: "getGlobalLoadingPercent",
    }),

    ...mapGetters("directories", {
      selectedKeyDirectory: "getSelectedKeyDirectory",
      directories: "getChildrensDirectories",
    }),

    ...mapGetters("files", {
      files: "getDisplayedFiles",
      currentPage: "getCurrentPage",
    }),

    ...mapGetters("arborescence", {
      arboOpen: "getArboOpen",
      treeData: "getTreeData",
      arboActivation: "getArboActivation",
    }),
  },
};
</script>
