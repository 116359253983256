import { get } from '~/service/Ged/service.js';
import store from '~/src/store/';
import Project_API from '~/service/Ged/Project';
import File_db from "~/db_access/File";
import _ from 'lodash'

const File_API = {

    dl(file, save_to_db = true,converted=false) {
        if(converted)
        {
            return new Promise((resolve) => {
                get('files/' + file.id + "/state", null, async function (res) {
                    console.log(res);
                    switch (res.state_id) {
                        case 2:
                            file.converted=true;
                            get('files/' + file.id, {"converted":converted}, async function (resultat) {
                                console.log('reading stream...');
                                let data = await File_API.read_stream(resultat.body.getReader(), file, save_to_db);
                                console.log(file.lib + ' - file done downloading');
                                // console.log('get_from_api: request successful');
                                resolve(data);
                            }, true, file.company.id, false);
                        break;

                        case -4:
                            file.converted=false;
                            get('files/' + file.id, {"converted":false}, async function (resultat) {
                                console.log('reading stream...');
                                let data = await File_API.read_stream(resultat.body.getReader(), file, save_to_db);
                                console.log(file.lib + ' - file done downloading');
                                // console.log('get_from_api: request successful');
                                resolve(data);
                            }, true, file.company.id, false);
                        break;
                        default:
                            file.converted=false;
                            break;
                    }
                });
            });
        }
        else
        {
            console.log("ici")
            return new Promise((resolve) => {
                console.log(file.lib + ' - download start');
                get('files/' + file.id, {"converted":converted}, async function (res) {
                    console.log('reading stream...');
                    let data = await File_API.read_stream(res.body.getReader(), file, save_to_db);
                    console.log(file.lib + ' - fin du telechargement');
                    // console.log('get_from_api: request successful');
                    resolve(data);
                }, true, file.company.id, false);
            });
        }

    },

    read_stream(reader, file, save_to_db) {
        let data = new Uint8Array();
        let tempData = new Uint8Array();
        let chunk_ids = [];
        return new Promise((resolve) => {
            new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(async ({ done, value }) => {
                            if (store.state.interruptCurrentLoading.includes(file.id)) {
                                await store.dispatch('rmInterruptCurrentLoading', file.id);
                                resolve(false);
                                return false;
                            }
                            if (done) {
                                if (tempData.length != 0 && save_to_db) {
                                    chunk_ids.push(await File_db.put_chunk(file,tempData));
                                }
                                if(save_to_db){
                                    file.offline="downloadFinish"
                                    file.chunk_ids=chunk_ids;
                                    await File_db.update_user_file(file);
                                }
                                controller.close();
                                resolve(tempData);
                            }
                            await store.dispatch('operations/addSizeDownloaded', { fileId: file.id, sizeDownloaded: value.byteLength });
                            controller.enqueue(value);
                            if (tempData.length + value.length < 100000000) {
                                let a = new Uint8Array(tempData.length + value.length);
                                a.set(tempData);
                                a.set(value, tempData.length);
                                tempData=a;
                            } else {
                                if (save_to_db) {
                                    chunk_ids.push(await File_db.put_chunk(file,tempData));
                                    tempData = new Uint8Array();
                                }
                            }

                            return pump();
                        });
                    }
                }
            })
        })
    },

    async get_files_by_root_id({ root_id = null, page_id = 1, loader = { loaderLinked: null, startPercent: 0, endPercent: 0 } }) {
        let project_id = store.state.project.project.id;
        let countResultPerPage = store.state.files.pageSize;

        let result = { "documents_per_page": countResultPerPage, "documents": [] };
        if (root_id !== null) {
            return new Promise(function (resolve) {
                get('projects/' + root_id + '/files', null, async function (files) {
                    result.total_documents = files.length;
                    let percentPerFile = (loader.endPercent - loader.startPercent) / (result.total_documents === 0 ? 1 : result.total_documents);
                    let fileDetailsCount = 0;
                    let fileIds = [];
                    for (var i = countResultPerPage * (page_id - 1); i < countResultPerPage * page_id; i++) {
                        if (root_id != store.state.directories.selectedKeyDirectory) {
                            return false;
                        }
                        if (i < files.length) {
                            fileIds.push(files[i].id)
                            if (loader.loaderLinked !== null) {
                                fileDetailsCount++;
                                await store.dispatch("changeLoadingPercent", { loaderLinked: loader.loaderLinked, loadingPercent: Math.round(loader.startPercent + fileDetailsCount * percentPerFile) });
                            }
                        }
                    }


                    result.documents = await Project_API.get_objs_details(fileIds);
                    resolve(result);
                });
            });
        } else {
            return new Promise(function (resolve) {
                get('projects/' + project_id + '/all_files/' + page_id, null, async function (result) {
                    result.documents = await Project_API.get_objs_details(_.map(result.documents, "id"));
                    resolve(result);
                });
            })
        }
    },

    async get_all_files_to_visa() {
        let project_id = store.state.project.project.id;
        let result = { "documents_per_page": "" };
        return new Promise(function (resolve) {
            get('projects/' + project_id + '/all_files/visas', null, async function (files) {
                result.total_documents = files.total_documents;
                result.documents = await Project_API.get_objs_details(_.keys(files.documents));
                resolve(result);
            });
        });
    },
}

export default File_API;