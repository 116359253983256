
<template>
  <div>

    <div style="font-weight: bold">{{ detailFile.lib }}</div>
    <div>{{ $t("filevisualisation.version") }} {{ detailFile.version }}</div>
    <div>{{ $t("filevisualisation.size") }} {{ detailFile.taille }}</div>
    <div>
      {{ $t("filevisualisation.added_by") }} {{ detailFile.add_by }}
      {{ $t("filevisualisation.on") }} {{ detailFile.dt_add }}
    </div>
    <div>
      {{ $t("filevisualisation.updated_by") }} {{ detailFile.upd_by }}
      {{ $t("filevisualisation.on") }} {{ detailFile.dt_upd }}
    </div>
  </div>
</template>
<script>
import Project_API from "~/service/Ged/Project";

export default {
  name: "DetailsFileRenderer",
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      detailFile:{}
    }
  },
   watch: {
     file:{
        immediate:true,
        async handler(){
            this.detailFile=await Project_API.get_obj_detail(this.file.id);
            console.log(this.detailFile);
        }
    },
   }
};
</script>


