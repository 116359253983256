<template>
    <a-modal
        class="pwa-modal"        
        :destroyOnClose="true"
        :visible="adminModalVisible"
        :closable="false"
        :centered="true"
        @cancel="handleCancel"
        @ok="handleImport"
        okText="Importer"
        title="Administration"
        width="50%"
        height="50%"
    >
        <input
            id="file-input-import-db"
            ref="fileInputImportDb"
            class="input-hide"
            type="file"
            @change="handleFileUpload"
        />
    </a-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { InboxOutlined } from '@ant-design/icons-vue';
import { v4 as uuidv4 } from 'uuid';

function getBlob(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        resolve(reader.readAsDataURL(file));
    });
}

export default {
    name: "AdminModal",    
    created() {
        this.form = this.$form.createForm(this, { name: "admin_form" });
    },
    components: {
        InboxOutlined,
    },
    data() {
        return {
            upload_errors: false,
            in_number_limit: false,
            fileToImport:{},
        };
    },
    methods: {
        ...mapMutations(['set_admin_modal_visible']),
        ...mapActions(['importDb']),
        async handleImport() {
            await this.importDb(this.fileToImport);
            this.set_admin_modal_visible(false);
            this.$notification["success"]({
                message: 'Import success',
            });
        },
        handleCancel() {
            this.set_admin_modal_visible(false);
        },
        async handleFileUpload(){
            let input = this.$refs['fileInputImportDb'];
            let file = input.files;
            if (file && file[0]) {
                this.fileToImport = file[0];
            }
        },
    },
    computed: {
        ...mapState(['adminModalVisible']),
    }
};
</script>