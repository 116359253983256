

export default {
    UPDATE_ARBO_TO_DISPLAY(state, payload) {
        state.arboStandardToDisplay = payload.arboStandardToDisplay;
        state.arboToVisaToDisplay = payload.arboToVisaToDisplay;
        state.arboLocalToDisplay = payload.arboLocalToDisplay;
      },
      CHANGE_ARBO_ACTIVE(state, {displayState,boolean}) {
        switch (parseInt(displayState)) {
          case 1:
            state.arboActivationStandard = boolean;
            break;
          case 2:
            state.arboActivationVisa = boolean;
            break;
          case 3:
            state.arboActivationLocal = boolean;
            break;
          default:
            state.arboActivationStandard = boolean;
    
        }
      },
      CHANGE_ARBO_OPEN(state, boolean) {
        state.arboOpen = boolean;
      },
};