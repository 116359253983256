import store from '~/src/store/';
import _ from 'lodash';
import { database } from '~/src/db';

export default {
    async get_company_offline() {
        let db = await database.db;
        let companies = []
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("infosProject", "readwrite");
            let request = transaction.objectStore('infosProject').index('company_id').openCursor();
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.user_ids.includes(user_id)) {
                        let company = cursor.value.company;
                        company.isOffline = true;
                        companies.push(company);

                    }
                    cursor.continue();
                }
                if (cursor == null) {
                    resolve(_.uniqBy(companies, "id"));
                }
            };
            request.onerror = function (event) {
                resolve(companies);
            }
        });
    },

    async delete_infos_company(company_id) {
        let db = await database.db;
        let user_id = store.state.login.connectedUser.id;
        return new Promise((resolve) => {
            let transaction = db.transaction("infosProject", "readwrite");
            let request = transaction.objectStore('infosProject').index('company_id').openCursor(
                IDBKeyRange.only(company_id)
            );
            request.onsuccess = function (event) {
                var cursor = request.result;
                if (cursor) {
                    if (cursor.value.user_ids.length > 1) {
                        cursor.value.user_ids.splice(cursor.value.user_ids.indexOf(user_id), 1);
                        var res = cursor.update(cursor.value);
                        res.onsuccess = function (e) {
                            resolve(true);

                        }
                        res.onerror = function (e) {
                            resolve(false);

                        }
                    }
                    else {
                        let del = cursor.delete();
                        del.onsuccess = function () {
                            resolve(true)
                        };
                    }
                    cursor.continue();
                }
            }

            request.onerror = function (event) {
                resolve(false);
            }
        });
    },
}
