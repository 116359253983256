<template>
    <div class="pwa-screen">
        <a-layout>
            <a-layout-header class="layout-header" :class="$mq">
                <Header
                    :displayLogoButton="true"
                    :displayBackButton="true"
                    :displaySearchBar="true"
                    :displayTeamButton="true"
                    :displayOfflineButton="true"
                    :displayModuleButton="true"
                    :title="getProject.lib"
                />
            </a-layout-header>
            <a-layout-content>
                <AllFilesSelection />
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
import Header from "./applayout/Header";
import AllFilesSelection from "./fileselection/AllFilesSelection";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "FileModule",
    components: {
        Header,
        AllFilesSelection,
    },
    data() {
        return {
            collapsed: true,
        };
    },
    async created() {
        if (!this.$route.params.alreadyLoaded && this.getLastRouteName != "Viewer") {
            this.createLoader([20, 80]).then(() => {
                this.updateFilesAndDirectoryToDisplay({
                    selectedKeyDirectory: this.getSelectedKeyDirectory,
                });
            });
        }
    },
    methods: {
        ...mapActions(["updateFilesAndDirectoryToDisplay", "createLoader"]),
    },
    computed: {
        ...mapGetters(["getOnline", "getLastRouteName" ]),
        ...mapGetters("project", ["getProject"]),
        ...mapGetters("directories", ["getSelectedKeyDirectory"]),
    },
};
</script>



