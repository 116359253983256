import Rapport_db from "~/db_access/Rapport.js";
import File_db from "~/db_access/File.js";
import Rapport_API from "~/service/Rapport/Rapport.js";
import User_API from "~/service/Common/User";
import i18next from "../../../i18n/";
import Router from "~/src/router.js";
import { convertDateValidateCr, defineActionRapport } from "~/src/common.js";
const Antd = require("ant-design-vue");

import {
  each,
  map,
  filter,
  find,
  groupBy,
  max,
  maxBy,
  parseInt,
  tail,
  cloneDeep,
  first,
  pickBy,
  indexOf,
  findIndex,
} from "lodash";
import File_API from "../../../../service/Ged/File";

export default {
  refresh_display_reports({ dispatch, rootGetters }) {
    dispatch("display_reports_by_state", rootGetters.getDisplayState);
  },
  async display_reports_by_state(
    { dispatch, commit, rootGetters, getters },
    state = 1
  ) {
    let rapports = [];
    commit("CHANGE_SYNCHRO_EN_COURS", false);
    dispatch("changeDisplayState", state, { root: true });
    if (state == 1) {
      if (rootGetters.getOnline) {
        dispatch("display_online_validate_reports");
      } else {
        dispatch("display_offline_validate_reports");
      }
    } else {
      rapports = await Rapport_db.get_rapports_en_cours();
      let rapports_IC = filter(rapports, ["modele_id", 2]);
      if (rapports_IC.length > 0) {
        let entreprise_IC = await Rapport_db.get_entreprise_associated_with_modele(
          2
        );
        map(rapports_IC, (rapport) => {
          const cloned_information = rapport.header?.is_cloned
            ? " (duplication)"
            : "";
          let entreprise_rapport = find(entreprise_IC, [
            "rapport_id",
            rapport.id,
          ]);
          if (entreprise_rapport && entreprise_rapport.id != "") {
            if (entreprise_rapport.id == "new") {
              rapport.org_name =
                entreprise_rapport.nouvelle_entreprise.raison +
                cloned_information;
            } else {
              const org_linked = find(getters.get_entreprises_rapport, [
                "id",
                entreprise_rapport.id,
              ]);
              rapport.org_name = org_linked
                ? org_linked.text + cloned_information
                : "Entreprise introuvable" + cloned_information;
            }
          } else {
            rapport.org_name =
              "Aucune entreprise associée" + cloned_information;
          }
        });
      }
    }
    commit("UPDATE_RAPPORTS_TO_DISPLAY", rapports);
  },
  async display_online_validate_reports({ commit, dispatch, rootGetters }) {
    let project = rootGetters["project/getProject"];
    let company = rootGetters["company/getCompany"];
    let rapports = await Rapport_API.get_list_compte_rendus_valides(
      project.id
    ).then(async (result) => {
      return await Promise.all(
        map(result, async (value, key) => {
          let cr = {
            ...value[0],
            lib: key,
            format: value[0].type,
            taille: value[0].size,
            date_add: convertDateValidateCr(value[0].date_add),
            company: company,
            project: project,
            module_file: "RAPPORT",
          };
          if (value.length > 1) {
            cr.children = await Promise.all(
              map(tail(value), async (val) => {
                let childrenCR = {
                  ...val,
                  lib: key,
                  taille: val.size,
                  format: val.type,
                  date_add: convertDateValidateCr(val.date_add),
                  company: company,
                  project: project,
                  module_file: "RAPPORT",
                };
                await defineActionRapport(childrenCR);
                return childrenCR;
              })
            );
          }
          await defineActionRapport(cr);
          return cr;
        })
      );
    });
    if (rootGetters.getDisplayState == 1) {
      commit("UPDATE_RAPPORTS_TO_DISPLAY", rapports);
    }
  },
  async display_offline_validate_reports({ commit, rootGetters }) {
    let rapports = await Rapport_db.get_rapports_offline();
    if (rootGetters.getDisplayState == 1) {
      commit("UPDATE_RAPPORTS_TO_DISPLAY", rapports);
    }
  },
  set_user_rapport({ commit, dispatch }, rapport) {
    commit("CHANGE_USER_RAPPORT", rapport);
    dispatch("change_sign_interface", false);
    dispatch("change_preview_interface", false);
    dispatch("change_send_interface", false);
  },
  async create_new_editing_report(
    { commit, dispatch, rootGetters, state, getters },
    { modele, from_last_report }
  ) {
    if (!state.create_report) {
      commit("CHANGE_CREATE_REPORT_IN_PROGRESS", true);
      let modele_rapport = JSON.parse(JSON.stringify(modele.detail_modele));
      let new_rapport = await Rapport_db.create_new_rapport(modele_rapport);
      commit("CHANGE_LAST_SYNCHRO", { date: "", success: false });
      commit("RESET_BLOCKED_SIGN_ERROR");
      await commit("CHANGE_LAST_RAPPORT_SECTIONS", modele.last_report_sections);
      if (from_last_report) {
        const sections = await dispatch("duplicate_from_last_report", {
          rapport: new_rapport,
          modele_rapport,
        });
        const rapport = {
          header: new_rapport.header,
          sections: sections,
        };
        await dispatch(
          "set_user_rapport_section_selected_with_values",
          rapport.sections[0]
        );
        await dispatch("set_user_rapport", rapport);
      } else {
        await dispatch(
          "set_user_rapport_section_selected_with_values",
          new_rapport.sections[0]
        );
        await dispatch("set_user_rapport", new_rapport);
      }
      commit("CHANGE_CREATE_REPORT_IN_PROGRESS", false);
    }
  },
  async duplicate_from_last_report(
    { getters, rootGetters },
    { rapport, modele_rapport }
  ) {
    return Promise.all(
      map(rapport.sections, async (section) => {
        if (modele_rapport.clonable && !section.clonable) {
          return section;
        }
        let last_section_name = getters.get_last_rapport_section_name(
          section.section_id
        );
        const section_values = await Promise.all(
          map(section.section_elements, async (element) => {
            let last_section = getters.get_last_rapport_section_element_value(
              section.section_id,
              element.element_id
            );
            if (section.element_type === "CHOICE_ACTORS_MAIL") {
              last_section = filter(last_section, (value) => {
                return find(rootGetters["project/getAllActors"], [
                  "id",
                  value.id,
                ]);
              });
            }

            return {
              element_id: element.element_id,
              values: last_section,
            };
          })
        );
        section = {
          ...section,
          section_name: last_section_name
            ? last_section_name
            : section.section_name,
          section_elements: map(section.section_elements, (element) => {
            const value = find(section_values, [
              "element_id",
              element.element_id,
            ]);
            return {
              ...element,
              element_values:
                value.values.length > 0
                  ? JSON.parse(JSON.stringify(value.values))
                  : element.element_values,
            };
          }),
        };
        Rapport_db.set_value_by_section(section);

        return section;
      })
    );
  },
  async load_editing_report(
    { commit, dispatch, rootGetters },
    { modele_id, rapport_id }
  ) {
    let currentRapport = await Rapport_db.get_edit_rapport(
      rootGetters["project/getProjectId"],
      modele_id,
      rapport_id
    );
    let modeles = await Rapport_db.get_all_modeles();
    let modele = find(modeles, ["id", modele_id]);
    let synchro = true;
    if (
      !currentRapport.header.synchronised &&
      filter(currentRapport.sections, ["synchronised", false]).length > 0
    )
      synchro = false;

    commit("CHANGE_LAST_SYNCHRO", { date: "", success: synchro });
    dispatch("go_back_edition");
    if (modele.title == "IC") {
      let entreprises_disponibles = await Rapport_db.get_entreprise_associated_with_modele(
        modele_id
      );
      commit("CHANGE_AVAILABLE_ENTREPRISE_IC", entreprises_disponibles);
    }
    if (rootGetters.getOnline) {
      // await Project_db.put_project_actors(rootGetters['project/getAllActors']);
    }
    commit("RESET_BLOCKED_SIGN_ERROR");
    await dispatch(
      "set_user_rapport_section_selected_with_values",
      currentRapport.sections[0]
    );
    await dispatch("set_user_rapport", currentRapport);
    if (modele) {
      await commit("CHANGE_LAST_RAPPORT_SECTIONS", modele.last_report_sections);
    }
  },

  async delete_editing_report(
    { dispatch },
    { project_id, modele_id, rapport_id }
  ) {
    let currentRapport = await Rapport_db.get_edit_rapport(
      project_id,
      modele_id,
      rapport_id
    );
    if (currentRapport) {
      map(currentRapport.sections, (section) => {
        Rapport_db.delete_section(section);
      });
      // Modifier le header au statut 999
      if (typeof currentRapport.header.rapport_id != "string") {
        currentRapport.header.status_id = 999;
        await dispatch("save_section", { section: currentRapport.header });
        return true;
      } else {
        Rapport_db.delete_section(currentRapport.header);
        return true;
      }
    }
    return false;
  },

  async update_offline_reports({ dispatch, getters, rootGetters }) {
    //Récupération de tous les rapports validés en DB
    let rapports_db = await File_db.get_all_files_user_by_module("RAPPORT");
    // On regroupe les fichier par projet
    let rapports_db_by_project = groupBy(rapports_db, "project.id");
    // On parcour le tableau de projet
    await Promise.all(
      map(rapports_db_by_project, (rapports_db, project_id) => {
        // On récupère la liste des rapports validés du projet sur le serveur
        Rapport_API.get_list_compte_rendus_valides(project_id).then(
          async (result) => {
            // On parcours les rapports en DB pour le projet
            for (var i = 0; i < rapports_db.length; i++) {
              let rapport_db = rapports_db[i];
              if (rapport_db.lib != "IC") {
                // Les rapports IC sont exclus car ils n'ont pas de mise a jour de version
                // On recherche le rapport validé coté serveur ayant le même lib que celui en DB
                let rapport_validate = find(result, (value, key) => {
                  if (key == rapport_db.lib) return value;
                })[0];
                // Si le rapport validé serveur n'a pas le meme id que celui en Db alors on remplace en mettant à jour les informations necessaires
                if (rapport_validate.id != rapport_db.id) {
                  rapport_validate = {
                    ...rapport_db,
                    id: rapport_validate.id,
                    taille: rapport_validate.size,
                    date_add: convertDateValidateCr(rapport_validate.date_add),
                  };
                  File_API.dl(rapport_validate).then(async function(file_data) {
                    // Vas ajouter en DB le nouveau rapport validé
                    await File_db.update_user_file(rapport_db); // Va supprimer l'ancien rapport en DB
                  });
                }
              }
            }
          }
        );
      })
    );
  },
  async update_editing_reports_from_server_to_local({ dispatch, rootGetters }) {
    //On recupére tous les modeles de rapport
    let current_modeles = await Rapport_db.get_all_modeles();
    let all_id_rapports = [];
    // Pour chaque modele nous allons récupéré la date de dernier update du rapport éventuellement en édition
    let synchroObject = await Promise.all(
      map(current_modeles, async (modele) => {
        // On prépare notre objet regroupant les rapports en cours d'édition pour le modele testé
        let modele_with_reports_associated = {
          modele_id: modele.id,
          rapports: [],
        };
        // On récupère les rapport en cours d'édition qui sont associés au modele
        let currents_rapports = await Rapport_db.get_edit_rapport(
          rootGetters["project/getProjectId"],
          modele.id
        );
        // Pour chaque rapport en cours pour le modele testé, on va déterminé la date de dernière mise à jour
        await Promise.all(
          map(currents_rapports, (rapport) => {
            all_id_rapports.push(rapport.header.rapport_id);
            if (typeof rapport.header.rapport_id == "string") return;
            //date derniere mise a jour = max (date_update du header et de toutes les sections du rapport)
            let date_update = false;
            //Récupération date update header
            date_update = rapport.header.date_update;
            //Récupération date update la plus recente des section
            let most_recent_section = maxBy(rapport.sections, "date_update");
            //Comparaison date header et sections
            if (most_recent_section) {
              date_update = max([date_update, most_recent_section.date_update]);
            }
            // On push le rapport dans notre oubjet regroupant les rapports en cours d'édition pour le modele testé
            modele_with_reports_associated.rapports.push({
              rapport_id: rapport.header.rapport_id,
              date_update: date_update,
            });
          })
        );
        // on retourne notre objet
        return modele_with_reports_associated;
      })
    );

    // La route API renvoie toutes les sections à mettre à jour par rapport
    Rapport_API.synchronise_unvalidate_report(synchroObject).then((result) => {
      // On parcours tous les tableaux dans result.reports (un tableau = section d'un rapport)
      map(result.reports, (rapport) => {
        //Pour chaque section du rapport, on save la section
        map(rapport, (section) => {
          dispatch("save_section", {
            section,
            synchronised: true,
            new_date_update: false,
          });
        });
      });
      // On détermine et supprime les rapports en cours d'édition à supprimer parce qu'ils ont été supprimé ou validés sur un autre terminal
      map(result.delete_reports, (delete_reports_by_modele) => {
        map(delete_reports_by_modele.reports_id, (rapport_id) => {
          if (all_id_rapports.includes(rapport_id))
            // Necessaire car la liste d'id a supprimer dans la réponse peut être très long
            dispatch("delete_editing_report", {
              project_id: rootGetters["project/getProjectId"],
              modele_id: delete_reports_by_modele.modele_id,
              rapport_id: rapport_id,
            });
        });
      });
    });
  },

  async set_report_in_progress({ commit, getters }, data) {
    let in_progress = [...getters.get_in_progress];
    let found = false;
    let datetime = new Date().getTime();
    map(in_progress, (project) => {
      if (project.project_id == data.project_id) {
        if (!project.rapport.includes(data.id)) {
          project.rapport.push(data.id);
          if (!project.dt_start) {
            project.dt_start = [];
          }
          project.dt_start[data.id] = datetime;
        }
        found = true;
      }
    });
    if (!found) {
      let dt_object = {};
      dt_object[data.id] = datetime;
      in_progress.push({
        project_id: data.project_id,
        rapport: [data.id],
        dt_start: dt_object,
      });
    }
    commit("CHANGE_IN_PROGRESS", in_progress);
  },

  async remove_report_in_progress({ commit }, { project_id, id }) {
    commit("REMOVE_IN_PROGRESS", {
      project_id,
      id,
    });
  },
  async update_editing_reports_from_local_to_server({
    commit,
    getters,
    dispatch,
    rootGetters,
  }) {
    if (rootGetters.getOnline) {
      commit("CHANGE_SYNCHRO_EN_COURS", true);
      await Rapport_db.get_unsynchronised_sections().then(
        async (unsynchronised_sections) => {
          let unsynchronised_sections_project = groupBy(
            unsynchronised_sections,
            "project_id"
          );
          await Promise.all(
            map(
              unsynchronised_sections_project,
              async (values_by_project, project_key) => {
                let rapports = groupBy(values_by_project, "rapport_id");
                await Promise.all(
                  map(rapports, async (value, key) => {
                    if (
                      !getters.report_is_syncing(
                        value[0].project_id,
                        value[0].rapport_id
                      )
                    ) {
                      dispatch("set_report_in_progress", {
                        project_id: value[0].project_id,
                        id: value[0].rapport_id,
                      });
                      try {
                        await Promise.all(
                          map(value, async (unsynchronised_section) => {
                            return Rapport_db.set_section_pending(
                              unsynchronised_section.modele.id,
                              unsynchronised_section.rapport_id,
                              unsynchronised_section.section_id,
                              parseInt(project_key)
                            );
                          })
                        );
                        let result = await Rapport_API.synchronise_rapport({
                          [key]: value,
                        });
                        console.log(result);
                        let header_rapport_to_send = false;
                        let rapport_id = result.response_status[0].rapport_id;
                        let modele_id = result.response_status[0].modele_id;
                        let success_synchro_rapport = true;
                        await Promise.all(
                          map(
                            result.response_status,
                            async (section_synchro_result) => {
                              // On met a jour l'état de synchronisation global du rapport
                              if (!section_synchro_result.success) {
                                success_synchro_rapport = false;
                              }
                              //On cherche dans les sections a synchronisé, laquelle a le resultat section_synchro_result
                              let section_synchro = find(value, [
                                "section_id",
                                section_synchro_result.section_id,
                              ]);
                              // On va récupéré la section avec toute ses informations en base.
                              let section_synchro_detail = await Rapport_db.get_section_by_key(
                                section_synchro.project_id,
                                modele_id,
                                section_synchro.rapport_id,
                                section_synchro_result.section_id
                              );
                              // Si la section est correctement synchronisé, nous regardons si ce n'est pas un header à supprimer (status_id=999).
                              let new_rapport_id = false;
                              if (section_synchro_result.success) {
                                if (section_synchro_detail.status_id == 999) {
                                  await Rapport_db.delete_section(
                                    section_synchro_detail
                                  );
                                  return;
                                }
                                if (
                                  typeof section_synchro_detail.rapport_id ==
                                  "string"
                                ) {
                                  new_rapport_id = true;
                                  await Rapport_db.delete_section(
                                    section_synchro_detail
                                  );
                                }
                              }
                              // On réattribut le rapport id a la section. Celui ci pourrait avoir changer si c'etait un id "new..."
                              section_synchro_detail.rapport_id = rapport_id;
                              // Si on est a un header a envoyé (status_id=3), on le met de coté pour validé plus tard sinon on enregistre la section suivant son état de synchronisation
                              if (
                                section_synchro_detail.section_id == 0 &&
                                section_synchro_detail.status_id == 3
                              ) {
                                header_rapport_to_send = section_synchro_detail;
                                if (new_rapport_id) {
                                  await dispatch("save_section", {
                                    section: section_synchro_detail,
                                    synchronised: false,
                                    new_date_update: false,
                                  });
                                }
                              } else {
                                if (section_synchro_detail.pending) {
                                  await dispatch("save_section", {
                                    section: section_synchro_detail,
                                    synchronised:
                                      section_synchro_result.success,
                                    new_date_update: false,
                                  });
                                } else {
                                  success_synchro_rapport = false;
                                  if (new_rapport_id) {
                                    await dispatch("save_section", {
                                      section: section_synchro_detail,
                                      synchronised: success_synchro_rapport,
                                      new_date_update: false,
                                    });
                                  }
                                }
                              }
                              // Si la synchronisation est un succes et que le statut est 999 = suppression demandé ou de type string dans le cas d'un id "new...", on supprime la section
                            }
                          )
                        );
                        //Si on a un header à envoyé pour le rapport synchronisé et que la synchro est ok, on met a jour son statut et on l'enregistre.
                        if (header_rapport_to_send && success_synchro_rapport) {
                          header_rapport_to_send.status_id = 4; // Status_id détecté comme rapport envoyé.
                          await dispatch("save_section", {
                            section: header_rapport_to_send,
                            synchronised: true,
                            new_date_update: false,
                          });
                        }
                        // Si le rapport synchronisé est le rapport edité par l'utilisateur, on met à jour le last_synchro et si besoin l'id du rapport en cours d'édition s'il était nouveau.
                        if (key == getters.get_header_rapport.rapport_id) {
                          commit("CHANGE_LAST_SYNCHRO", {
                            date: "",
                            success: success_synchro_rapport,
                          });
                          if (typeof key == "string") {
                            commit("CHANGE_USER_RAPPORT_ID", rapport_id);
                          }
                        }
                        if (
                          map(getters.get_rapports_to_display, "id").includes(
                            key
                          ) &&
                          typeof key == "string" &&
                          rootGetters.getDisplayState == 2
                        ) {
                          commit("CHANGE_ID_IN_LIST_RAPPORT_DISPLAY", {
                            old: key,
                            new_id: rapport_id,
                          });
                        }
                      } catch (e) {
                        console.log(e);
                      } finally {
                        dispatch("remove_report_in_progress", {
                          project_id: value[0].project_id,
                          id: value[0].rapport_id,
                        });
                      }
                    }
                  })
                );
              }
            )
          );
          commit("CHANGE_SYNCHRO_EN_COURS", false);
        }
      );
    }
  },

  async update_editing_report_from_local_to_server(
    { commit, getters, dispatch, rootGetters },
    { with_notification = false, force = false }
  ) {
    let success_synchro_rapport = false;
    let error_sections = [];
    let key = getters.get_header_rapport?.rapport_id;
    let id_project = getters.get_header_rapport.project_id;
    if (
      rootGetters.getOnline &&
      key &&
      !getters.report_is_syncing(rootGetters["project/getProjectId"], key)
    ) {
      dispatch("set_report_in_progress", { project_id: id_project, id: key });
      try {
        commit("CHANGE_SYNCHRO_EN_COURS", true);
        success_synchro_rapport = true;
        let report_to_save = await Rapport_db.get_edit_rapport(
          rootGetters["project/getProjectId"],
          getters.get_header_rapport.modele.id,
          key,
          false
        );
        let sections_to_synchro = {};
        sections_to_synchro = filter(report_to_save.sections, [
          "synchronised",
          false,
        ]);
        if (!report_to_save.header.synchronised) {
          sections_to_synchro.push(report_to_save.header);
        }
        let rapport_id = "";
        if (sections_to_synchro.length > 0) {
          await Promise.all(
            map(sections_to_synchro, async (unsynchronised_section) => {
              return Rapport_db.set_section_pending(
                unsynchronised_section.modele.id,
                unsynchronised_section.rapport_id,
                unsynchronised_section.section_id
              );
            })
          );
          const result = await Rapport_API.synchronise_rapport(
            { [report_to_save.header.rapport_id]: sections_to_synchro },
            force
          );
          if (result) {
            await Promise.all(
              map(result.response_status, async (section_synchro) => {
                let start_section = find(sections_to_synchro, [
                  "section_id",
                  section_synchro.section_id,
                ]);
                start_section = await Rapport_db.get_section_by_key(
                  start_section.project_id,
                  start_section.modele.id,
                  start_section.rapport_id,
                  section_synchro.section_id
                );
                let oldSection = JSON.parse(JSON.stringify(start_section));
                start_section.rapport_id = section_synchro.rapport_id;
                rapport_id = section_synchro.rapport_id;
                if (!section_synchro.success) {
                  success_synchro_rapport = false;
                  error_sections = [section_synchro.title];
                  // if(section_synchro.has_recent_upd) {
                  //     commit('CHANGE_FORCE_SYNC_MODAL', {new_val: true});
                  //     with_notification = false;
                  // }
                }
                if (start_section.pending) {
                  await dispatch("save_section", {
                    section: start_section,
                    synchronised: section_synchro.success,
                    new_date_update: false,
                  });
                }
                if (section_synchro.success) {
                  if (
                    oldSection.status_id == 999 ||
                    typeof oldSection.rapport_id == "string"
                  ) {
                    await Rapport_db.delete_section(oldSection);
                  }
                }
              })
            );
          }
        }
        if (with_notification) {
          if (success_synchro_rapport) {
            Antd.notification["success"]({
              message: "Synchronisation manuelle réussie",
              description:
                "La synchronisation manuelle s'est correctement déroulée",
              duration: 1,
            });
          } else {
            let string =
              "<div> La synchronisation manuelle a rencontrée une erreur sur les sections : <ul>";
            await Promise.all(
              map(filter(error_sections), (value) => {
                string += "<li>" + value + "</li> ";
              })
            );
            string += "</ul></div>";
            Antd.notification["error"]({
              message: "Synchronisation manuelle échouée",
              description: (h) => {
                return h("div", { domProps: { innerHTML: string } });
              },
              duration: 1,
            });
          }
        }
        commit("CHANGE_LAST_SYNCHRO", {
          date: "",
          success: success_synchro_rapport,
        });
        if (typeof key == "string") {
          commit("CHANGE_USER_RAPPORT_ID", rapport_id);
          if (
            map(getters.get_rapports_to_display, ["id"]).includes(key) &&
            rootGetters.getDisplayState == 2
          ) {
            commit("CHANGE_ID_IN_LIST_RAPPORT_DISPLAY", {
              old: key,
              new_id: rapport_id,
            });
          }
        }
        commit("CHANGE_SYNCHRO_EN_COURS", false);
      } catch (e) {
        console.log(e);
      } finally {
        // if (force) {
        //     commit('CHANGE_FORCE_SYNC_MODAL', {new_val: false});
        // }
        dispatch('remove_report_in_progress', { project_id: id_project, id: key });
      }
    }

    return success_synchro_rapport;
  },
  async send_reports({ commit, dispatch, getters, rootGetters }) {
    const result = await User_API.can_mailing();
    if (result.can_mailing) {
      let rapports_to_send = await Rapport_db.get_rapport_to_send();
      for (let rapport of rapports_to_send) {
        const result = await Rapport_API.send_report(
          rapport.project_id,
          rapport.id,
          rapport.text_mail,
          rapport.object_mail
        );
        if (result.success) {
          await dispatch("delete_editing_report", {
            project_id: rapport.project_id,
            modele_id: rapport.modele_id,
            rapport_id: rapport.id,
          });
          await Rapport_db.set_last_report_modeles(
            rapport.project_id,
            result.last_report
          );
          Antd.notification["success"]({
            message: "Rapport envoyé en différé",
            description:
              "Le rapport " +
              rapport.send_infos.type +
              " du projet " +
              rapport.send_infos.project +
              " a été validé et envoyé avec succès",
            duration: 5,
          });
        }
        if (rootGetters["project/getProjectId"] == rapport.project_id) {
          dispatch("refresh_display_reports");
        }
      }
    }
  },

  change_last_synchro(commit, value) {
    commit("CHANGE_LAST_SYNCHRO", value);
  },

  async save_section(
    { commit, dispatch },
    { section, synchronised = false, new_date_update = true }
  ) {
    if (!section.rapport_id || section.rapport_id == "") {
      return;
    }
    const section_exists = await Rapport_db.get_section_by_key(
      section.project_id,
      section.modele.id,
      section.rapport_id,
      section.section_id
    );
    let has_heavy_data = false;
    each(section.section_elements, (section_element) => {
      if (section_element.element_type === "IMAGE") {
        has_heavy_data = true;
      }
    });
    if (has_heavy_data && section_exists) {
      await Promise.all(
        map(section.section_elements, async (section_element) => {
          if (section_element.element_type !== "IMAGE") {
            await dispatch("save_element_section", {
              section_id: section.section_id,
              element_id: section_element.element_id,
              element_values: section_element.element_values,
              section_name: section.section_name,
              project_id: section.project_id,
            });
          }
        })
      );
      if (synchronised) {
        Rapport_db.set_section_synchronised(
          section.modele.id,
          section.rapport_id,
          section.section_id,
          section.project_id
        );
      }
    } else {
      Rapport_db.set_value_by_section(section, synchronised, new_date_update);
    }
    commit("CHANGE_LAST_SYNCHRO", { date: "", success: false });
  },

  async save_element_section(
    { rootState, getters, commit },
    {
      section_id,
      element_id,
      element_values,
      section_name = null,
      project_id = null,
    }
  ) {
    await Rapport_db.save_element_values(
      getters.get_header_rapport.modele.id,
      getters.get_header_rapport.rapport_id,
      section_id,
      element_id,
      element_values,
      section_name,
      project_id ?? rootState.project.project.id
    );
    commit("CHANGE_LAST_SYNCHRO", { date: "", success: false });
  },

  change_title_rapport_user({ commit, dispatch }, value) {
    if (value) {
      commit("CHANGE_USER_RAPPORT_TITLE", value);
      dispatch("save_header");
    }
  },

  save_header({ getters, commit }, synchronised = false) {
    Rapport_db.set_value_by_section(getters.get_header_rapport, synchronised);
    commit("CHANGE_LAST_SYNCHRO", { date: "", success: false });
  },

  async set_user_rapport_section_selected_with_values({ commit }, section) {
    commit("CHANGE_USER_RAPPORT_SECTION_SELECTED", section);
  },

  async update_signature({ commit, getters, dispatch }, { type, value, img }) {
    commit("UPDATE_SIGNATURE", { type, value, img });
    let signatureOk = true;
    await Promise.all(
      map(getters.get_header_rapport.signatures, (signature) => {
        if (signature.mandatory && signature.value == "") {
          signatureOk = false;
        }
      })
    );
    dispatch("change_user_rapport_statut", { status_id: signatureOk ? 2 : 1 });
  },

  add_blocked_sign_error({ commit }, error) {
    commit("ADD_BLOCKED_SIGN_ERROR", error);
  },
  remove_blocked_sign_error({ commit }, id_error) {
    commit("REMOVE_BLOCKED_SIGN_ERROR", id_error);
  },

  async switch_locked_report({ commit, getters, dispatch }) {
    if (getters.get_header_rapport.status_id >= 1) {
      if (getters.is_signed_report) {
        await commit("RESET_VISAS");
        Antd.notification["warning"]({
          message: "Visas supprimés",
          description:
            "L'ouverture à modification de la section a supprimé les visas existants.",
        });
      }
      dispatch("change_user_rapport_statut", { status_id: 0 });
    } else {
      dispatch("change_user_rapport_statut", { status_id: 1 });
    }
  },

  change_user_rapport_statut(
    { commit, dispatch, rootGetters },
    { status_id, text_mail = false, object_mail = false, send_infos = false }
  ) {
    commit("CHANGE_USER_RAPPORT_STATUT", status_id);
    if (text_mail) {
      commit("CHANGE_USER_RAPPORT_TEXT_MAIL", text_mail);
    }
    if (object_mail) {
      commit("CHANGE_USER_RAPPORT_OBJECT_MAIL", object_mail);
    }
    if (send_infos) {
      commit("CHANGE_USER_RAPPORT_SEND_INFOS", send_infos);
    }
    dispatch("save_header");
  },
  change_sign_interface({ commit }, value) {
    commit("CHANGE_SIGN_INTERFACE", value);
  },
  change_preview_interface({ commit }, value) {
    commit("CHANGE_PREVIEW_INTERFACE", value);
  },
  change_send_interface({ commit }, value) {
    commit("CHANGE_SEND_INTERFACE", value);
  },

  go_back_edition({ commit }) {
    commit("CHANGE_SIGN_INTERFACE", false);
    commit("CHANGE_PREVIEW_INTERFACE", false);
    commit("CHANGE_SEND_INTERFACE", false);
  },

  delete_offline_validate_rapport_to_display({ commit }, file) {
    commit("DELETE_OFFLINE_VALIDATE_RAPPORT_TO_DISPLAY", file);
  },
  async clone_report(
    { state, commit, dispatch },
    { project_id, modele_id, rapport_id }
  ) {
    if (state.create_report) {
      return false;
    }
    commit("CHANGE_CREATE_REPORT_IN_PROGRESS", true);
    let modeles = await Rapport_db.get_all_modeles();
    let modele = find(modeles, ["id", modele_id]);
    let modele_rapport = JSON.parse(JSON.stringify(modele.detail_modele));
    let new_rapport = await Rapport_db.create_new_rapport(modele_rapport, true);
    commit("RESET_BLOCKED_SIGN_ERROR");
    await Promise.all(
      map(new_rapport.sections, async (section) => {
        if (section.clonable) {
          const section_source = await Rapport_db.get_section_by_key(
            project_id,
            modele_id,
            rapport_id,
            section.section_id
          );
          if (section_source) {
            section = {
              ...section,
              section_elements: section_source.section_elements,
              section_name: section_source.section_name,
            };
            Rapport_db.set_value_by_section(section);
          }
        }
      })
    );
    commit("CHANGE_CREATE_REPORT_IN_PROGRESS", false);
  },
  async set_frozen_reports({ state, rootState, commit }, reset = false) {
    if (reset) {
      commit("remove_frozen_reports");
    }
    let curr_dt = new Date().getTime();
    let cloned_in_progress = cloneDeep(state.in_progress);
    let project = first(
      filter(cloned_in_progress, (project) => {
        return project.project_id === rootState.project.project.id;
      })
    );
    if (project) {
      let rapports = await Rapport_db.get_rapports_en_cours();
      project.dt_start = pickBy(project.dt_start, (datetime) => {
        return curr_dt - datetime > 10 * 60 * 1000;
      });
      project.rapport = filter(project.rapport, (report_id) => {
        const rapport_exists = findIndex(rapports, { id: report_id });
        if (rapport_exists !== -1) {
          if (
            report_id in project.dt_start &&
            indexOf(state.frozen_reports, report_id) < 0
          ) {
            commit("set_frozen_report", report_id);
            commit("set_projects_in_progress", project.project_id);
          }
        } else {
          commit("REMOVE_IN_PROGRESS", {
            project_id: project.project_id,
            id: report_id,
          });
          commit("remove_frozen_report_by_id", report_id);
        }
      });
    }
  },
  async set_projects_in_progress({ state, commit }) {
    let curr_dt = new Date().getTime();
    let cloned_in_progress = cloneDeep(state.in_progress);
    cloned_in_progress.forEach(async (project) => {
      let rapports = await Rapport_db.get_rapports_en_cours(project.project_id);
      project.dt_start = pickBy(project.dt_start, (datetime) => {
        return curr_dt - datetime > 10 * 60 * 1000;
      });
      project.rapport = filter(project.rapport, (report_id) => {
        const rapport_exists = findIndex(rapports, { id: report_id });
        if (rapport_exists !== -1) {
          if (report_id in project.dt_start) {
            commit("set_projects_in_progress", project.project_id);
          }
        } else {
          commit("remove_project_in_progress_by_id", project.project_id);
        }
      });
    });
  },
  async display_frozen_rapport_notif({ state, commit, getters, rootGetters }) {
    const key = `frozen_notif`;
    const frozen_reports = state.frozen_reports;
    const last_closed_dt =
      getters.get_frozen_notif_closed[rootGetters["project/getProject"].id] ??
      false;
    const current_dt = new Date().getTime();
    if (
      frozen_reports?.length > 0 &&
      Router.currentRoute.name === "Rapport" &&
      (!last_closed_dt || current_dt - last_closed_dt > 60 * 60 * 1000)
    ) {
      Antd.notification.open({
        type: "warning",
        message: i18next.t("fileselection.frozen_notif.title"),
        description: i18next.t("fileselection.frozen_notif.desc"),
        placement: "bottomRight",
        duration: 0,
        btn: (h) => {
          return h(
            "a-button",
            {
              props: {
                type: "primary",
                size: "large",
              },
              on: {
                click: () => {
                  frozen_reports.forEach((rapport_id) => {
                    commit("REMOVE_IN_PROGRESS", {
                      project_id: rootGetters["project/getProject"].id,
                      id: rapport_id,
                    });
                    commit("remove_frozen_report_by_id", rapport_id);
                    commit(
                      "remove_project_in_progress_by_id",
                      rootGetters["project/getProject"].id
                    );
                  });
                  Antd.message.success(
                    i18next.t("fileselection.frozen_notif.msg_plural")
                  );
                  Antd.notification.close(key);
                },
              },
            },
            i18next.t("fileselection.frozen_notif.btn")
          );
        },
        key,
        onClose: (e) => {
          commit("UPDATE_FROZEN_NOTIF_CLOSED", {
            project_id: rootGetters["project/getProject"].id,
          });
        },
        style: {
          width: "600px",
          marginLeft: `${335 - 600}px`,
        },
      });
    }
  },
};
