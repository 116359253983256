<template>
    <div>
        <div v-if="type === 'PDF'">
            <img
                src="../../assets/TypeFilesIcon/pdf_120.png"
                :style="styleImage"
            />
        </div>
        <div v-else-if="type === 'RÉDACTION'">
            <img
                src="../../assets/TypeFilesIcon/edit.png"
                :style="styleImage"
            />
        </div>
        <div v-else-if="type === 'SIGNATURE'">
            <img
                src="../../assets/TypeFilesIcon/sign.png"
                :style="styleImage"
            />
        </div>
        <div v-else-if="type === 'ENVOI'">
            <img
                src="../../assets/TypeFilesIcon/send.png"
                :style="styleImage"
            />
        </div>
        <div v-else-if="type === 'RÉCAPITULATIF'">
            <img
                src="../../assets/TypeFilesIcon/send.png"
                :style="styleImage"
            />
        </div>
        <div v-else-if="type === 'BLOQUER'">
            <img
                src="../../assets/TypeFilesIcon/cadenas.png"
                :style="styleImage"
            />
        </div>
        <div v-else>
            <img
                src="../../assets/TypeFilesIcon/unknown_120.png"
                :style="styleImage"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: "IconeDocRenderer",
    props: ["type", "styleImage"],
};
</script>


