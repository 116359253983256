import { getNodeAndPathOfDirectory } from '~/src/common'


export default {
  changeSelectedDirectory({ commit }, directory) {
    commit("CHANGE_SELECTED_DIRECTORY", directory);
  },
  setCurrentPath({ commit }, currentPath) {
    commit('CHANGE_CURRENT_PATH', currentPath);
  },
  async updateDirectoriesToDisplay({ commit, dispatch, getters, rootGetters }) {
    console.log("updateDirectories")
    let loaderLinked = await dispatch("getNewLoaderAvailable", null, { root: true });
    let directoriesStandardToDisplay = [];
    let directoriesToVisaToDisplay = [];
    let directoriesLocalToDisplay = [];
    //Pour l'affichage actuel
    let childrensWithDisplayState = [];
    if (getters.getSelectedDirectory !== undefined && getters.getSelectedDirectory !== "") {
      getters.getSelectedDirectory.children.forEach((children) => {
        childrensWithDisplayState.push({
          key: children.key,
          directory: children
        });
      });
      await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 25 }, { root: true });
      switch (parseInt(rootGetters.getDisplayState)) {
        case 1:
          directoriesStandardToDisplay = childrensWithDisplayState;
          break;
        case 2:
          directoriesToVisaToDisplay = childrensWithDisplayState;
          break;
        case 3:
          directoriesLocalToDisplay = childrensWithDisplayState;
          break;

        default:
          break;
      }
      await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 30 }, { root: true });
      //Pour les autres cas : 
      let cpt = 0;
      if (parseInt(rootGetters.getDisplayState) !== 1 && rootGetters.getOnline) {
        let parentDirectoryStandard = await getNodeAndPathOfDirectory(
          rootGetters["arborescence/getArboStandardToDisplay"][0],
          getters.getSelectedKeyDirectory
        );
        if (parentDirectoryStandard.node !== '') {
          parentDirectoryStandard.node.children.forEach((children) => {
            directoriesStandardToDisplay.push({
              key: children.key,
              directory: children
            });
          });
        }
        cpt++;
        await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 30 + cpt * 20 }, { root: true });
      }
      if (parseInt(rootGetters.getDisplayState) !== 2 && rootGetters.getOnline) {
        let parentDirectoryToVisa = await getNodeAndPathOfDirectory(
          rootGetters["arborescence/getArboToVisaToDisplay"][0],
          getters.getSelectedKeyDirectory
        );
        if (parentDirectoryToVisa.node !== '') {
          parentDirectoryToVisa.node.children.forEach((children) => {
            directoriesToVisaToDisplay.push({
              key: children.key,
              directory: children
            });
          });
        }
        cpt++;
        await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 30 + cpt * 20 }, { root: true });
      }
      if (parseInt(rootGetters.getDisplayState) !== 3) {
        let parentDirectoryLocal = await getNodeAndPathOfDirectory(
          rootGetters["arborescence/getArboLocalToDisplay"][0],
          getters.getSelectedDirectory.key
        );
        if (parentDirectoryLocal.node !== '') {
          parentDirectoryLocal.node.children.forEach((children) => {
            directoriesLocalToDisplay.push({
              key: children.key,
              directory: children
            });
          });
        }
        cpt++;
        await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 30 + cpt * 20 }, { root: true });
      }
    }
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 90 }, { root: true });
    commit('UPDATE_DIRECTORIES_TO_DISPLAY',
      {
        directoriesStandardToDisplay: directoriesStandardToDisplay,
        directoriesToVisaToDisplay: directoriesToVisaToDisplay,
        directoriesLocalToDisplay: directoriesLocalToDisplay
      })
    await dispatch("changeLoadingPercent", { loaderLinked, loadingPercent: 100 }, { root: true });
  },
  
  resetDirectoriesToDisplay({ commit }) {
    commit('UPDATE_DIRECTORIES_TO_DISPLAY',
      {
        directoriesStandardToDisplay: [],
        directoriesToVisaToDisplay: [],
        directoriesLocalToDisplay: []
      })
  }
};
