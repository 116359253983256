
import db_param from "~/constants/DataBase";
import store from "./store/";


export const database = {
    'init': init(),
    'db': get_db()
}

function init() {
    console.log('init_db');
    return new Promise((resolve) => {
        let request = window.indexedDB.open(db_param.name, db_param.version);
        request.onerror = function (event) {
            console.log(request.error);
        };
        request.onblocked = function(event) {
            store.dispatch("changeUpdate",{type:'close'})
        };

        request.onupgradeneeded = function (event) {
            let db = request.result;
            if (event.oldVersion < 1) {
                console.log("Upgrade version database 1")
                let objectStore = db.createObjectStore("document", { keyPath: ['company.id','project.id','id'] });
                objectStore.createIndex("module_st_project_id", ["company.id","project.id","module_file"], { unique: false });

                objectStore = db.createObjectStore("arbo", { keyPath: "st_project_id" });
                objectStore.createIndex("st_project_id", ["st_id","project_id"], { unique: false });

                db.createObjectStore("favoriteProject", { keyPath: ["company_id","project_id","user_id"] });

                objectStore = db.createObjectStore("userCodes", { keyPath: "user_id" });
                objectStore.createIndex("user", "user", { unique: false });
            
                objectStore = db.createObjectStore("modificationRapports", { keyPath: ["project_id","modele.id","rapport_id","section_id"]});
                objectStore.createIndex("project_modele_id", ["project_id","modele.id"], { unique: false });
                objectStore.createIndex("project_id", "project_id", { unique: false });

                objectStore = db.createObjectStore("chunks", { keyPath: "chunk_id", autoIncrement:true});
                objectStore.createIndex("data", "data", { unique: false });

                objectStore = db.createObjectStore("urn", { keyPath: "urn_id", autoIncrement:true});
                objectStore.createIndex("doc_id", "doc_id", { unique: false });
                objectStore.createIndex("data", "data", { unique: false });
                
                objectStore = db.createObjectStore("infosProject", { keyPath: "project_id"});
                objectStore.createIndex("company_id", "company.id", { unique: false });
            }
            if (event.oldVersion < 2) {
                console.log("Upgrade version database 2")
                db.createObjectStore("userMailsContent", { keyPath: ["user_id","modele_id"]});
            }

            
            console.log('database updated');
            resolve();
        };
    });
}

function get_db() {
    return new Promise((resolve) => {
        let request = window.indexedDB.open(db_param.name, db_param.version);
        request.onerror = function (event) {
            console.log(request.error);
        };
        request.onsuccess = function (event) {
            resolve(request.result);
        };
    });
}

