<template>
    <div class="child-section entreprise-information">
        <div v-if="is_only_intervenant">
            <div class="tableau-section">
            <div>
                <div class="subtitle-section-detail entreprise-information" :class="$mq">
                    {{ $t('title_operator') }}
                    <span style="color:red; font-weight:bold; margin-left:5px">*</span>
                </div>
            </div>
        </div>
        <div class="tableau-section">
            <div>
                <div class="cell-detail" style="min-width:150px; width:30%">
                    <DropDown
                        class="select-entreprise "
                        width="100%"
                        :disabled="this.isAvailableCr"
                        v-model="informations.id"
                        :choiceValues="entreprisesValues"
                        @save="test_entreprise"
                    />
                </div>
            </div>
        </div>
        </div>
       <div v-else>
            <div class="tableau-section">
                <div>
                    <div class="subtitle-section-detail entreprise-information" :class="$mq">
                        {{ $t('title_company') }}
                        <span style="color:red; font-weight:bold; margin-left:5px">*</span>
                        <div class="cell-detail" :class="$mq">
                            <DropDown
                                :class="errorEntreprise ? 'error' : ''"
                                class="select-entreprise "
                                width="100%"
                                :disabled="this.isAvailableCr"
                                v-model="informations.id"
                                :choiceValues="entreprisesValues"
                                @save="test_entreprise"
                            />
                        </div>
                    </div>
                    <div class="subtitle-section-detail entreprise-information" :class="$mq">
                        {{ $t('title_number') }}
                        <span style="color:red; font-weight:bold; margin-left:5px">*</span>
                        <div class="cell-detail" :class="$mq">
                            <a-input style="text-align:center; width:100%" :disabled="this.isAvailableCr" v-model="informations.effectif" />
                        </div>
                    </div>
                    <div class="subtitle-section-detail entreprise-information " :class="$mq">
                        {{ $t('title_dates') }}
                        <span style="color:red; font-weight:bold; margin-left:5px">*</span>
                        <div class="cell-detail last" :class="$mq">
                        <DatePicker  width="100%" :disabled="this.isAvailableCr" :multiple="true" v-model="informations.dates_travaux" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="informations.id=='new'" style="background-color :#4cb3c1; color:white;  font-size: 16px; padding:10px; ">
            <span class="new-entreprise-information">
                {{ $t('new.name') }}
                <span style="color:red; font-weight:bold; margin-left:5px">*</span>
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.raison" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.service') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.service" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.address') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.adresse_1" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.additional_address') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.adresse_2" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.cp') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.code_postal" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.city') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.ville" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.phone') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.telephone" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.fax') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.telecopie" />
            </span>
            <span class="new-entreprise-information">
                {{ $t('new.website') }}
                <a-input style="text-align:center;" :disabled="this.isAvailableCr" v-model="informations.nouvelle_entreprise.site_internet" />
            </span>
        </div>
        <div class="cell-detail" :class="$mq">
            <a-textarea width="100%" :disabled="this.isAvailableCr"  :placeholder="$t('placeholder_comment')" v-model="informations.comment" />
        </div>
                  
    </div>
</template>
<script>
import _ from "lodash";
import {mapActions,mapGetters} from 'vuex';
import DropDown from "../sections/HeaderSections/DropDown"
import DatePicker from "../sections/HeaderSections/DatePicker"



export default {
    i18nOptions: {
        keyPrefix: "rapportedit.entrepriseinformation"
    },
    name: "EntrepriseInformation",
    components:{
        DropDown,
        DatePicker
    },
    props: {
        value:{
            type:Object,
            required:true
        }
	},
    data() {
        return {
            errorEntreprise:false,
            textErrorEntreprise: this.$t('text_error'),
            informations:{},
            oldValue:{},
            valueChange:false,
        };
    },
    watch:{
        informations:{
            deep:true,
            handler(){
                if(!this.valueChange){
                    this.$emit('input',this.informations);
                    this.$emit('save');
                }
                this.valueChange=false;
                
            }
        },
        value:{
            immediate:true,
            deep:true,
            handler(){
                this.valueChange=true;
                this.informations={...this.value};
            }
        }
    },
    beforeMount(){
        this.reset_entreprise();
        if(!this.informations.id){
            this.add_blocked_sign_error({id:1,text:this.textErrorEntreprise})
            this.errorEntreprise=true;
        }
    },
    methods: {
        ...mapActions('rapports', ["add_blocked_sign_error","remove_blocked_sign_error"]),
        test_entreprise(){
            this.reset_entreprise();
            if(!this.informations.id){
                this.add_blocked_sign_error({id:1,text:this.textErrorEntreprise})
                this.errorEntreprise=true;
            }

             if(this.informations.id!='new'){
                this.informations={..._.omit(this.informations,'nouvelle_entreprise')}
            }else{
                this.informations={...this.informations,nouvelle_entreprise:{}}
            }
        },
        reset_entreprise(){
            this.remove_blocked_sign_error(1)
            this.errorEntreprise=false;
        },
    },
    computed: {
        ...mapGetters("rapports", { 
            is_locked_report: "is_locked_report" ,
            is_to_send_or_recap_report:"is_to_send_or_recap_report",
            entreprises:"get_entreprises_rapport",
            intervenants:"get_intervenants_rapport",
            entreprises_IC:"get_available_entreprise_ic",
            rapport:"get_user_rapport"
        }),
        entreprisesValues(){
            let choice=[];
            if(this.is_only_intervenant){
                for (const interv of this.intervenants) {
                    choice.push(interv);
                }
                choice.push({
                    id: "new",
                    text: this.$t('title_new_operator')
                });

                return choice; 
            } else {
                for (const entreprise of this.entreprises) {
                    if(entreprise.id==this.informations.id || !_.map(this.get_available_entreprise_ic,'id').includes(entreprise.id))
                        choice.push(entreprise);
                }
                choice.push({
                    id: "new",
                    text: this.$t('title_new_company')
                });

                return choice;
            }
        },
        is_only_intervenant(){
            return this.rapport.header.modele.title === 'IC_EXP';
        },
        isAvailableCr() {
            return this.is_locked_report || this.is_to_send_or_recap_report;
        }
    }
};
</script>

