import {getArboLocal,getArboVisa,getArboNormal} from '~/src/common'
import Project_db from "~/db_access/Project.js";
export default {
  async updateArbosTodisplay({ commit, dispatch, rootGetters } ) {

    let loaderLinked=await dispatch("getNewLoaderAvailable",null,{root:true});
    
    let arboStandardToDisplay;
    let arboToVisaToDisplay;
    let arboLocalToDisplay;
    if (rootGetters.getOnline) {

      arboStandardToDisplay = await getArboNormal();
      await dispatch("changeLoadingPercent", {loaderLinked,loadingPercent:25},{root:true});
      arboToVisaToDisplay = await getArboVisa(arboStandardToDisplay);
      
      await dispatch("changeLoadingPercent", {loaderLinked,loadingPercent:50},{root:true});
      arboLocalToDisplay = await getArboLocal(arboStandardToDisplay);
      console.log(loaderLinked);
      await dispatch("changeLoadingPercent",{loaderLinked,loadingPercent:75},{root:true});
      
    }
    else {
      arboLocalToDisplay = await Project_db.get_arbo();
      await dispatch("changeLoadingPercent",{loaderLinked,loadingPercent:75},{root:true});
    }
    
    commit('UPDATE_ARBO_TO_DISPLAY',
      {
        arboStandardToDisplay: arboStandardToDisplay,
        arboToVisaToDisplay: arboToVisaToDisplay,
        arboLocalToDisplay: arboLocalToDisplay,
      })
    await dispatch("changeLoadingPercent",{loaderLinked,loadingPercent:100},{root:true});
    
  },
  setArborescenceActive({ commit }, payload) {
    commit('CHANGE_ARBO_ACTIVE', payload);
  },
  setArboOpen({ commit }, boolean) {
    commit('CHANGE_ARBO_OPEN', boolean);
  },
};
