<template>
  <div class="pwa-screen">
    <a-layout>
      <a-layout-header class="layout-header" :class="$mq">
        <Header
          :displayLogoButton="true"
          :displayBackButton="true"
          :displaySearchBar="true"
          :displayTeamButton="false"
          :displayOfflineButton="true"
          :displayModuleButton="true"
          title="Carnet de contact"
        />
      </a-layout-header>
      <a-layout-content>
         <div v-if="project.isPresents" class="actor">
            <a-collapse :activeKey="activeKey">
              <a-collapse-panel v-for="(entreprise,index) in entreprises_actors" class="entreprise" :show-arrow="false" :key="''+index" :header="get_header(entreprise)">
                 <div slot="extra"> <span v-if="is_icp(entreprise)">ICP</span> <span v-if="is_ppsps(entreprise)">PPSPS</span></div>
                <a-collapse accordion>
                  <a-collapse-panel v-for="(actor,index) in actors_by_entreprise(entreprise)" :key="index" :header="actor.name">
                    <p>{{ $t('applayout.mail') }} : {{ actor.email==''?'Non renseigné':actor.email }}</p>
                    <p>{{ $t('applayout.phone') }} : {{ actor.gsm==''?'Non renseigné':actor.gsm }}</p>
                    <p v-if="!actor.is_intervenant">{{ $t('applayout.activity') }} : {{ actor.role==''?'Non renseigné':actor.role }}</p>
                    <p v-else>{{ $t('applayout.fonction') }} : {{ actor.role==''?'Non renseigné':actor.role }}</p>
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>
            </a-collapse>
        </div>
         <div v-else class="actor">
          <a-collapse accordion>
            <a-collapse-panel v-for="(actor,index) in actors" :key="index" :header="actor.name">
              <p>{{ $t('applayout.mail') }} :  {{ actor.mail==''?'Non renseigné':actor.mail }}</p>
              <p>{{ $t('applayout.phone') }} : {{actor.info.tel==''?'Non renseigné':actor.info.tel }}</p>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>
<script>


import Header from "./applayout/Header";

import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "FileModule",
  components: {
    Header,
  },
  data() {
    return {
      activeKey: [],
    };
  },
  beforeMount(){
    this.activeKey=_.map(this.entreprises_actors,(value, index)=>{
      return index;
    });
  },
  
  methods:{
    actors_by_entreprise(entreprise){
        return _.sortBy(_.filter(this.allActors,['org_lib',entreprise]),['name']);
    },
    is_icp(entreprise){
      return _.filter(this.actors_by_entreprise(entreprise),['icp',true]).length>0
    },
    is_ppsps(entreprise){
      return _.filter(this.actors_by_entreprise(entreprise),['ppsps',true]).length>0
    },
    activities(entreprise){
      return _.join(_.map(_.filter(
        this.actors_by_entreprise(entreprise),
        function(item) {
          return item.role != "";
        }), function(item) {
          return item.role;
        }), ", ");
    },
    get_header(entreprise) {
      const activities = this.activities(entreprise);
      if (!activities) {
        return `${entreprise}`;
      }

      return `${entreprise} (${activities})`;
    }
  },
  computed: {
     ...mapGetters("project", { project: "getProject" }),
     ...mapGetters('project',{ allActors: "getAllActors",project:'getProject' }),
     entreprises_actors(){
        return _.uniq(_.sortBy(_.map(this.allActors,'org_lib'), function(actor_lib){
            return _.toLower(actor_lib);
        }));
     },
      actors(){
        if(this.project.isPresents){
            return _.sortBy(this.allActors,['org_lib','name']);
          }
          return _.sortBy(this.allActors,['name']);
      },
  },
};
</script>



