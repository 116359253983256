<template>
    <div class="container-tiles">
        <RapportModeleTile v-for="modele in modelesMapped" :key="modele.id" :modele="modele"
            :blocked_in_progress="modele.blocked_in_progress" />
    </div>
</template>
<script>
import { map, indexOf } from 'lodash';
import { mapState } from 'vuex';
import RapportModeleTile from "./RapportModeleTile.vue";
import Rapport_db from "~/db_access/Rapport";
import Rapport_API from "~/service/Rapport/Rapport";

export default {
    name: "AllModeleSelection",
    components: {
        RapportModeleTile,
    },
    data() {
        return {
            modeles: [],
            rapports: [],
        };
    },
    computed: {
        ...mapState('rapports', ['frozen_reports']),
        modelesMapped() {
            let reports_blocked = [];
            this.frozen_reports.forEach(rapport_id => {
                if (typeof rapport_id === 'string' && rapport_id.includes('new')) {
                    const modele_id = rapport_id.split('_')[0];
                    if (!reports_blocked[modele_id]) {
                        reports_blocked[modele_id] = [];
                    }
                    reports_blocked[modele_id].push(rapport_id);
                }
            });
            this.rapports.forEach(rapport => {
                if (indexOf(this.frozen_reports, rapport.id) !== -1) {
                    if (!reports_blocked[rapport.modele_id]) {
                        reports_blocked[rapport.modele_id] = [];
                    }
                    reports_blocked[rapport.modele_id].push(rapport.id);
                }
            });

            return map(this.modeles, (modele) => {
                modele.blocked_in_progress = reports_blocked[modele.id] ?? [];

                return modele;
            });
        }
    },
    async beforeMount() {
        this.modeles = await Rapport_db.get_all_modeles();
        if (this.modeles.length === 0) {
            this.modeles = await Rapport_API.get_all_modeles();
            Rapport_db.register_modeles_rapports(this.modeles);
        }
        this.rapports = await Rapport_db.get_rapports_en_cours();
    },
};
</script>